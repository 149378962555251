import * as Types from '../../../../../__codegen__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IntercomUserQueryVariables = Types.Exact<{
  customerId: Types.Scalars['String'];
}>;

export type IntercomUserQuery = { readonly __typename?: 'Query' } & {
  readonly intercomUser?: Types.Maybe<
    { readonly __typename?: 'IntercomUserLinkPayload' } & Pick<
      Types.IntercomUserLinkPayload,
      'userLink'
    >
  >;
  readonly intercomConversationCount?: Types.Maybe<
    { readonly __typename?: 'IntercomOpenConversationCountPayload' } & Pick<
      Types.IntercomOpenConversationCountPayload,
      'openConversationCount'
    >
  >;
};

export const IntercomUserDocument = gql`
  query IntercomUser($customerId: String!) {
    intercomUser: intercomUserLink(input: { customerId: $customerId }) {
      userLink
    }
    intercomConversationCount: intercomOpenConversationCount(
      input: { customerId: $customerId }
    ) {
      openConversationCount
    }
  }
`;

/**
 * __useIntercomUserQuery__
 *
 * To run a query within a React component, call `useIntercomUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntercomUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntercomUserQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useIntercomUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IntercomUserQuery,
    IntercomUserQueryVariables
  >
) {
  return Apollo.useQuery<IntercomUserQuery, IntercomUserQueryVariables>(
    IntercomUserDocument,
    baseOptions
  );
}
export function useIntercomUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntercomUserQuery,
    IntercomUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<IntercomUserQuery, IntercomUserQueryVariables>(
    IntercomUserDocument,
    baseOptions
  );
}
export type IntercomUserQueryHookResult = ReturnType<
  typeof useIntercomUserQuery
>;
export type IntercomUserLazyQueryHookResult = ReturnType<
  typeof useIntercomUserLazyQuery
>;
export type IntercomUserQueryResult = Apollo.QueryResult<
  IntercomUserQuery,
  IntercomUserQueryVariables
>;
