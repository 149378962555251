import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div`
  display: flex;
`;

interface RowProps {
  className?: string;
}
export const Row: React.FC<RowProps> = ({ children, className }) => (
  <StyledRow className={className}>{children}</StyledRow>
);
