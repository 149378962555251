import * as React from 'react';
import styled, { css } from 'styled-components';

enum TextVariation {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  regular = 'p',
  small = 'p',
  tiny = 'p',
}
const TEXT_VARIATION_KEYS = Object.keys(TextVariation) as Array<
  keyof typeof TextVariation
>;

const getType = (
  props: TextProps & StyledTextProps
): keyof JSX.IntrinsicElements => {
  for (let i = 0; i < TEXT_VARIATION_KEYS.length; i++) {
    // go through all allowed text variations and check for truthability in props
    // if present, return the variation - else return default of regular.
    if (props[TEXT_VARIATION_KEYS[i]]) {
      return TextVariation[TEXT_VARIATION_KEYS[i]];
    }
  }

  // Default to regular if no variation present
  return TextVariation.regular;
};

interface StyledTextProps {
  variant?: string;
  bold?: boolean;
  light?: boolean;
  code?: boolean;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  small?: boolean;
  tiny?: boolean;
  color?: string;
  regular?: boolean;
}
const StyledText = styled.div<StyledTextProps>`
  color: ${({ color }) => color || 'currentColor'};

  ${({ h1, h2, h3 }) =>
    (h1 || h2 || h3) &&
    css`
      font-weight: 700;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
    `}

  ${({ h1 }) =>
    h1 &&
    css`
      font-size: 24px;
    `}

  ${({ h2 }) =>
    h2 &&
    css`
      font-size: 21px;
    `}

  ${({ h3 }) =>
    h3 &&
    css`
      font-size: 18px;
    `}

  ${({ variant }) =>
    variant === 'p' &&
    css`
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
    `}

  ${({ small }) =>
    small &&
    css`
      font-size: 12px;
    `}

  ${({ tiny }) =>
    tiny &&
    css`
      font-size: 10px;
      font-weight: 600;
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

${({ light }) =>
    light &&
    css`
      font-weight: 300;
    `}
`;

interface TextProps {
  className?: string;
  style?: React.CSSProperties;
}
/**
 * Standardized Text Component.
 * If you apply multiple StyledTextProps to an instance of this component, only
 * one will apply.
 * eg: `<Text small h1 small>Foo</Text>` will not do what you expect.
 */
export const Text: React.SFC<TextProps & StyledTextProps> = ({
  style,
  ...props
}) => {
  const nativeType = React.useMemo(() => getType(props), [props]);

  return (
    <StyledText as={nativeType} variant={nativeType} style={style} {...props} />
  );
};
