import { WorkerClient } from '../data/WorkerClient';
import { Activities } from '../data/Activities';

/**
 * Whether a worker is currently in callback activity doing 1 or more reservations
 */
export const isCallbacking = (worker: WorkerClient) => {
  const currentReservations = worker.reservations.size;
  const currentActivity = worker.activity.sid;

  return currentActivity === Activities.Callback && currentReservations > 0;
};
