import React from 'react';
import { ButtonLink } from 'src/components/Button';
import { NotificationBubble } from 'src/components/NotifcationBubble';
import { useIntercomUserQuery } from '../data/__codegen__/IntercomUser';

interface IntercomUserLinkProps {
  customerId: string;
}
export const IntercomUserLink: React.FC<IntercomUserLinkProps> = ({
  customerId,
}) => {
  const { data, loading, error } = useIntercomUserQuery({
    variables: { customerId },
  });

  if (loading || !data) return null;
  if (!data.intercomUser) return null;
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return (
    <NotificationBubble
      count={data.intercomConversationCount?.openConversationCount}
    >
      <ButtonLink
        variant="primary"
        href={data.intercomUser.userLink}
        target="_blank"
        rel="noopener noreferrer"
        icon={['far', 'external-link']}
        size="small"
      >
        Intercom
      </ButtonLink>
    </NotificationBubble>
  );
};
