import * as React from 'react';
import * as Flex from '@twilio/flex-ui';
import { FeatureComponent } from '../initialize_features';
import { CallbackButton } from './ui/CallbackButton';
import { modifyTaskListSorting } from './ui/modify_task_list_sorting';
import { modifyWorkerWithCallbacks } from './domain/modifyWorkerWithCallbacks';

export const initialize = (): FeatureComponent => ({
  onLoad: () => {
    modifyTaskListSorting(Flex);

    Flex.TaskCanvasHeader.Content.add(
      <CallbackButton {...({ key: 'accept-override' } as any)} />,
      {
        sortOrder: 1,
        if: (props: { task: Flex.ITask }) =>
          props.task.attributes.taskType === 'callback',
      }
    );
  },
  onInitManager: (manager: Flex.Manager) => {
    modifyWorkerWithCallbacks(manager.workerClient);
  },
});
