import * as React from 'react';
import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';
import styled, { css } from 'styled-components';
import { StyledBaseButton } from './styles/StyledBaseButton';
import { BaseButton } from './types/BaseButton';
import { StyledFontAwesomeIcon } from './styles/StyledFontAwesomeIcon';

type ButtonLinkProps = BaseButton &
  Pick<AriaButtonProps, 'autoFocus' | 'target' | 'href' | 'rel'>;
export const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  target,
  href,
  size = 'big',
  variant,
  icon,
  className,
}) => {
  const ref = React.useRef<HTMLAnchorElement>(null);
  const { buttonProps } = useButton({ target, href, elementType: 'a' }, ref);

  return (
    <StyledButtonLink
      {...(buttonProps as any)}
      ref={ref}
      variant={variant}
      size={size}
      className={className}
      icon={icon}
      as="a"
    >
      {icon && <StyledFontAwesomeIcon icon={icon} />}
      {children}
    </StyledButtonLink>
  );
};

type StyledButtonLinkProps = Pick<BaseButton, 'icon'>;
const StyledButtonLink = styled(StyledBaseButton)<StyledButtonLinkProps>`
  ${({ icon }) =>
    icon &&
    css`
      padding: 0 20px;
      padding-left: 16px;
    `}

  ${({ icon, size }) =>
    icon &&
    size === 'small' &&
    css`
      padding: 0 16px;
      padding-left: 12px;
    `}

  ${StyledFontAwesomeIcon} {
    font-size: 18px;
    margin-right: 8px;
  }
`;
