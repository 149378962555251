import * as Flex from '@twilio/flex-ui';
import { initialize as callback } from './callback';
import { initialize as ringtone } from './ringtone';
// import { initialize as history } from './history';
import { initialize as crm } from './crm';
import { initialize as callRecord } from './call_record';
import { initialize as endedMessage } from './ended_message';

export interface FeatureComponent {
  onLoad: () => void;
  onInitManager: (manager: Flex.Manager) => void;
}

export const initializeFeatures = () => [
  callback(),
  ringtone(),
  // history(),
  crm(),
  callRecord(),
  endedMessage(),
];
