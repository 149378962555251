import React from 'react';
import styled from 'styled-components';

const StyledColumn = styled.div`
  width: 100%;
  margin: 0 8px;
`;

export const Column: React.FC = ({ children }) => (
  <StyledColumn>{children}</StyledColumn>
);
