import React from 'react';
import styled from 'styled-components';

const StyledField = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  color: ${({ theme }) => theme.text};
  margin-bottom: 4px;
`;

const StyledLabel = styled.div`
  font-size: 11px;
  font-weight: 700;
  color: ${({ theme }) => theme.secondaryText};
`;

const StyledText = styled.div`
  font-size: 14px;
`;

interface FieldProps {
  label: string;
  text: string;
}
export const Field: React.FC<FieldProps> = ({ label, text }) => (
  <StyledField>
    <StyledLabel>{label}</StyledLabel>
    <StyledText>{text}</StyledText>
  </StyledField>
);
