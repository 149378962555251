import * as React from 'react';
import styled from 'styled-components';
import { Color, Font } from '_theme';

interface NotificationBubbleProps {
  count?: number | string | null;
}
export const NotificationBubble: React.FC<NotificationBubbleProps> = ({
  count,
  children,
}) => {
  const showNotificationBubble = !!(count && !(count === 0 || count === '0'));

  return (
    <StyledNotificationBubbleWrapper>
      {children}

      {showNotificationBubble && (
        <StyledNotificationBubble>{count}</StyledNotificationBubble>
      )}
    </StyledNotificationBubbleWrapper>
  );
};

const StyledNotificationBubbleWrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

const StyledNotificationBubble = styled.div`
  background-color: ${({ theme }) => theme.negative};
  color: ${Color.White};
  position: absolute;
  top: -6px;
  right: -6px;
  min-width: 21px;
  height: 21px;
  padding: 2px 6px;
  padding-bottom: 3px;
  font-size: 11px;
  font-family: ${Font.Medium};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
