import { gql, useQuery } from '@apollo/client';

export enum TaskType {
  Signup = 'signup',
  AgreementUpload = 'agreementUpload',
  SignupAudit = 'signupAudit',
  SignupCompletion = 'signupCompletion',
  NemKonto = 'nemKonto',
  CloseAccount = 'closeAccount',
  CloseAccountStay = 'closeAccountStay',
  CreditApplicationCreated = 'creditApplicationCreated',
  CreditRequest = 'creditRequest',
  CreditCancellationRequest = 'creditCancellationRequest',
  PrepaidCardAccountWentNegative = 'prepaidCardAccountWentNegative',
  PayMyBill = 'payMyBill',
  Subscriptionpaymentfailure = 'subscriptionpaymentfailure',
  MigrationFailed = 'migrationFailed',
  BankSignup = 'bank_signup',
}

export interface User {
  id: string;
  email: string;
  name: string;
}

export interface Note {
  id: string;
  content: string;
  created: string;
  createdBy?: User;
  deletedBy?: User;
}

export interface Customer {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone?: {
    countryCode: string;
    number: string;
  };
  relations: Array<string>;
  notes: Array<Note>;
}

export interface GetCustomersVariables {
  phone: string;
}

export interface GetCustomersData {
  customers: Array<Customer>;
}

export const GET_CUSTOMERS = gql`
  query($phone: String!) {
    customers(phone: $phone) {
      id
      email
      firstName
      lastName
      phone {
        countryCode
        number
      }
      relations
      notes {
        nodes {
          id
          content
          created
          createdBy {
            id
            email
            name {
              first
              last
            }
          }
        }
      }
    }
  }
`;

/**
 * Returns an array of customer objects
 * @param phone Format like +xx-zzzzzzzz where x is countrycode and z is the phone number
 */
export const useCustomers = (phone: string) => {
  const response = useQuery<GetCustomersData, GetCustomersVariables>(
    GET_CUSTOMERS,
    {
      variables: {
        phone,
      },
    }
  );

  return response;
};
