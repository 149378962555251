import React, { memo } from 'react';
import styled from 'styled-components';
import { Field, Row, Card, Text, MessageBubble } from '_components';
import { formatDate } from '_helpers';
import { Customer as CustomerProps } from '../data/customers';

const StyledCustomer = styled(Row)`
  height: 100%;
  width: 100%;
  padding: 8px 24px;
  padding-top: 0;
  padding-bottom: 16px;
  display: flex;

  > div:first-of-type {
    margin-right: 24px;
  }
`;

const StyledNotesContainer = styled(Card)`
  flex-grow: 1;
  max-height: 335px;
  display: flex;
  flex-direction: column;
`;

const StyledMessage = styled.div`
  text-align: right;
  margin-bottom: 16px;
`;

const StyledNotes = styled.div`
  overflow: scroll;
  flex-grow: 1;
`;

export const Customer: React.FC<CustomerProps> = memo(
  ({ id, firstName, lastName, phone, email, notes, relations }) => (
    <StyledCustomer>
      <Card>
        <Text bold>Information</Text>
        <Field label="ID" text={id} />
        <Field label="NAME" text={`${firstName} ${lastName}`} />
        {phone && (
          <Field label="PHONE" text={`${phone.countryCode} ${phone.number}`} />
        )}
        <Field label="EMAIL" text={email} />
        {relations.length > 0 && (
          <Field label="RELATION" text={relations.join(' - ')} />
        )}
      </Card>
      {notes.length > 0 && (
        <StyledNotesContainer>
          <Text bold>Notes</Text>
          <StyledNotes>
            {notes.reverse().map(note => (
              <StyledMessage>
                <MessageBubble key={note.id} isFromMe>
                  <Text>{note.content}</Text>
                </MessageBubble>
                <Text tiny style={{ marginTop: 0, marginRight: 8 }}>
                  {formatDate(note.created)}
                </Text>
              </StyledMessage>
            ))}
          </StyledNotes>
        </StyledNotesContainer>
      )}
    </StyledCustomer>
  )
);
