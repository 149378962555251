import React, { memo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useCustomers, Customer as CustomerType } from '../data/customers';
import { PreviewCustomer } from './PreviewCustomer';
import { CostumerNotFoundDisplay } from './CustomerNotFoundDisplay';
import { Customer } from './Customer';

const StyledCustomers = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

const StyledCenteringContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface CustomersProps {
  phone: string;
}
export const Customers: React.FC<CustomersProps> = memo(({ phone }) => {
  const formattedPhone = phone.match(/\+[\d]+/);
  const { loading, error, data } = useCustomers(
    formattedPhone ? formattedPhone[0] : ''
  );
  const [expandedCustomerId, setExpandedCustomerId] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (data && data.customers && data.customers.length > 0) {
      const customerId: string = [...data.customers].sort(
        sortByRelationPriority
      )[0].id;

      setExpandedCustomerId(customerId);
    } else {
      setExpandedCustomerId(null);
    }
  }, [data]);

  const handleOnToggle = useCallback(
    (customerId: string) => {
      setExpandedCustomerId(customerId);
    },
    [setExpandedCustomerId]
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error.message}</p>;
  if (!data) return <p>NO DATA :(</p>;

  const { customers } = data;

  return (
    <StyledCustomers>
      {customers.length === 0 && (
        <StyledCenteringContainer>
          <CostumerNotFoundDisplay />
        </StyledCenteringContainer>
      )}
      {customers.map(customer => (
        <PreviewCustomer
          onToggle={() => handleOnToggle(customer.id)}
          isExpanded={customer.id === expandedCustomerId}
          key={customer.id}
          {...customer}
        >
          <Customer {...customer} />
        </PreviewCustomer>
      ))}
    </StyledCustomers>
  );
});

const relationPriorityMap: Record<string, number> = {
  DKLB: 1,
  DKLBB: 2,
  DKNB: 3,
  DKNBB: 4,
  OTHER: 5,
};

const getRelationPriority = (customer: CustomerType): number => {
  const [relation] = customer.relations;
  const priority = relationPriorityMap[relation] || relationPriorityMap.OTHER;

  return priority;
};

const sortByRelationPriority = (a: CustomerType, b: CustomerType): number => {
  const aPriority = getRelationPriority(a);
  const bPriority = getRelationPriority(b);

  return aPriority - bPriority;
};
