import * as Types from '../../../../../__codegen__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CustomerPlanQueryVariables = Types.Exact<{
  userId: Types.Scalars['String'];
}>;

export type CustomerPlanQuery = { readonly __typename?: 'Query' } & {
  readonly customerPlan?: Types.Maybe<
    { readonly __typename?: 'premium_Plan' } & Pick<
      Types.Premium_Plan,
      'id' | 'state'
    >
  >;
};

export const CustomerPlanDocument = gql`
  query CustomerPlan($userId: String!) {
    customerPlan: premium_userPlan(userId: $userId) {
      id
      state
    }
  }
`;

/**
 * __useCustomerPlanQuery__
 *
 * To run a query within a React component, call `useCustomerPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPlanQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCustomerPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomerPlanQuery,
    CustomerPlanQueryVariables
  >
) {
  return Apollo.useQuery<CustomerPlanQuery, CustomerPlanQueryVariables>(
    CustomerPlanDocument,
    baseOptions
  );
}
export function useCustomerPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerPlanQuery,
    CustomerPlanQueryVariables
  >
) {
  return Apollo.useLazyQuery<CustomerPlanQuery, CustomerPlanQueryVariables>(
    CustomerPlanDocument,
    baseOptions
  );
}
export type CustomerPlanQueryHookResult = ReturnType<
  typeof useCustomerPlanQuery
>;
export type CustomerPlanLazyQueryHookResult = ReturnType<
  typeof useCustomerPlanLazyQuery
>;
export type CustomerPlanQueryResult = Apollo.QueryResult<
  CustomerPlanQuery,
  CustomerPlanQueryVariables
>;
