import React from 'react';
import styled from 'styled-components';

const StyledView = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 120px;
`;

export const DefaultView: React.FC = () => <StyledView>🚀</StyledView>;
