import moment from 'moment';

/**
 * Date formatter
 * @param {String} timestamp Timstamp to be formatted.
 * @param {String} format Moment format to use.
 */
export const formatDate = (
  timestamp: string,
  format: string = 'DD/MM/YY HH:mm'
): string => moment(timestamp).format(format);
