import * as Flex from '@twilio/flex-ui';
import { hasAllowedRecording } from '../domain/has_allowed_recording';
import { withRecording } from '../domain/withRecording';
import { AcceptTask } from '../data/AcceptTask';

export const addAcceptTaskWithRecordingListener = (flex: typeof Flex) =>
  flex.Actions.addListener('beforeAcceptTask', (payload: AcceptTask) => {
    if (hasAllowedRecording(payload.task)) {
      withRecording(payload);
    }
  });
