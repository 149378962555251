import * as React from 'react';
import styled, { css } from 'styled-components';
import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';
import { StyledBaseButton } from './styles/StyledBaseButton';
import { BaseButton } from './types/BaseButton';
import { StyledFontAwesomeIcon } from './styles/StyledFontAwesomeIcon';

export type ButtonProps = {
  onClick?: AriaButtonProps['onPress'];
} & Pick<AriaButtonProps, 'type' | 'autoFocus' | 'isDisabled'> &
  BaseButton;
export const Button: React.FC<ButtonProps> = ({
  children,
  type,
  autoFocus,
  isDisabled,
  size = 'big',
  variant,
  icon,
  className,
  onClick,
}) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    { onPress: onClick, type, autoFocus, isDisabled },
    ref
  );

  return (
    <StyledButton
      {...buttonProps}
      ref={ref}
      variant={variant}
      size={size}
      icon={icon}
      className={className}
    >
      {icon && <StyledFontAwesomeIcon icon={icon} />}
      {children}
    </StyledButton>
  );
};

type StyledButtonProps = Pick<BaseButton, 'icon' | 'size'>;
const StyledButton = styled(StyledBaseButton)<StyledButtonProps>`
  ${({ icon }) =>
    icon &&
    css`
      padding: 0 20px;
      padding-left: 16px;
    `}

  ${({ icon, size }) =>
    icon &&
    size === 'small' &&
    css`
      padding: 0 16px;
      padding-left: 12px;
    `}

  ${StyledFontAwesomeIcon} {
    font-size: 18px;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.4;
    cursor: auto;

    ${({ variant, theme }) => {
      switch (variant) {
        case 'primary':
          return css`
            background: ${theme.button.primary.default};
          `;
        case 'positive':
          return css`
            background: ${theme.button.positive.default};
          `;
        case 'negative':
          return css`
            background: ${theme.button.negative.default};
          `;
        case 'attention':
          return css`
            background: ${theme.button.attention.default};
          `;
        case 'secondary':
          return css`
            background: ${theme.button.secondary.default};
          `;
        default:
          return null;
      }
    }}
  }
`;
