import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Font, EASING, Color } from '_theme';
import { BaseButton } from '../types/BaseButton';

type StyledBaseButtonProps = Pick<BaseButton, 'variant' | 'size'>;
export const StyledBaseButton = styled.button<StyledBaseButtonProps>`
  outline: none;
  height: 40px;
  width: auto;
  flex: 0 0 auto;
  border-radius: 20px;
  padding: 0 24px;
  font-family: ${Font.Bold};
  font-size: 14px;
  transition: all 0.15s ${EASING};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: ${({ theme }) => theme.text};
  background: transparent;
  text-decoration: none;
  cursor: pointer;

  ${({ size }) =>
    size === 'small' &&
    css`
      height: 32px;
      font-size: 12px;
      padding: 0 16px;
    `}

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return css`
          color: ${theme.contrast0};
          background: ${theme.button.primary.default};

          :hover {
            background: ${darken(0.05, theme.button.primary.interactive)};
          }
        `;
      case 'positive':
        return css`
          color: ${Color.White};
          background: ${theme.button.positive.default};

          :hover {
            background: ${darken(0.05, theme.button.positive.interactive)};
          }
        `;
      case 'negative':
        return css`
          color: ${Color.White};
          background: ${theme.button.negative.default};

          :hover {
            background: ${darken(0.05, theme.button.negative.interactive)};
          }
        `;
      case 'attention':
        return css`
          color: ${Color.White};
          background: ${theme.button.attention.default};

          :hover {
            background: ${darken(0.05, theme.button.attention.interactive)};
          }
        `;
      case 'secondary':
        return css`
          color: ${theme.text};
          background: ${theme.button.secondary.default};

          :hover {
            background: ${theme.button.secondary.interactive};
          }
        `;
      default:
        return null;
    }
  }}
`;
