import * as Flex from '@twilio/flex-ui';
import { WorkerClient } from '../data/WorkerClient';
import { setActivityToCallback } from '../data/setActivityToCallback';
import { setActivityToAvailable } from '../data/setActivityToAvailable';
import { Reservation } from '../data/Reservation';
import { isCallback } from './isCallbackTask';
import { isCallbacking } from './isCallbacking';

const createHandleReservationCompleted = (worker: WorkerClient) => () => {
  if (!isCallbacking(worker)) {
    return setActivityToAvailable(worker);
  }
};

const createHandleReservationCreated = (worker: WorkerClient) => (
  reservation: Reservation
) => {
  reservation.on('accepted', async (acceptedReservation: Reservation) => {
    acceptedReservation.on(
      'completed',
      createHandleReservationCompleted(worker)
    );
    const { task } = acceptedReservation;

    if (isCallback(task)) {
      await setActivityToCallback(worker);

      Flex.Actions.invokeAction('StartOutboundCall', {
        destination: task.attributes.customerNumber,
      });
    }
  });
};

/**
 * Adds callback functionality for the worker client.
 *
 * This involves listening for reservation.accept in order to move workers to the
 * (set as unavailable) callback activity, and reservation.completes in order to
 * move the worker back in an available activity.
 *
 * This is done due to Flex trying to assign incoming voice calls to a worker who
 * is currenty engaged in a callback task.
 */
export const modifyWorkerWithCallbacks = (worker: WorkerClient) => {
  const handleReservationCompleted = createHandleReservationCompleted(worker);
  const handleReservationCreated = createHandleReservationCreated(worker);

  for (const [, reservation] of worker.reservations) {
    reservation.on('completed', handleReservationCompleted);
  }

  worker.on('reservationCreated', handleReservationCreated);
};
