import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { Color } from '_theme';

interface StyledMessageProps {
  isFromMe: boolean;
}
const StyledMessageBubble = styled.div<StyledMessageProps>`
  margin-bottom: 8px;
  margin-right: auto;
  max-width: 85%;
  border-radius: 16px;
  padding: 8px 12px;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.contrast20};

  ${({ isFromMe }) =>
    isFromMe &&
    css`
      margin-left: auto;
      margin-right: 0;
      color: ${Color.White};
      background-color: ${({ theme }) => theme.contrast80};
    `}
`;

interface MessageBubbleProps {
  isFromMe: boolean;
}
export const MessageBubble: React.FC<MessageBubbleProps> = memo(
  ({ children, isFromMe }) => (
    <StyledMessageBubble isFromMe={isFromMe}>{children}</StyledMessageBubble>
  )
);
