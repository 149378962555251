import * as Flex from '@twilio/flex-ui';
import { request } from '_helpers';

export const send_message = async (to: string, manager: Flex.Manager) => {
    request('trigger-send-message-flow', manager, {
        to
      }).then(response => {
        console.log('Message has been sent');
      })
      .catch(error => {
        console.error(error);
      });
}