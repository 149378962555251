import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import * as Flex from '@twilio/flex-ui';
import { client } from './ApolloClient';
import './styles/index.css';
import { App } from './App';
import registerServiceWorker from './registerServiceWorker';
import { initializeFeatures } from './features/initialize_features';

const Logo = require('./static/images/lunar-logo-blue.png');

const mountNode = document.getElementById('root');

if (!mountNode) {
  throw new Error('root mount node is missing in html');
}

window.onload = () => {
  const predefinedConfig = (window as any).appConfig || {};
  const configuration = {
    ...predefinedConfig,
  };

  const features = initializeFeatures();

  features.forEach(({ onLoad }) => onLoad());

  Flex.MessageInput.defaultProps.useSeparateInputStore = false;
  Flex.MainHeader.defaultProps.logoUrl = Logo;

  Flex.progress(mountNode)
    .provideLoginInfo(configuration, mountNode)
    .then(() => Flex.Manager.create(configuration))
    .then(manager => {
      renderApp(manager);

      features.forEach(({ onInitManager }) => onInitManager(manager));
    })
    .catch(error => handleError(error));
};

const renderApp = (manager: Flex.Manager) => {
  ReactDOM.render(<App client={client} manager={manager} />, mountNode);
};

const handleError = (error: Error) => {
  // eslint-disable-next-line no-console
  console.error('Failed to initialize Flex', error);
};

registerServiceWorker();
