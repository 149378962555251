/**
 * The callback-related activities needed to switch between.
 *
 * This could potentially come from the flex configuration object instead of
 * being hard-coded.
 */
export enum Activities {
  Callback = 'WAec8ff5d3ade20178e2b3e4762b98ef0b',
  Available = 'WAcf4e52ca0bfec87fa6419e10d76c73e3',
}
