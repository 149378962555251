export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  cardsettlement_Date: any;
  /** Date custom scalar type */
  Date: number;
  /** A decimal value formatted as a string (eg. "1000.00") */
  Decimal: string;
  /** Currency code formatted as ISO 4217 (eg. "DKK") */
  Currency: string;
  /** DateTime formatted as ISO 8601 (eg. "2020-03-17T19:22:43.522Z") */
  DateTime: string;
  paymentlimit_Decimal: any;
  internationalpayment_Decimal: any;
  bankaccount_JSON: { [key: string]: any };
  cardsettlement_Base64: any;
  cardsettlement_JSON: { [key: string]: any };
  paymentlimit_JSON: any;
};

export type Accountmanagement_CommandResponse = {
  readonly __typename?: 'accountmanagement_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Accountsview_Account = {
  readonly __typename?: 'accountsview_Account';
  readonly id: Scalars['String'];
  readonly created: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly displayName: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly balanceAmount: Scalars['String'];
  readonly balanceAvailableAmount: Scalars['String'];
  readonly balanceUsableAmount: Scalars['String'];
  readonly balanceGoalAmount: Scalars['String'];
  readonly creditLimitAmount: Scalars['String'];
  readonly balanceReservedAmount: Scalars['String'];
  readonly accountNumber: Scalars['String'];
  readonly iban: Scalars['String'];
  readonly bicSwift: Scalars['String'];
  readonly origin: Accountsview_Origin;
  readonly originGroupId: Scalars['String'];
  readonly nemkontoStatus: Accountsview_NemkontoStatus;
  readonly type: Accountsview_Type;
  readonly rawType: Scalars['String'];
  readonly isPrimaryAccount: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly accountOwnerUserId: Scalars['String'];
  readonly closedAt?: Maybe<Scalars['String']>;
  readonly requestedClosedByUserAt?: Maybe<Scalars['String']>;
};

export type Accountsview_AccountOrderAgreement = {
  readonly __typename?: 'accountsview_AccountOrderAgreement';
  readonly id: Scalars['String'];
  readonly fileUrl: Scalars['String'];
};

export enum Accountsview_NemkontoStatus {
  None = 'NONE',
  Ordered = 'ORDERED',
  Agreementsigned = 'AGREEMENTSIGNED',
  Active = 'ACTIVE',
  Notapplicable = 'NOTAPPLICABLE',
}

export enum Accountsview_Origin {
  Nykredit = 'NYKREDIT',
  Lunar = 'LUNAR',
}

export enum Accountsview_Type {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
  Investment = 'INVESTMENT',
  Loan = 'LOAN',
  External = 'EXTERNAL',
}

export type Achievement = {
  readonly __typename?: 'Achievement';
  readonly id: Scalars['ID'];
  readonly type: AchievementType;
  readonly goal: Scalars['Int'];
  readonly progress: AchievementProgress;
};

export type AchievementProgress = {
  readonly __typename?: 'AchievementProgress';
  readonly current: Scalars['Int'];
  readonly awardedAt?: Maybe<Scalars['Date']>;
};

export enum AchievementType {
  FirstSignup = 'FirstSignup',
  Signups100 = 'Signups100',
  Signups200 = 'Signups200',
  Signups500 = 'Signups500',
  Signups1k = 'Signups1k',
  Signups2k = 'Signups2k',
  Signups5k = 'Signups5k',
  Signups10k = 'Signups10k',
  SignupsBoyToy = 'SignupsBoyToy',
}

export type Address = {
  readonly __typename?: 'Address';
  readonly streetName: Scalars['String'];
  readonly streetNumber: Scalars['String'];
  readonly floor: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly coAddress: Scalars['String'];
  readonly placeName: Scalars['String'];
};

export type AddUserPayload = {
  readonly __typename?: 'AddUserPayload';
  readonly user?: Maybe<User>;
  readonly errors?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export enum AgeSegment {
  None = 'none',
  U18 = 'U18',
}

export type Amount = {
  readonly __typename?: 'Amount';
  readonly amount: Scalars['String'];
  readonly currency: Scalars['String'];
};

export type Approval = {
  readonly __typename?: 'Approval';
  readonly id: Scalars['ID'];
  readonly userId: Scalars['String'];
  readonly time: Scalars['Date'];
};

export type ApproveTaskInput = {
  readonly taskId: Scalars['ID'];
};

export type ApproveTaskPayload = {
  readonly __typename?: 'ApproveTaskPayload';
  readonly task?: Maybe<Task>;
  readonly errors?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type Astralis_CommandResponse = {
  readonly __typename?: 'astralis_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Astralis_McDeleteProductCommand = {
  readonly userId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Astralis_McOrderProductCommand = {
  readonly userId: Scalars['String'];
  readonly product: Astralis_ProductId;
  readonly promoCode: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Astralis_Product = {
  readonly __typename?: 'astralis_Product';
  readonly id: Astralis_ProductId;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly state: Astralis_ProductState;
};

export enum Astralis_ProductId {
  Unknown = 'UNKNOWN',
  AstralisDk = 'ASTRALIS_DK',
}

export enum Astralis_ProductState {
  Available = 'Available',
  Active = 'Active',
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
}

export type AuditInput = {
  readonly hasLunarRelation: Scalars['Boolean'];
  readonly action: Scalars['String'];
  readonly userIds: ReadonlyArray<Scalars['String']>;
  readonly query?: Maybe<Scalars['String']>;
  readonly actionAllowed?: Maybe<Scalars['Boolean']>;
};

export type AuditPayload = {
  readonly __typename?: 'AuditPayload';
  readonly error?: Maybe<Scalars['String']>;
};

export type Bankaccount_Account = {
  readonly __typename?: 'bankaccount_Account';
  readonly accountId: Scalars['String'];
  readonly bban: Scalars['String'];
  readonly iban: Scalars['String'];
  readonly currencyCode: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly created: Scalars['String'];
};

export type Bankaccount_AccountClosureStatus = {
  readonly __typename?: 'bankaccount_AccountClosureStatus';
  readonly status: Bankaccount_AccountClosureStatusEnum;
  readonly errorMessage: Scalars['String'];
};

export enum Bankaccount_AccountClosureStatusEnum {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Failed = 'Failed',
  Completed = 'Completed',
}

export type Bankaccount_Balance = {
  readonly __typename?: 'bankaccount_Balance';
  readonly availableBalance: Bankaccount_Money;
  readonly currentBalance: Bankaccount_Money;
  readonly reservedAmount: Bankaccount_Money;
};

export type Bankaccount_CancelCardReservation = {
  readonly accountID: Scalars['String'];
  readonly reservationID: Scalars['String'];
};

export type Bankaccount_CommandResponse = {
  readonly __typename?: 'bankaccount_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Bankaccount_Error = {
  readonly __typename?: 'bankaccount_Error';
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
};

export type Bankaccount_Event = {
  readonly streamID: Scalars['String'];
  readonly eventID: Scalars['String'];
};

export enum Bankaccount_EventStreamType {
  Account = 'Account',
  AccountCatalog = 'AccountCatalog',
}

export type Bankaccount_GetAccountResponse = {
  readonly __typename?: 'bankaccount_GetAccountResponse';
  readonly account: Bankaccount_Account;
  readonly balance: Bankaccount_Balance;
  readonly transactions: ReadonlyArray<Bankaccount_Transaction>;
  readonly reservations: ReadonlyArray<Bankaccount_Reservation>;
};

export type Bankaccount_InitiateAccountClosure = {
  readonly userID: Scalars['String'];
  readonly accountID: Scalars['String'];
};

export type Bankaccount_ListCommandResponse = {
  readonly __typename?: 'bankaccount_ListCommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Maybe<Bankaccount_Error>>>;
};

export type Bankaccount_McBatchSyncCommand = {
  readonly numberOfSyncs: Scalars['Int'];
  readonly durationSeconds: Scalars['Int'];
};

export type Bankaccount_McCancelCardReservationCommand = {
  readonly accountID: Scalars['String'];
  readonly reservationID: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Bankaccount_McCancelCardReservationsCommand = {
  readonly cancelCardReservations: ReadonlyArray<
    Bankaccount_CancelCardReservation
  >;
  readonly reason: Scalars['String'];
};

export type Bankaccount_McCancelDepositReservationCommand = {
  readonly accountID: Scalars['String'];
  readonly depositReservationID: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Bankaccount_McCreateCorrectionTransactionCommand = {
  readonly accountID: Scalars['String'];
  readonly amount: Scalars['Float'];
  readonly title: Scalars['String'];
  readonly longDescription: Scalars['String'];
  readonly transactionIDToCorrect?: Maybe<Scalars['String']>;
  readonly reason: Scalars['String'];
};

export type Bankaccount_McCreateTransactionReversalCorrectionCommand = {
  readonly accountID: Scalars['String'];
  readonly transactionIDToReverse: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Bankaccount_McManuallyFailOnboardingCommand = {
  readonly OnboardingID: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Bankaccount_McPokeAccountCreationCommand = {
  readonly accountCreationIDs: ReadonlyArray<Scalars['String']>;
  readonly reason: Scalars['String'];
};

export type Bankaccount_McPokeCreditTransferCommand = {
  readonly creditTransferID: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Bankaccount_McPokeOnboardingCommand = {
  readonly OnboardingID: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Bankaccount_McPokeSystemToSystemAccountingTransferCommand = {
  readonly accountingTransferID: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Bankaccount_McPokeSystemToUserAccountingTransferCommand = {
  readonly accountingTransferID: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Bankaccount_McPokeUserToSystemAccountingTransferCommand = {
  readonly accountingTransferID: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Bankaccount_McRepublishExternalEventCommand = {
  readonly eventStreamType: Bankaccount_EventStreamType;
  readonly events: ReadonlyArray<Bankaccount_Event>;
};

export type Bankaccount_Money = {
  readonly __typename?: 'bankaccount_Money';
  readonly amount: Scalars['String'];
  readonly currency: Scalars['String'];
};

export type Bankaccount_Reservation = {
  readonly __typename?: 'bankaccount_Reservation';
  readonly id: Scalars['String'];
  readonly reservationTime: Scalars['String'];
  readonly amount: Bankaccount_Money;
  readonly text: Scalars['String'];
  readonly type: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
};

export type Bankaccount_SyncUsersEventCommand = {
  readonly userIDs: ReadonlyArray<Scalars['String']>;
  readonly excludeTransactions: Scalars['Boolean'];
  readonly excludeFutures: Scalars['Boolean'];
  readonly excludeAccountInfo: Scalars['Boolean'];
};

export type Bankaccount_Transaction = {
  readonly __typename?: 'bankaccount_Transaction';
  readonly id: Scalars['String'];
  readonly postingTime: Scalars['String'];
  readonly amount: Bankaccount_Money;
  readonly text: Scalars['String'];
  readonly info: ReadonlyArray<Scalars['String']>;
  readonly type: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly balance: Bankaccount_Balance;
};

export type BankBusinessSignupAmlContent = {
  readonly __typename?: 'BankBusinessSignupAMLContent';
  readonly id: Scalars['ID'];
  readonly time: Scalars['String'];
  readonly userId: Scalars['ID'];
  readonly referenceId: Scalars['String'];
  readonly approvals: ReadonlyArray<Approval>;
};

export type BankBusinessSignupContent = {
  readonly __typename?: 'BankBusinessSignupContent';
  readonly id: Scalars['ID'];
  readonly time: Scalars['String'];
  readonly userId: Scalars['ID'];
  readonly referenceId: Scalars['String'];
  readonly messageTemplates: ReadonlyArray<Maybe<MessageTemplate>>;
};

export type BankCardEngravingContent = {
  readonly __typename?: 'BankCardEngravingContent';
  readonly id: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly happenedAt: Scalars['String'];
};

export type BankCloseCustomerContent = {
  readonly __typename?: 'BankCloseCustomerContent';
  readonly id: Scalars['ID'];
  readonly time: Scalars['String'];
  readonly userId: Scalars['ID'];
  readonly offboardingId: Scalars['ID'];
  readonly requestedBy: Scalars['String'];
};

export type BankMinorToAdult = {
  readonly __typename?: 'BankMinorToAdult';
  readonly id: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly ageSegment: AgeSegment;
  readonly updated: Scalars['Date'];
};

export type BankMoveBetalingsserviceMandateFromOtherBankContent = {
  readonly __typename?: 'BankMoveBetalingsserviceMandateFromOtherBankContent';
  readonly id: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly accountId: Scalars['ID'];
  readonly sortCode: Scalars['String'];
};

export type BankNemkontoContent = {
  readonly __typename?: 'BankNemkontoContent';
  readonly id: Scalars['ID'];
  readonly accountId: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly orderedAt: Scalars['String'];
};

export type BankPrivateSignupAmlContent = {
  readonly __typename?: 'BankPrivateSignupAMLContent';
  readonly id: Scalars['ID'];
  readonly time: Scalars['String'];
  readonly userId: Scalars['ID'];
  readonly referenceId: Scalars['String'];
  readonly approvals: ReadonlyArray<Approval>;
};

export type BankPrivateSignupContent = {
  readonly __typename?: 'BankPrivateSignupContent';
  readonly id: Scalars['ID'];
  readonly time: Scalars['String'];
  readonly userId: Scalars['ID'];
  readonly referenceId: Scalars['String'];
  readonly messageTemplates: ReadonlyArray<Maybe<MessageTemplate>>;
};

export type BankSelbSignupAmlContent = {
  readonly __typename?: 'BankSelbSignupAMLContent';
  readonly id: Scalars['ID'];
  readonly time: Scalars['String'];
  readonly userId: Scalars['ID'];
  readonly referenceId: Scalars['String'];
  readonly approvals: ReadonlyArray<Approval>;
};

export type BankSelbSignupContent = {
  readonly __typename?: 'BankSelbSignupContent';
  readonly id: Scalars['ID'];
  readonly time: Scalars['String'];
  readonly userId: Scalars['ID'];
  readonly referenceId: Scalars['String'];
  readonly messageTemplates: ReadonlyArray<Maybe<MessageTemplate>>;
};

export type BankSignupAmlContent = {
  readonly __typename?: 'BankSignupAMLContent';
  readonly id: Scalars['ID'];
  readonly time: Scalars['String'];
  readonly userId: Scalars['ID'];
  readonly referenceId: Scalars['String'];
  readonly approvals: ReadonlyArray<Approval>;
};

export type BankSignupContent = {
  readonly __typename?: 'BankSignupContent';
  readonly id: Scalars['ID'];
  readonly time: Scalars['String'];
  readonly userId: Scalars['ID'];
  readonly referenceId: Scalars['String'];
  readonly messageTemplates: ReadonlyArray<Maybe<MessageTemplate>>;
};

export type Becadapter_Account = {
  readonly __typename?: 'becadapter_Account';
  readonly accountInfo: Becadapter_AccountInfo;
  readonly lastSyncTime?: Maybe<Scalars['String']>;
  readonly transactions: ReadonlyArray<Becadapter_Transaction>;
};

export type Becadapter_AccountInfo = {
  readonly __typename?: 'becadapter_AccountInfo';
  readonly accountId: Scalars['String'];
  readonly bban: Scalars['String'];
  readonly becAccountId?: Maybe<Scalars['String']>;
  readonly becHomeBankingId?: Maybe<Scalars['String']>;
  readonly iso4217CurrencyCode: Scalars['String'];
};

export type Becadapter_AccountReq = {
  readonly accountId?: Maybe<Scalars['String']>;
  readonly becAccountId?: Maybe<Scalars['String']>;
  readonly becHomebankingId?: Maybe<Scalars['String']>;
};

export type Becadapter_BankZoneAndAccountId = {
  readonly accountID: Scalars['String'];
  readonly bankZoneAccountID: Scalars['String'];
};

export type Becadapter_CheckAccountRequest = {
  readonly accountId: Scalars['String'];
  readonly userFullName: Scalars['String'];
};

export type Becadapter_CheckAccountResult = {
  readonly __typename?: 'becadapter_CheckAccountResult';
  readonly accountID: Scalars['String'];
  readonly checkFailedError?: Maybe<Scalars['String']>;
  readonly numberOfBECAccountsForUser: Scalars['Int'];
  readonly becAccountNumbersFoundForUser?: Maybe<
    ReadonlyArray<Scalars['String']>
  >;
  readonly becAccountFound: Scalars['Boolean'];
  readonly becHomebankingID: Scalars['String'];
  readonly becAccountID: Scalars['String'];
  readonly becOwnerName: Scalars['String'];
  readonly nameMatchResult: Scalars['String'];
};

export type Becadapter_CheckAccountsInput = {
  readonly requests?: Maybe<ReadonlyArray<Becadapter_CheckAccountRequest>>;
};

export type Becadapter_CommandResponse = {
  readonly __typename?: 'becadapter_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Becadapter_CreateAccountInBec = {
  readonly accountId: Scalars['String'];
  readonly bban: Scalars['String'];
  readonly iso4217CurrencyCode: Scalars['String'];
  readonly becHomeBankingId: Scalars['String'];
  readonly userId: Scalars['String'];
};

export type Becadapter_Error = {
  readonly __typename?: 'becadapter_Error';
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
};

export type Becadapter_Event = {
  readonly streamID: Scalars['String'];
  readonly eventID: Scalars['String'];
};

export enum Becadapter_EventStreamType {
  Account = 'Account',
}

export type Becadapter_ListCommandResponse = {
  readonly __typename?: 'becadapter_ListCommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Maybe<Becadapter_Error>>>;
};

export type Becadapter_McBootstrapSync = {
  readonly offsetInHours?: Maybe<Scalars['Int']>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McCreateCreditTransferCorrectionPosting = {
  readonly accountID: Scalars['String'];
  readonly creditTransferID: Scalars['String'];
  readonly becPostingEntryID: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_McCreateNewBecHomeBanking = {
  readonly userId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualBoostrapCustomer = {
  readonly userId: Scalars['String'];
  readonly accountId: Scalars['String'];
  readonly userIdentification: Scalars['String'];
};

export type Becadapter_McManualBoostrapCustomerResponse = {
  readonly __typename?: 'becadapter_MCManualBoostrapCustomerResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
  readonly userAlreadyBootstrapped: Scalars['Boolean'];
};

export type Becadapter_McManualFailAccountCreation = {
  readonly accountCreationId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualFailAccountingTransfer = {
  readonly transferIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualFailCreditTransfer = {
  readonly senderAccountId: Scalars['String'];
  readonly creditTransferId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualFailCustomerOnboarding = {
  readonly customerOnboardingIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualFailLoanConfigs = {
  readonly loanConfigIDs?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualFailLoanConfigsResponse = {
  readonly __typename?: 'becadapter_MCManualFailLoanConfigsResponse';
  readonly successful: Scalars['Boolean'];
  readonly resultInformation: Scalars['String'];
  readonly errorMessage: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Maybe<Becadapter_Error>>>;
};

export type Becadapter_McManualFailPaymentSlip = {
  readonly paymentSlipId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualResetAccountCreationStateToInitiated = {
  readonly accountCreationId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualRetryAccountCreation = {
  readonly accountCreationIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualRetryAccountingTransfer = {
  readonly transferIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reason: Scalars['String'];
  readonly interestBearingDate?: Maybe<Scalars['String']>;
  readonly allowFromCompletedState?: Maybe<Scalars['Boolean']>;
};

export type Becadapter_McManualRetryCustomerOnboarding = {
  readonly customerOnboardingIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualRetryLoanConfigs = {
  readonly loanConfigIDs?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualSkipBecAccountCreation = {
  readonly accountCreationId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualSucceedAccountingTransfer = {
  readonly transferId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_McManualSucceedCreditTransfer = {
  readonly creditTransferId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_McPokeAccountCreation = {
  readonly accountCreationId: Scalars['String'];
};

export type Becadapter_McPokeAccountingTransfer = {
  readonly transferIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McPokeCreditTransfer = {
  readonly transferIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McPokeCustomerOnboarding = {
  readonly onbaordingIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McRepublishBecPostingEntryCreated = {
  readonly accountId: Scalars['String'];
  readonly transactionId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_McRepublishExternalEventCommand = {
  readonly eventStreamType: Becadapter_EventStreamType;
  readonly events: ReadonlyArray<Becadapter_Event>;
};

export type Becadapter_McReverseUserToSystemAccountingTransfer = {
  readonly accountId: Scalars['String'];
  readonly accountingTransferID: Scalars['String'];
  readonly xferE2EID?: Maybe<Scalars['String']>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McSetAccountBankZoneIDs = {
  readonly accounts?: Maybe<ReadonlyArray<Becadapter_BankZoneAndAccountId>>;
  readonly reason: Scalars['String'];
};

export type Becadapter_McUpdateBecHomeBanking = {
  readonly userId: Scalars['String'];
  readonly accountIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly newHomebankingId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Becadapter_Transaction = {
  readonly __typename?: 'becadapter_Transaction';
  readonly id: Scalars['String'];
  readonly becId: Scalars['String'];
  readonly postingTime: Scalars['String'];
  readonly transactionText?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['Float']>;
  readonly correlationId?: Maybe<Scalars['String']>;
};

export type Billys_Billing_UserInfo = {
  readonly __typename?: 'billys_billing_UserInfo';
  readonly userId: Scalars['String'];
  readonly disabled: Scalars['Boolean'];
  readonly created: Scalars['String'];
  readonly updated: Scalars['String'];
};

export type Brand_Brand = {
  readonly __typename?: 'brand_Brand';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly websiteURL?: Maybe<Scalars['String']>;
  readonly category: Brand_Category;
  readonly businessCategory?: Maybe<Brand_Category>;
  readonly color?: Maybe<Scalars['String']>;
  readonly iconURL?: Maybe<Scalars['String']>;
  readonly creditorNumbers?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly stockTickers?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly emoji?: Maybe<Scalars['String']>;
};

export type Brand_BrandCreated = {
  readonly __typename?: 'brand_BrandCreated';
  readonly brand: Brand_Brand;
  readonly uploadUrl: Scalars['String'];
};

export type Brand_BrandInput = {
  readonly name: Scalars['String'];
  readonly websiteURL?: Maybe<Scalars['String']>;
  readonly categoryID: Scalars['ID'];
  readonly businessCategoryID?: Maybe<Scalars['ID']>;
  readonly color: Scalars['String'];
  readonly creditorNumbers?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly stockTickers?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly emoji?: Maybe<Scalars['String']>;
};

export type Brand_Categories = {
  readonly __typename?: 'brand_Categories';
  readonly business: ReadonlyArray<Brand_Category>;
  readonly personal: ReadonlyArray<Brand_Category>;
};

export type Brand_Category = {
  readonly __typename?: 'brand_Category';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type Brand_Company = {
  readonly __typename?: 'brand_Company';
  readonly name: Scalars['String'];
  readonly legalName: Scalars['String'];
  readonly domain: Scalars['String'];
  readonly tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly description: Scalars['String'];
  readonly logo: Scalars['String'];
  readonly twitterHandle: Scalars['String'];
  readonly twitterUrl: Scalars['String'];
  readonly sector: Scalars['String'];
  readonly industryGroup: Scalars['String'];
  readonly industry: Scalars['String'];
  readonly subIndustry: Scalars['String'];
  readonly sicCode: Scalars['String'];
  readonly naicsCode: Scalars['String'];
  readonly ticker: Scalars['String'];
};

export type Brand_Occurence = {
  readonly __typename?: 'brand_Occurence';
  readonly origin: Scalars['String'];
  readonly count: Scalars['Int'];
};

export enum Brand_Origin {
  Lunar = 'LUNAR',
  External = 'EXTERNAL',
  Nykredit = 'NYKREDIT',
  PrepaidGps = 'PREPAID_GPS',
}

export type Brand_Title = {
  readonly __typename?: 'brand_Title';
  readonly id: Scalars['ID'];
  readonly transactionTitle: Scalars['String'];
  readonly brandId?: Maybe<Scalars['String']>;
  readonly brandName?: Maybe<Scalars['String']>;
  readonly occurences: Scalars['Int'];
  readonly occurencesMap: ReadonlyArray<Brand_Occurence>;
};

export enum Brand_TitlesFilter {
  All = 'ALL',
  Branded = 'BRANDED',
  Unbranded = 'UNBRANDED',
}

export type Brand_Unbranded = {
  readonly __typename?: 'brand_Unbranded';
  readonly id: Scalars['ID'];
  readonly transactionTitle: Scalars['String'];
  readonly occurrences: Scalars['String'];
};

export type Braze_Sync_Response = {
  readonly __typename?: 'braze_sync_Response';
  readonly success: Scalars['Boolean'];
  readonly error?: Maybe<Scalars['String']>;
};

export type CancelFuturePayload = {
  readonly __typename?: 'CancelFuturePayload';
  readonly success: Scalars['Boolean'];
  readonly error: Scalars['String'];
};

export type CardInfo = {
  readonly __typename?: 'CardInfo';
  readonly stan?: Maybe<Scalars['String']>;
  readonly cardNumberMasked?: Maybe<Scalars['String']>;
  readonly cardType?: Maybe<CardType>;
  readonly cardProductType?: Maybe<CardProductType>;
  readonly reservationId?: Maybe<Scalars['String']>;
  readonly canDelete?: Maybe<Scalars['Boolean']>;
};

export type Cardmanagement_Activity = {
  readonly __typename?: 'cardmanagement_Activity';
  readonly happenedAt: Scalars['String'];
  readonly action: Cardmanagement_ActivityAction;
  readonly reason?: Maybe<Cardmanagement_Reason>;
  readonly performedBy: Scalars['String'];
};

export enum Cardmanagement_ActivityAction {
  CardOrdered = 'CardOrdered',
  CardStateActive = 'CardStateActive',
  CardStateBlocked = 'CardStateBlocked',
  CardStateFrozen = 'CardStateFrozen',
  CardClosed = 'CardClosed',
  CardAwaitingEngraving = 'CardAwaitingEngraving',
}

export type Cardmanagement_Card = {
  readonly __typename?: 'cardmanagement_Card';
  readonly id: Scalars['String'];
  readonly createdAt: Scalars['String'];
  readonly name: Scalars['String'];
  readonly state: Cardmanagement_CardState;
  readonly bankAccountId: Scalars['String'];
  readonly type: Cardmanagement_CardType;
  readonly design: Cardmanagement_CardDesign;
  readonly maskedPan: Scalars['String'];
  readonly expiry: Cardmanagement_CardExpiry;
  readonly activity: ReadonlyArray<Cardmanagement_Activity>;
  readonly showDeliveryInformation: Scalars['Boolean'];
  readonly orderedDate: Scalars['String'];
  readonly shippedDate: Scalars['String'];
  readonly expectedDate: Scalars['String'];
  readonly externalId: Scalars['String'];
};

export enum Cardmanagement_CardDesign {
  FreemiumBlack = 'FreemiumBlack',
  StandardCoral = 'StandardCoral',
  PremiumGrey = 'PremiumGrey',
  PremiumRoseGold = 'PremiumRoseGold',
  PremiumGold = 'PremiumGold',
  BusinessGrey = 'BusinessGrey',
  BusinessGrowSilver = 'BusinessGrowSilver',
  Virtual = 'Virtual',
  VirtualBusiness = 'VirtualBusiness',
  ProBlack = 'ProBlack',
  ProSilver = 'ProSilver',
  ProRoseGold = 'ProRoseGold',
  AstralisBlack = 'AstralisBlack',
}

export type Cardmanagement_CardExpiry = {
  readonly __typename?: 'cardmanagement_CardExpiry';
  readonly month: Scalars['String'];
  readonly year: Scalars['String'];
};

export enum Cardmanagement_CardHolderSegment {
  BusinessLight = 'BusinessLight',
  BusinessProf = 'BusinessProf',
  PrivateFremium = 'PrivateFremium',
  PrivatePremium = 'PrivatePremium',
}

export enum Cardmanagement_CardState {
  Ordered = 'Ordered',
  Active = 'Active',
  Frozen = 'Frozen',
  Blocked = 'Blocked',
  Deleted = 'Deleted',
}

export enum Cardmanagement_CardType {
  Physical = 'Physical',
  Virtual = 'Virtual',
}

export type Cardmanagement_CommandResponse = {
  readonly __typename?: 'cardmanagement_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Cardmanagement_Company = {
  readonly __typename?: 'cardmanagement_Company';
  readonly id: Scalars['String'];
};

export enum Cardmanagement_CompanyClassification {
  Light = 'Light',
  Prof = 'Prof',
}

export enum Cardmanagement_DeliveryType {
  Standard = 'Standard',
  Express = 'Express',
}

export type Cardmanagement_Engraving = {
  readonly __typename?: 'cardmanagement_Engraving';
  readonly id: Scalars['String'];
  readonly status: Cardmanagement_EngravingStatus;
  readonly userId: Scalars['String'];
};

export enum Cardmanagement_EngravingFilter {
  Awaiting = 'Awaiting',
}

export type Cardmanagement_EngravingImage = {
  readonly __typename?: 'cardmanagement_EngravingImage';
  readonly fileUrl: Scalars['String'];
};

export enum Cardmanagement_EngravingStatus {
  Awaiting = 'Awaiting',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export type Cardmanagement_InputAddress = {
  readonly streetName: Scalars['String'];
  readonly streetNumber: Scalars['String'];
  readonly floor: Scalars['String'];
  readonly placeName: Scalars['String'];
  readonly coAddress: Scalars['String'];
  readonly postalCode: Scalars['String'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
};

export enum Cardmanagement_InputCardState {
  Active = 'Active',
  Frozen = 'Frozen',
  Blocked = 'Blocked',
}

export type Cardmanagement_Money = {
  readonly amount: Scalars['Float'];
  readonly currency: Scalars['String'];
};

export type Cardmanagement_PhysicalOrderCardInput = {
  readonly userId: Scalars['String'];
  readonly design: Cardmanagement_CardDesign;
  readonly delivery?: Maybe<Cardmanagement_DeliveryType>;
  readonly pinIssuerId: Scalars['String'];
  readonly bankAccountId: Scalars['String'];
  readonly cardPrice: Cardmanagement_Money;
  readonly personalizationParameter?: Maybe<Scalars['String']>;
};

export type Cardmanagement_PhysicalOrderWithPinInput = {
  readonly userId: Scalars['String'];
  readonly design: Cardmanagement_CardDesign;
  readonly delivery?: Maybe<Cardmanagement_DeliveryType>;
  readonly bankAccountId: Scalars['String'];
  readonly cardPrice: Cardmanagement_Money;
  readonly pin: ReadonlyArray<Scalars['Int']>;
  readonly personalizationParameter?: Maybe<Scalars['String']>;
};

export enum Cardmanagement_Reason {
  Lost = 'Lost',
  Stolen = 'Stolen',
  Fraud = 'Fraud',
  SuspectedFraud = 'SuspectedFraud',
  WearAndTear = 'WearAndTear',
  Other = 'Other',
  OnlyCardholderAccess = 'OnlyCardholderAccess',
  OthersAccess = 'OthersAccess',
  PremiumDowngrade = 'PremiumDowngrade',
  EngravingRejected = 'EngravingRejected',
  AccountAccessRemoved = 'AccountAccessRemoved',
}

export enum Cardmanagement_ReplacementType {
  SamePinpan = 'SamePINPAN',
}

export type Cardmanagement_ReplacePhysicalCardInput = {
  readonly userId: Scalars['String'];
  readonly cardId: Scalars['String'];
  readonly replacementType: Cardmanagement_ReplacementType;
  readonly delivery: Cardmanagement_DeliveryType;
};

export type Cardmanagement_UpdateCompanyInfoInput = {
  readonly companyId: Scalars['String'];
  readonly relation: Scalars['String'];
  readonly name: Scalars['String'];
  readonly cvr: Scalars['String'];
  readonly email: Scalars['String'];
  readonly phoneCountry: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
  readonly address: Cardmanagement_InputAddress;
};

export enum Cardmanagement_UserClassification {
  None = 'None',
  Premium = 'Premium',
  Pro = 'Pro',
}

export type Cardmanagement_VirtualOrderCardInput = {
  readonly userId: Scalars['String'];
  readonly cardHolderSegment: Cardmanagement_CardHolderSegment;
  readonly bankAccountId: Scalars['String'];
};

export enum CardProductType {
  BlackCard = 'BlackCard',
  TravelCard = 'TravelCard',
  BusinessCard = 'BusinessCard',
}

export type Cardsettlement_CardClearingInfo = {
  readonly __typename?: 'cardsettlement_CardClearingInfo';
  readonly FileName: Scalars['String'];
  readonly Recurring: Scalars['Boolean'];
  readonly CurrencyRate: Scalars['Float'];
};

export type Cardsettlement_Clearing = {
  readonly __typename?: 'cardsettlement_Clearing';
  readonly Id: Scalars['String'];
  readonly BatchId: Scalars['String'];
  readonly Created: Scalars['cardsettlement_Date'];
  readonly Done?: Maybe<Scalars['cardsettlement_Date']>;
  readonly Published?: Maybe<Scalars['cardsettlement_Date']>;
  readonly AccountId?: Maybe<Scalars['String']>;
  readonly ReservationId?: Maybe<Scalars['String']>;
  readonly SRN: Scalars['String'];
  readonly MaskedPan: Scalars['String'];
  readonly CardId: Scalars['String'];
  readonly BillingAmount: Cardsettlement_Money;
  readonly Debit?: Maybe<Cardsettlement_DebitClearingInfo>;
  readonly Card?: Maybe<Cardsettlement_CardClearingInfo>;
};

export type Cardsettlement_ClearingQuery = {
  readonly BatchId?: Maybe<Scalars['String']>;
  readonly Progress?: Maybe<Scalars['Boolean']>;
  readonly AccountIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly Limit?: Maybe<Scalars['Int']>;
  readonly Offset?: Maybe<Scalars['Int']>;
};

export type Cardsettlement_ClearingResponse = {
  readonly __typename?: 'cardsettlement_ClearingResponse';
  readonly clearings: ReadonlyArray<Cardsettlement_Clearing>;
};

export type Cardsettlement_CommandResponse = {
  readonly __typename?: 'cardsettlement_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Cardsettlement_DebitClearingInfo = {
  readonly __typename?: 'cardsettlement_DebitClearingInfo';
  readonly FileName: Scalars['String'];
  readonly TransactionAmount: Cardsettlement_Money;
  readonly Description: Scalars['String'];
};

export type Cardsettlement_FileInfo = {
  readonly __typename?: 'cardsettlement_FileInfo';
  readonly FileName: Scalars['String'];
  readonly Size: Scalars['Int'];
  readonly ModTime: Scalars['cardsettlement_Date'];
  readonly IsDir: Scalars['Boolean'];
};

export type Cardsettlement_IgnoreCmd = {
  readonly id: Scalars['String'];
  readonly ignore: Scalars['Boolean'];
};

export type Cardsettlement_ListPlansQuery = {
  readonly all?: Maybe<Scalars['Boolean']>;
};

export type Cardsettlement_Money = {
  readonly __typename?: 'cardsettlement_Money';
  readonly Amount: Scalars['Float'];
  readonly Currency: Scalars['String'];
};

export type Cardsettlement_PlanListResponse = {
  readonly __typename?: 'cardsettlement_PlanListResponse';
  readonly plans?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type Cardsettlement_RerunCommand = {
  readonly file: Scalars['String'];
};

export type Cardsettlement_StartPlanCommand = {
  readonly planID: Scalars['String'];
};

export type Cardsettlement_Status = {
  readonly __typename?: 'cardsettlement_Status';
  readonly Id: Scalars['Int'];
  readonly Label: Scalars['String'];
};

export enum CardType {
  VisaDebit = 'VisaDebit',
  MasterCardDebit = 'MasterCardDebit',
}

export type ChargeDescriptor = {
  readonly __typename?: 'ChargeDescriptor';
  readonly id: Scalars['ID'];
  readonly type: ChargeType;
  readonly defaultTransactionTitle?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly currency: Scalars['Currency'];
  readonly label: Scalars['String'];
  readonly description: Scalars['String'];
  /** The bank relation */
  readonly relations: ReadonlyArray<Relation>;
};

export type ChargeDescriptorCollection = {
  readonly __typename?: 'ChargeDescriptorCollection';
  readonly count: Scalars['Int'];
  readonly nodes: ReadonlyArray<ChargeDescriptor>;
};

export type ChargeInput = {
  readonly type: ChargeType;
  /** Id of the customer to charge from */
  readonly customerId: Scalars['ID'];
  /** Id of the customer account to charge from */
  readonly accountId: Scalars['ID'];
  /** Funds charged in account currency */
  readonly amount: Scalars['Decimal'];
  /** The currency in the ISO-4217 currency code format. */
  readonly currency: Scalars['Currency'];
  /** What to display in the customer's transaction list - uses a default for each charge type if not set */
  readonly transactionTitle?: Maybe<Scalars['String']>;
  /** Documentation for initiating the charge */
  readonly reason: ReasonInput;
};

export type ChargePayload = {
  readonly __typename?: 'ChargePayload';
  /** Populated with reason if the charge failed immediately */
  readonly error?: Maybe<Scalars['String']>;
  /** Transfer status of the charge */
  readonly status?: Maybe<TransferStatus>;
};

/**
 * The type of charge for a user-to-system accounting transaction
 * Note, service fees will be reported to the Fee Statement service.
 */
export enum ChargeType {
  /** Service fee for second reminder: A second reminder has been sent and the service fee needs to be charged */
  ChargeServiceFeeTwo = 'CHARGE_SERVICE_FEE_TWO',
  /** Service fee for BS: Due to overdrafting account with BS */
  ChargeServiceFeeBs = 'CHARGE_SERVICE_FEE_BS',
  /** Reject chargeback: Reject a previously made chargeback */
  ChargeChargebackRejected = 'CHARGE_CHARGEBACK_REJECTED',
  /** Multiple account: Charge one month of an extra account */
  ChargeExtraAccountMonthly = 'CHARGE_EXTRA_ACCOUNT_MONTHLY',
  /** Withdrawal fee: Charge a refunded withdrawal fee */
  ChargeWithdrawalFee = 'CHARGE_WITHDRAWAL_FEE',
  /** Overdraft provision: Charge a refunded overdraft provision */
  ChargeOverdraftProvision = 'CHARGE_OVERDRAFT_PROVISION',
  /** Stock brokerage: Charge a refunded stock brokerage */
  ChargeStockBrokerage = 'CHARGE_STOCK_BROKERAGE',
  /** Pro subscription: Charge for a month of pro subscription */
  ChargeProSubscriptionMonthly = 'CHARGE_PRO_SUBSCRIPTION_MONTHLY',
  /** Visa debit fee: Charge a refunded Visa debit fee */
  ChargeVisaDebit = 'CHARGE_VISA_DEBIT',
  /** Premium subscription: Charge for a month of premium subscription */
  ChargePremiumSubscriptionMonthly = 'CHARGE_PREMIUM_SUBSCRIPTION_MONTHLY',
  /** Replacement card: Charge for a Replacement card */
  ChargeReplacementCard = 'CHARGE_REPLACEMENT_CARD',
  /** Annual business subscription fee: 12 months subscription fee. */
  ChargeDklbbSubscriptionAnnual = 'CHARGE_DKLBB_SUBSCRIPTION_ANNUAL',
  /** Service fee for business subscription payment: For missing a business subscription payment. */
  ChargeDklbbSubscriptionMissedPayment = 'CHARGE_DKLBB_SUBSCRIPTION_MISSED_PAYMENT',
  /** FI card creation fee: For the creation of an FI card ("fælles indbetalingskort"). */
  ChargeDklbbFiCreate = 'CHARGE_DKLBB_FI_CREATE',
  /** Existing FI move fee: For the move of an existing FI-creditor. */
  ChargeDklbbFiMove = 'CHARGE_DKLBB_FI_MOVE',
  /** Produce and deliver FI fee: For producing and delivering an FI card ("fælles indbetalingskort"). */
  ChargeDklbbFiProduceAndDeliver = 'CHARGE_DKLBB_FI_PRODUCE_AND_DELIVER',
  /** Inquiry from SKAT (500 DKK): Fee for collecting information on behalf of the customer */
  ChargeDklbbInquirySkat_500 = 'CHARGE_DKLBB_INQUIRY_SKAT_500',
  /** Inquiry from SKAT (1000 DKK): Fee for collecting information on behalf of the customer */
  ChargeDklbbInquirySkat_1000 = 'CHARGE_DKLBB_INQUIRY_SKAT_1000',
  /** Withdrawal fee ATM: For an ATM withdrawal fee */
  ChargeSelbWitdhrawalFee = 'CHARGE_SELB_WITDHRAWAL_FEE',
  /** Multiple account: One month of an extra account */
  ChargeSelbExtraAccountMonthly = 'CHARGE_SELB_EXTRA_ACCOUNT_MONTHLY',
  /** Pro subscription: One month of Pro subscription */
  ChargeSelbProSubscriptionMonthly = 'CHARGE_SELB_PRO_SUBSCRIPTION_MONTHLY',
  /** Premium subscription: One month of Premium subscription */
  ChargeSelbPremiumSubscriptionMonthly = 'CHARGE_SELB_PREMIUM_SUBSCRIPTION_MONTHLY',
  /** Replacement card - Pro: For a Pro replacement card */
  ChargeSelbReplacementCardPro = 'CHARGE_SELB_REPLACEMENT_CARD_PRO',
  /** Replacement card: For a normal replacement card */
  ChargeSelbReplacementCard = 'CHARGE_SELB_REPLACEMENT_CARD',
  /** Service fee: Service fee 2 for minus on account   */
  ChargeSelbServiceFeeTwo = 'CHARGE_SELB_SERVICE_FEE_TWO',
  /** Apple Pay incentives: Give money back due to apple pay incentives */
  ChargeSelbApplePayIncentives = 'CHARGE_SELB_APPLE_PAY_INCENTIVES',
}

export type CompanyInfo = {
  readonly __typename?: 'CompanyInfo';
  readonly companyName: Scalars['String'];
  readonly companyAddress: Address;
  readonly ownerAddress: Address;
  readonly cvr: Scalars['String'];
  readonly ownerName: Scalars['String'];
  readonly companyPurpose: Scalars['String'];
  readonly companyForm: Scalars['String'];
  readonly industryCode: Scalars['String'];
  readonly employees: Scalars['String'];
  readonly biIndustryCodesList: ReadonlyArray<Scalars['String']>;
};

export type ConversionInfo = {
  readonly __typename?: 'ConversionInfo';
  readonly id?: Maybe<Scalars['ID']>;
  readonly from?: Maybe<Money>;
  readonly to?: Maybe<Money>;
  readonly offline?: Maybe<Scalars['Boolean']>;
  readonly reversed?: Maybe<Scalars['Boolean']>;
  readonly type?: Maybe<ConversionType>;
};

export enum ConversionType {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Exchange = 'exchange',
  InternalDeposit = 'internalDeposit',
  InternalExchange = 'internalExchange',
  InternalReverseExchange = 'internalReverseExchange',
  InternalWithdraw = 'internalWithdraw',
  InternalDepositFromTopup = 'internalDepositFromTopup',
  Unknown = 'unknown',
}

export type Credit_Loan_Loan = {
  readonly __typename?: 'credit_loan_Loan';
  readonly id: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly applicationId: Scalars['ID'];
  readonly currency: Scalars['String'];
  readonly state: Credit_Loan_LoanState;
  readonly remainingAmount: Scalars['String'];
  readonly amount: Scalars['String'];
  readonly nextPaymentDate: Scalars['String'];
  readonly firstPaymentDate: Scalars['String'];
  readonly loanCompletedDate: Scalars['String'];
  readonly created: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly loanProduct: Scalars['String'];
  readonly paymentAccountId: Scalars['String'];
  readonly loanAccountId: Scalars['String'];
  readonly loanCategory: Scalars['String'];
  readonly stateHistory: ReadonlyArray<Credit_Loan_StateTransition>;
  readonly transactions: ReadonlyArray<Credit_Loan_Transaction>;
};

export type Credit_Loan_LoanInformation = {
  readonly __typename?: 'credit_loan_LoanInformation';
  readonly loans: ReadonlyArray<Credit_Loan_Loan>;
  readonly totalLoanAmount: Scalars['String'];
  readonly previousLossAmount: Scalars['String'];
  readonly error?: Maybe<Scalars['String']>;
};

export type Credit_Loan_LoanInformationInput = {
  readonly userId: Scalars['ID'];
};

export type Credit_Loan_LoanInput = {
  readonly loanId: Scalars['ID'];
  readonly userId: Scalars['ID'];
};

export type Credit_Loan_LoanResponse = {
  readonly __typename?: 'credit_loan_LoanResponse';
  readonly loan: Credit_Loan_Loan;
  readonly error?: Maybe<Scalars['String']>;
};

export enum Credit_Loan_LoanState {
  PendingSignature = 'PendingSignature',
  Signed = 'Signed',
  Rejected = 'Rejected',
  Overdraft = 'Overdraft',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Defaulted = 'Defaulted',
}

export type Credit_Loan_PayDownpaymentInput = {
  readonly loanId: Scalars['ID'];
  readonly amount: Scalars['String'];
  readonly fromAccount: Scalars['String'];
  readonly currency: Scalars['String'];
};

export type Credit_Loan_PayLaterInput = {
  readonly userId: Scalars['ID'];
  readonly credit: Scalars['String'];
};

export type Credit_Loan_StateTransition = {
  readonly __typename?: 'credit_loan_StateTransition';
  readonly transitionTime: Scalars['String'];
  readonly fromState: Credit_Loan_LoanState;
  readonly toState: Credit_Loan_LoanState;
};

export type Credit_Loan_Transaction = {
  readonly __typename?: 'credit_loan_Transaction';
  readonly transactionTime: Scalars['String'];
  readonly senderAccount: Scalars['String'];
  readonly receiverAccount: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly amount: Scalars['String'];
};

export type CreditApplicationContent = {
  readonly __typename?: 'CreditApplicationContent';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly created: Scalars['Date'];
  readonly updated?: Maybe<Scalars['Date']>;
};

export type Creditengine_Application = {
  readonly __typename?: 'creditengine_Application';
  readonly id: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly data: Creditengine_Data;
  readonly type: Creditengine_ApplicationType;
  readonly state: Creditengine_ApplicationState;
  readonly stateHistory: ReadonlyArray<Creditengine_StateTransition>;
  readonly created: Scalars['String'];
  readonly updated: Scalars['String'];
};

export type Creditengine_ApplicationInput = {
  readonly applicationId: Scalars['ID'];
};

export type Creditengine_ApplicationParams = {
  readonly __typename?: 'creditengine_ApplicationParams';
  readonly category: Scalars['String'];
  readonly expenses: Scalars['String'];
  readonly housingType: Creditengine_HousingType;
  readonly zipCode: Scalars['String'];
  readonly maritalStatus: Creditengine_MaritalStatus;
  readonly employmentType: Creditengine_EmploymentType;
  readonly numberOfKids: Scalars['Int'];
  readonly numberOfCars: Scalars['Int'];
  readonly cars: ReadonlyArray<Creditengine_Car>;
  readonly associations: ReadonlyArray<Scalars['String']>;
  readonly insurances: ReadonlyArray<Scalars['String']>;
  readonly subscriptions: ReadonlyArray<Scalars['String']>;
  readonly additionalMonthlyExpenses: Scalars['String'];
  readonly salaryFundingAccount: Scalars['Boolean'];
  readonly salaryLastThreeMonths: ReadonlyArray<Scalars['String']>;
  readonly currentDept: Scalars['String'];
  readonly currentPublicDept: Scalars['String'];
  readonly principal: Scalars['String'];
  readonly establishmentExpenses: Scalars['String'];
  readonly terms: Scalars['Int'];
};

export type Creditengine_ApplicationParamsInput = {
  readonly category: Scalars['String'];
  readonly expenses: Scalars['Int'];
  readonly housingType: Creditengine_HousingType;
  readonly zipCode: Scalars['Int'];
  readonly maritalStatus: Creditengine_MaritalStatus;
  readonly employmentType: Creditengine_EmploymentType;
  readonly numberOfKids: Scalars['Int'];
  readonly numberOfCars: Scalars['Int'];
  readonly cars: ReadonlyArray<Creditengine_CarInput>;
  readonly associations: ReadonlyArray<Scalars['String']>;
  readonly insurances: ReadonlyArray<Scalars['String']>;
  readonly subscriptions: ReadonlyArray<Scalars['String']>;
  readonly additionalMonthlyExpenses: Scalars['Int'];
  readonly newDept: Scalars['Int'];
  readonly salaryFundingAccount: Scalars['Boolean'];
  readonly salaryLastThreeMonths: ReadonlyArray<Scalars['Int']>;
  readonly currentDept: Scalars['Int'];
  readonly currentPublicDept: Scalars['Int'];
  readonly principal: Scalars['Int'];
  readonly establishmentExpenses: Scalars['Int'];
  readonly terms: Scalars['Int'];
};

export type Creditengine_ApplicationResponse = {
  readonly __typename?: 'creditengine_ApplicationResponse';
  readonly application?: Maybe<Creditengine_Application>;
  readonly error?: Maybe<Scalars['String']>;
};

export type Creditengine_ApplicationsResponse = {
  readonly __typename?: 'creditengine_ApplicationsResponse';
  readonly applications?: Maybe<ReadonlyArray<Creditengine_Application>>;
  readonly error?: Maybe<Scalars['String']>;
};

export enum Creditengine_ApplicationState {
  Initial = 'Initial',
  Elevate = 'Elevate',
  ModelEval = 'ModelEval',
  ESkat = 'ESkat',
  RkiLookup = 'RKILookup',
  Effectuate = 'Effectuate',
  Closed = 'Closed',
}

export enum Creditengine_ApplicationType {
  ConsumerLoan = 'ConsumerLoan',
  TemporaryOverdraft = 'TemporaryOverdraft',
}

export type Creditengine_Car = {
  readonly __typename?: 'creditengine_Car';
  readonly ownershipType: Creditengine_CarOwnershipType;
  readonly cost: Scalars['Int'];
};

export type Creditengine_CarInput = {
  readonly ownershipType: Creditengine_CarOwnershipType;
  readonly cost: Scalars['Int'];
};

export enum Creditengine_CarOwnershipType {
  Owner = 'Owner',
  Lease = 'Lease',
  Company = 'Company',
}

export type Creditengine_Context = {
  readonly name: Scalars['String'];
  readonly role: Scalars['String'];
};

export type Creditengine_CreditModelResult = {
  readonly __typename?: 'creditengine_CreditModelResult';
  readonly creditScore: Scalars['Int'];
  readonly disposableIncome: Scalars['String'];
  readonly riskClass: Creditengine_RiskClass;
  readonly depriciationRate: Scalars['String'];
  readonly loanAmount: Scalars['String'];
  readonly interestRate: Scalars['String'];
  readonly rejectionReason: Scalars['String'];
  readonly apr: Scalars['String'];
  readonly terms: Scalars['Int'];
};

export type Creditengine_CreditModelResultInput = {
  readonly creditScore: Scalars['Int'];
  readonly disposableIncome: Scalars['Int'];
  readonly riskClass: Creditengine_RiskClass;
  readonly depriciationRate: Scalars['String'];
  readonly loanAmount: Scalars['String'];
  readonly interestRate: Scalars['String'];
  readonly apr: Scalars['String'];
  readonly terms: Scalars['Int'];
};

export type Creditengine_Data = {
  readonly __typename?: 'creditengine_Data';
  readonly params: Creditengine_ApplicationParams;
  readonly elevation: Scalars['Boolean'];
  readonly elevationReason?: Maybe<Scalars['String']>;
  readonly approved: Scalars['Boolean'];
  readonly rejected: Scalars['Boolean'];
  readonly loanAmount: Scalars['String'];
  readonly rki: Creditengine_RkiStatus;
  readonly creditScoring?: Maybe<Creditengine_CreditModelResult>;
};

export type Creditengine_ElevateApplicationInput = {
  readonly applicationId: Scalars['ID'];
  readonly reason: Scalars['String'];
};

export enum Creditengine_EmploymentType {
  Employed = 'Employed',
  SelfEmployed = 'SelfEmployed',
  Student = 'Student',
  Unemployed = 'Unemployed',
  Retired = 'Retired',
}

export enum Creditengine_HousingType {
  Owned = 'Owned',
  Coop = 'Coop',
  WithParents = 'WithParents',
  Rented = 'Rented',
}

export enum Creditengine_MaritalStatus {
  Married = 'Married',
  Single = 'Single',
  Widow = 'Widow',
  Cohabiting = 'Cohabiting',
}

export type Creditengine_OverrideRejectionInput = {
  readonly applicationId: Scalars['ID'];
  readonly reason: Scalars['String'];
};

export enum Creditengine_RiskClass {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  C = 'C',
  D = 'D',
  E = 'E',
}

export enum Creditengine_RkiStatus {
  Unknown = 'Unknown',
  NotRegistered = 'NotRegistered',
  Registered = 'Registered',
}

export type Creditengine_SetElevatedInput = {
  readonly applicationId: Scalars['ID'];
  readonly approved: Scalars['Boolean'];
  readonly publicDebt: Scalars['String'];
  readonly note: Scalars['String'];
};

export type Creditengine_SetRkiStatusInput = {
  readonly applicationId: Scalars['ID'];
  readonly status: Creditengine_RkiStatus;
};

export type Creditengine_SetStateInput = {
  readonly applicationId: Scalars['ID'];
  readonly state: Creditengine_ApplicationState;
};

export type Creditengine_SetStateResponse = {
  readonly __typename?: 'creditengine_SetStateResponse';
  readonly success: Scalars['Boolean'];
  readonly error?: Maybe<Scalars['String']>;
};

export type Creditengine_StateTransition = {
  readonly __typename?: 'creditengine_StateTransition';
  readonly transitionTime: Scalars['String'];
  readonly fromState: Creditengine_ApplicationState;
  readonly toState: Creditengine_ApplicationState;
};

export type Creditengine_UserInput = {
  readonly userId: Scalars['ID'];
};

export type Customer = {
  readonly __typename?: 'Customer';
  readonly id: Scalars['ID'];
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly phone?: Maybe<PhoneNumber>;
  readonly ssn: Scalars['String'];
  readonly cvr?: Maybe<Scalars['String']>;
  readonly relations: ReadonlyArray<Scalars['String']>;
  readonly customerRelations: ReadonlyArray<Customer>;
  readonly tasks: ReadonlyArray<Task>;
  readonly notes: NoteCollection;
  readonly address: Address;
  readonly lunarRelation: CustomerLunarRelation;
  readonly isBlocked: Scalars['Boolean'];
  readonly viewings: ViewingCollection;
  readonly tags: TagCollection;
  readonly ageSegment: AgeSegment;
  readonly companyInfo?: Maybe<CompanyInfo>;
  readonly isClosed: Scalars['Boolean'];
};

export type CustomerLunarRelation = {
  readonly __typename?: 'CustomerLunarRelation';
  readonly isEmployee: Scalars['Boolean'];
  readonly isManagementMember: Scalars['Boolean'];
  readonly isRiskTaker: Scalars['Boolean'];
  readonly isBoardMember: Scalars['Boolean'];
};

export type CustomerLunarRelationInput = {
  readonly isEmployee?: Maybe<Scalars['Boolean']>;
  readonly isManagementMember?: Maybe<Scalars['Boolean']>;
  readonly isRiskTaker?: Maybe<Scalars['Boolean']>;
  readonly isBoardMember?: Maybe<Scalars['Boolean']>;
};

export type CustomerTagInput = {
  readonly name: Scalars['String'];
  readonly customerId: Scalars['ID'];
};

export type Dinero_UserInfo = {
  readonly __typename?: 'dinero_UserInfo';
  readonly userId: Scalars['String'];
  readonly disabled: Scalars['Boolean'];
  readonly created: Scalars['String'];
  readonly updated: Scalars['String'];
};

export type Directdebit_BetalingsserviceInstruction = {
  readonly __typename?: 'directdebit_BetalingsserviceInstruction';
  readonly id: Scalars['String'];
  readonly instructionId: Scalars['String'];
  readonly status: Directdebit_BetalingsserviceInstructionStatus;
  readonly collectionDate: Scalars['String'];
  readonly hasAttachment: Scalars['Boolean'];
  readonly longDescription: Scalars['String'];
  readonly creditorFullName: Scalars['String'];
  readonly mandateName: Scalars['String'];
  readonly mandateCustomerNumber: Scalars['String'];
  readonly mandateNumber: Scalars['String'];
  readonly accountId: Scalars['String'];
  readonly amount: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly rejectable: Scalars['Boolean'];
};

export enum Directdebit_BetalingsserviceInstructionStatus {
  Unknown = 'UNKNOWN',
  Received = 'RECEIVED',
  ReadyForExecution = 'READY_FOR_EXECUTION',
  SettledRejectionPossible = 'SETTLED_REJECTION_POSSIBLE',
  SettlementCompleted = 'SETTLEMENT_COMPLETED',
  Rejecting = 'REJECTING',
  Rejected = 'REJECTED',
  Reversing = 'REVERSING',
  Reversed = 'REVERSED',
  Terminated = 'TERMINATED',
}

export type Directdebit_BetalingsserviceMandate = {
  readonly __typename?: 'directdebit_BetalingsserviceMandate';
  readonly id: Scalars['String'];
  readonly mandateId: Scalars['String'];
  readonly mandateNumber: Scalars['String'];
  readonly accountId: Scalars['String'];
  readonly creditorName: Scalars['String'];
  readonly effectiveDate: Scalars['String'];
  readonly status: Directdebit_BetalingsserviceMandateStatus;
  readonly customerNumber: Scalars['String'];
  readonly pbsNumber: Scalars['String'];
  readonly debtorGroupNumber: Scalars['String'];
  readonly canRevoke: Scalars['Boolean'];
  readonly instructions: ReadonlyArray<Directdebit_BetalingsserviceInstruction>;
};

export enum Directdebit_BetalingsserviceMandateStatus {
  Unknown = 'UNKNOWN',
  Received = 'RECEIVED',
  Modifying = 'MODIFYING',
  Active = 'ACTIVE',
  Revoking = 'REVOKING',
  Revoked = 'REVOKED',
  Terminated = 'TERMINATED',
}

export type Directdebit_CreateBetalingserviceMandateInput = {
  readonly userId: Scalars['String'];
  readonly accountId: Scalars['String'];
  readonly customerNumber: Scalars['String'];
  readonly pbsNumber: Scalars['String'];
  readonly debtorGroupNumber: Scalars['String'];
};

export type Directdebit_GetBetalingsserviceInstructionsInput = {
  readonly userId: Scalars['String'];
  readonly month: Scalars['String'];
  readonly year: Scalars['String'];
};

export type Directdebit_MoveMandatesFromBankInput = {
  readonly userId: Scalars['ID'];
  readonly AccountId: Scalars['ID'];
  readonly SortCode: Scalars['String'];
};

export type DirectDebitInfo = {
  readonly __typename?: 'DirectDebitInfo';
  readonly hasAttachment?: Maybe<Scalars['Boolean']>;
  readonly mandateName?: Maybe<Scalars['String']>;
  readonly mandateNumber?: Maybe<Scalars['String']>;
  readonly receiverFullName?: Maybe<Scalars['String']>;
  readonly message?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly canCancel: Scalars['Boolean'];
};

export type Document_Agreement = {
  readonly __typename?: 'document_Agreement';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly AcceptedAt: Scalars['String'];
};

export type Economic_UserInfo = {
  readonly __typename?: 'economic_UserInfo';
  readonly userId: Scalars['String'];
  readonly disabled: Scalars['Boolean'];
  readonly created: Scalars['String'];
  readonly updated: Scalars['String'];
};

export enum Environment {
  Dev = 'dev',
  Staging = 'staging',
  Prod = 'prod',
}

export type Eskat_RawEskat = {
  readonly __typename?: 'eskat_RawEskat';
  readonly id: Scalars['ID'];
  readonly updated: Scalars['String'];
  readonly consentId: Scalars['String'];
  readonly data: Scalars['String'];
  readonly statusCode: Scalars['Int'];
  readonly userId: Scalars['String'];
};

export type Eskat_RawEskatInput = {
  readonly userId?: Maybe<Scalars['String']>;
};

export type Eskat_RawEskatResponse = {
  readonly __typename?: 'eskat_RawEskatResponse';
  readonly rawEskat: ReadonlyArray<Maybe<Eskat_RawEskat>>;
  readonly error?: Maybe<Scalars['String']>;
};

export type Eskat_RecollectEskatInput = {
  readonly consentId: Scalars['String'];
};

export type Eskat_RecollectEskatResponse = {
  readonly __typename?: 'eskat_RecollectEskatResponse';
  readonly success: Scalars['Boolean'];
  readonly error?: Maybe<Scalars['String']>;
};

export type Eskat_ReplayRawEskatInput = {
  readonly id: Scalars['String'];
};

export type Eskat_ReplayRawEskatResponse = {
  readonly __typename?: 'eskat_ReplayRawEskatResponse';
  readonly success: Scalars['Boolean'];
  readonly error?: Maybe<Scalars['String']>;
};

export type Externalaccounts_Connection = {
  readonly __typename?: 'externalaccounts_Connection';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly iconUrl: Scalars['String'];
  readonly color: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly statusMessage: Scalars['String'];
};

export type Externalaccounts_ExportPaymentsCsvInput = {
  readonly userId?: Maybe<Scalars['String']>;
};

export type Externalaccounts_Payment = {
  readonly __typename?: 'externalaccounts_Payment';
  readonly id: Scalars['String'];
  readonly createdAt: Scalars['String'];
  readonly userId: Scalars['String'];
  readonly connectionId: Scalars['String'];
  readonly recipientBBAN: Scalars['String'];
  readonly senderBBAN: Scalars['String'];
  readonly amount: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly date?: Maybe<Scalars['String']>;
  readonly instant: Scalars['Boolean'];
  readonly messageToRecipient?: Maybe<Scalars['String']>;
  readonly messageToSender?: Maybe<Scalars['String']>;
  readonly status: Externalaccounts_PaymentStatus;
};

export enum Externalaccounts_PaymentStatus {
  Preparing = 'PREPARING',
  ReadyForAuthorize = 'READY_FOR_AUTHORIZE',
  Authorizing = 'AUTHORIZING',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

export type FeeInfo = {
  readonly __typename?: 'FeeInfo';
  readonly fixedAmount?: Maybe<Money>;
  readonly variableAmount?: Maybe<Money>;
};

export type GenericContent = {
  readonly __typename?: 'GenericContent';
  readonly id: Scalars['ID'];
  readonly created: Scalars['Date'];
  readonly updated?: Maybe<Scalars['Date']>;
  readonly fields: ReadonlyArray<Maybe<KeyValue>>;
};

export type GeoLocation = {
  readonly __typename?: 'GeoLocation';
  readonly formattedAddress?: Maybe<Scalars['String']>;
  readonly lat?: Maybe<Scalars['Float']>;
  readonly lng?: Maybe<Scalars['Float']>;
};

export enum Goalscore_ActivityType {
  GoalCreated = 'goalCreated',
  GoalCashedOut = 'goalCashedOut',
  GoalDeleted = 'goalDeleted',
  GoalRuleFailed = 'goalRuleFailed',
}

export type Goalscore_BalanceRecalculation = {
  readonly __typename?: 'goalscore_BalanceRecalculation';
  readonly amount?: Maybe<Goalscore_Money>;
  readonly success: Scalars['Boolean'];
};

export type Goalscore_FeedItem = {
  readonly __typename?: 'goalscore_FeedItem';
  readonly id: Scalars['ID'];
  readonly sort: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly goalId: Scalars['ID'];
  readonly type: Goalscore_FeedItemType;
  readonly displayDate: Scalars['String'];
  readonly balance: Goalscore_Money;
  readonly activity?: Maybe<Goalscore_FeedItemActivity>;
  readonly deposit?: Maybe<Goalscore_FeedItemDeposit>;
  readonly withdrawal?: Maybe<Goalscore_FeedItemWithdrawal>;
};

export type Goalscore_FeedItemActivity = {
  readonly __typename?: 'goalscore_FeedItemActivity';
  readonly id: Scalars['ID'];
  readonly type: Goalscore_ActivityType;
  readonly text: Scalars['String'];
  readonly imageUrl: Scalars['String'];
};

export type Goalscore_FeedItemDeposit = {
  readonly __typename?: 'goalscore_FeedItemDeposit';
  readonly id: Scalars['ID'];
  readonly text: Scalars['String'];
  readonly imageUrl: Scalars['String'];
  readonly amount: Goalscore_Money;
};

export enum Goalscore_FeedItemType {
  Activity = 'activity',
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
}

export type Goalscore_FeedItemWithdrawal = {
  readonly __typename?: 'goalscore_FeedItemWithdrawal';
  readonly id: Scalars['ID'];
  readonly text: Scalars['String'];
  readonly imageUrl: Scalars['String'];
  readonly amount: Goalscore_Money;
};

export type Goalscore_Goal = {
  readonly __typename?: 'goalscore_Goal';
  readonly id: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly fileId?: Maybe<Scalars['ID']>;
  readonly target?: Maybe<Goalscore_Money>;
  readonly balance: Goalscore_Money;
  readonly created: Scalars['String'];
  readonly deleted: Scalars['Boolean'];
  readonly cashedOut: Scalars['Boolean'];
};

export type Goalscore_Money = {
  readonly __typename?: 'goalscore_Money';
  readonly amount: Scalars['Float'];
  readonly currency: Scalars['String'];
};

export type Goalsrules_CommandResponse = {
  readonly __typename?: 'goalsrules_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Goalsrules_DeleteRuleCommand = {
  readonly ruleId: Scalars['String'];
};

export type Goalsrules_Rule = {
  readonly __typename?: 'goalsrules_Rule';
  readonly type: Goalsrules_RuleType;
};

export enum Goalsrules_RuleType {
  RoundUp = 'RoundUp',
  Transfer = 'Transfer',
  Unknown = 'Unknown',
}

export type Insurance_Activity = {
  readonly __typename?: 'insurance_Activity';
  readonly id: Scalars['String'];
  readonly insuranceId: Scalars['String'];
  readonly createdAt: Scalars['String'];
  readonly type: Insurance_ActivityType;
  readonly context?: Maybe<Insurance_ActivityContext>;
};

export type Insurance_ActivityContext = {
  readonly __typename?: 'insurance_ActivityContext';
  readonly user?: Maybe<Scalars['String']>;
  readonly reason?: Maybe<Scalars['String']>;
};

export enum Insurance_ActivityType {
  Created = 'CREATED',
  PaymentSuccess = 'PAYMENT_SUCCESS',
  PaymentFailed = 'PAYMENT_FAILED',
  ActiveCancel = 'ACTIVE_CANCEL',
  DeleteRequested = 'DELETE_REQUESTED',
  Canceled = 'CANCELED',
  ChangeWorld = 'CHANGE_WORLD',
  ChangeEurope = 'CHANGE_EUROPE',
  NewPolicyFile = 'NEW_POLICY_FILE',
  Unknown = 'UNKNOWN',
}

export type Insurance_CreateActivityContextInput = {
  readonly user: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Insurance_Insurance = {
  readonly __typename?: 'insurance_Insurance';
  readonly id: Scalars['String'];
  readonly createdAt: Scalars['String'];
  readonly userId: Scalars['String'];
  readonly policyNumber: Scalars['String'];
  readonly type: Insurance_InsuranceType;
  readonly subType: Insurance_InsuranceSubType;
  readonly status: Insurance_InsuranceStatus;
  readonly renewsAt: Scalars['String'];
  readonly expiresAt?: Maybe<Scalars['String']>;
  readonly policyFileUrl?: Maybe<Scalars['String']>;
};

export enum Insurance_InsuranceStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  ActiveCancelled = 'ACTIVE_CANCELLED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
}

export enum Insurance_InsuranceSubType {
  TravelEurope = 'TRAVEL_EUROPE',
  TravelWorld = 'TRAVEL_WORLD',
  Cyber = 'CYBER',
}

export enum Insurance_InsuranceType {
  Travel = 'TRAVEL',
  Premium = 'PREMIUM',
  Pro = 'PRO',
  Grow = 'GROW',
}

export type Interaction = {
  readonly __typename?: 'Interaction';
  readonly id: Scalars['ID'];
  readonly initiatedBy: Scalars['String'];
  readonly created: Scalars['String'];
  readonly duration: Scalars['Int'];
  readonly type: InteractionTypeEnum;
  readonly messages: ReadonlyArray<Maybe<Message>>;
};

export enum InteractionTypeEnum {
  Sms = 'SMS',
  Phone = 'PHONE',
  Webchat = 'WEBCHAT',
}

export type IntercomOpenConversationCountInput = {
  readonly customerId: Scalars['String'];
};

export type IntercomOpenConversationCountPayload = {
  readonly __typename?: 'IntercomOpenConversationCountPayload';
  /** Intercom ID */
  readonly id: Scalars['ID'];
  /** Count of open conversations */
  readonly openConversationCount: Scalars['Int'];
};

export type IntercomUserLinkInput = {
  readonly customerId: Scalars['String'];
};

export type IntercomUserLinkPayload = {
  readonly __typename?: 'IntercomUserLinkPayload';
  /** Intercom user id */
  readonly id: Scalars['ID'];
  /** Intercom workspace id */
  readonly workSpaceId: Scalars['ID'];
  /** Link to user in Intercom */
  readonly userLink: Scalars['String'];
};

export enum International_Payment_Api_Action {
  Approve = 'APPROVE',
  Reject = 'REJECT',
}

export type International_Payment_Api_Documentation = {
  readonly __typename?: 'international_payment_api_Documentation';
  readonly id: Scalars['ID'];
  readonly fileName: Scalars['String'];
};

export type International_Payment_Api_Payment = {
  readonly __typename?: 'international_payment_api_Payment';
  readonly id: Scalars['ID'];
  readonly userId: Scalars['String'];
  readonly accountId: Scalars['String'];
  readonly Status: International_Payment_Api_PaymentStatus;
  readonly iban?: Maybe<Scalars['String']>;
  readonly swiftBic?: Maybe<Scalars['String']>;
  readonly accountNumber?: Maybe<Scalars['String']>;
  readonly receiverType: International_Payment_Api_ReceiverType;
  readonly receiverName: Scalars['String'];
  readonly receiverBankName: Scalars['String'];
  readonly receiverBankCountry: Scalars['String'];
  readonly receiverBankAddress: Scalars['String'];
  readonly purpose: Scalars['String'];
  readonly purposeOther?: Maybe<Scalars['String']>;
  readonly documentation: ReadonlyArray<
    International_Payment_Api_Documentation
  >;
  readonly amount: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly chargeAmount: Scalars['String'];
  readonly chargeCurrency: Scalars['String'];
  readonly events: ReadonlyArray<International_Payment_Api_PaymentEvent>;
};

export type International_Payment_Api_PaymentEvent = {
  readonly __typename?: 'international_payment_api_PaymentEvent';
  readonly date: Scalars['String'];
  readonly message: Scalars['String'];
};

export type International_Payment_Api_PaymentsInput = {
  readonly userId: Scalars['ID'];
};

export enum International_Payment_Api_PaymentStatus {
  Success = 'SUCCESS',
  Processing = 'PROCESSING',
  AwaitingChallenge = 'AWAITING_CHALLENGE',
  AwaitingManualApproval = 'AWAITING_MANUAL_APPROVAL',
  Failed = 'FAILED',
}

export type International_Payment_Api_ProcessReceiverCountryLimitExceededInput = {
  readonly paymentId: Scalars['ID'];
  readonly reason: Scalars['String'];
  readonly approvers: ReadonlyArray<Scalars['String']>;
  readonly action: International_Payment_Api_Action;
};

export type International_Payment_Api_ProcessSanctionListHitInput = {
  readonly paymentId: Scalars['ID'];
  readonly reason: Scalars['String'];
  readonly approvers: ReadonlyArray<Scalars['String']>;
  readonly action: International_Payment_Api_Action;
};

export enum International_Payment_Api_ReceiverType {
  Private = 'PRIVATE',
  Business = 'BUSINESS',
}

export type Internationalpayment_CommandResponse = {
  readonly __typename?: 'internationalpayment_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Internationalpayment_InitiateOutgoingInternationPayment = {
  readonly PaymentID: Scalars['String'];
  readonly UserID: Scalars['String'];
  readonly Amount?: Maybe<Scalars['internationalpayment_Decimal']>;
  readonly Currency: Scalars['String'];
  readonly SenderAccountID: Scalars['String'];
  readonly SenderTitle: Scalars['String'];
  readonly IBAN: Scalars['String'];
  readonly SWIFT?: Maybe<Internationalpayment_Swift>;
  readonly ReceiverName: Scalars['String'];
};

export type Internationalpayment_Swift = {
  readonly ReceiverAccountNumber: Scalars['String'];
  readonly BIC: Scalars['String'];
};

export enum Invest_BoardingApplicationStatus {
  NotStarted = 'NotStarted',
  Started = 'Started',
  ReadyForNemIdSigning = 'ReadyForNemIdSigning',
  SubmittedForAutomatedProcessing = 'SubmittedForAutomatedProcessing',
  Redo = 'Redo',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Unknown = 'Unknown',
}

export type Invest_ClientInfo = {
  readonly __typename?: 'invest_ClientInfo';
  readonly userId: Scalars['String'];
  readonly applicationStatus: Invest_BoardingApplicationStatus;
};

export type Invest_CommandResponse = {
  readonly __typename?: 'invest_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Invest_InstrumentInfo = {
  readonly __typename?: 'invest_InstrumentInfo';
  readonly id: Scalars['String'];
  readonly createdAt: Scalars['String'];
  readonly updatedAt: Scalars['String'];
  readonly deletedAt?: Maybe<Scalars['String']>;
  readonly exchangeID: Scalars['String'];
  readonly uic: Scalars['String'];
  readonly type: Scalars['String'];
  readonly subType: Scalars['String'];
  readonly name: Scalars['String'];
  readonly tickerCode: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly isTradable: Scalars['Boolean'];
};

export type Invest_McBootstrapClientInfoRequest = {
  readonly clientId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Invest_SaxoExchange = {
  readonly __typename?: 'invest_SaxoExchange';
  readonly allDay: Scalars['Boolean'];
  readonly countryCode: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly exchangeID: Scalars['String'];
  readonly exchangeSessions: ReadonlyArray<Invest_SaxoExchangeSession>;
  readonly mic: Scalars['String'];
  readonly name: Scalars['String'];
  readonly timeZone: Scalars['Int'];
  readonly timeZoneOffset: Scalars['String'];
};

export type Invest_SaxoExchangeSession = {
  readonly __typename?: 'invest_SaxoExchangeSession';
  readonly endTime: Scalars['String'];
  readonly startTime: Scalars['String'];
  readonly state: Scalars['String'];
};

export type Invest_SaxoInfoPrice = {
  readonly __typename?: 'invest_SaxoInfoPrice';
  readonly errorCode: Scalars['String'];
  readonly errorMessage: Scalars['String'];
  readonly lastClose: Scalars['String'];
  readonly lastTraded: Scalars['String'];
  readonly lastUpdated: Scalars['String'];
  readonly netChange: Scalars['String'];
  readonly percentChange: Scalars['String'];
  readonly priceSource: Scalars['String'];
};

export type Invest_SaxoInstrument = {
  readonly __typename?: 'invest_SaxoInstrument';
  readonly description: Scalars['String'];
  readonly currencyCode: Scalars['String'];
  readonly priceToContractFactor: Scalars['String'];
  readonly assetType: Scalars['String'];
  readonly symbol: Scalars['String'];
  readonly uic: Scalars['Int'];
  readonly exchange: Scalars['String'];
  readonly displayHint?: Maybe<Scalars['String']>;
  readonly isTradable: Scalars['Boolean'];
};

export type Invest_UserInfo = {
  readonly __typename?: 'invest_UserInfo';
  readonly userId: Scalars['String'];
  readonly clientId: Scalars['String'];
  readonly applicationStarted: Scalars['String'];
  readonly applicationStatus: Invest_BoardingApplicationStatus;
  readonly accountNumber: Scalars['String'];
};

export type KeyValue = {
  readonly __typename?: 'KeyValue';
  readonly key: Scalars['String'];
  readonly value?: Maybe<Scalars['String']>;
};

export type Kyc_AddBusinessOwnerPayload = {
  readonly __typename?: 'kyc_AddBusinessOwnerPayload';
  readonly error?: Maybe<Scalars['String']>;
  readonly kyc?: Maybe<ReadonlyArray<Kyc_Kyc>>;
};

export type Kyc_AuditInput = {
  readonly reason: Scalars['String'];
};

export type Kyc_BootstrapInput = {
  readonly event: Scalars['String'];
  readonly exchange: Scalars['String'];
  readonly userId?: Maybe<Scalars['ID']>;
  readonly relation?: Maybe<Kyc_Relation>;
};

export type Kyc_BootstrapResponse = {
  readonly __typename?: 'kyc_BootstrapResponse';
  readonly Success: Scalars['Boolean'];
  readonly Message?: Maybe<Scalars['String']>;
};

export type Kyc_BusinessOwner = {
  readonly __typename?: 'kyc_BusinessOwner';
  readonly id: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly birthDate: Scalars['String'];
  readonly citizenship: Kyc_Citizenship;
  readonly taxLiableInCountries: Kyc_TaxLiableInCountries;
  readonly countryOfResidence: Scalars['String'];
};

export type Kyc_BusinessOwnerInput = {
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly birthDate: Scalars['String'];
  readonly citizenship: Kyc_CitizenshipInput;
  readonly taxLiableInCountries: Kyc_TaxLiableInCountriesInput;
  /** This is a Alpha2 code. */
  readonly countryOfResidence: Scalars['String'];
};

export type Kyc_Citizenship = {
  readonly __typename?: 'kyc_Citizenship';
  /** This is a Alpha2 code. Length of the array is max size of 3 */
  readonly countryCodes: ReadonlyArray<Scalars['String']>;
};

export type Kyc_CitizenshipInput = {
  /** This is a Alpha2 code. Length of the array is max size of 3 */
  readonly countryCodes: ReadonlyArray<Scalars['String']>;
};

export type Kyc_File = {
  readonly __typename?: 'kyc_File';
  readonly id: Scalars['String'];
  readonly storageStatus: Scalars['String'];
  readonly expireDate: Scalars['String'];
  readonly screenId: Scalars['String'];
  readonly photoType?: Maybe<Kyc_PhotoType>;
  readonly fileType: Kyc_FileType;
};

export type Kyc_FileInput = {
  readonly id: Scalars['String'];
  readonly expireDate: Scalars['String'];
  readonly photoType: Kyc_PhotoType;
};

export enum Kyc_FileType {
  PhotoId = 'PHOTO_ID',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
  ProofOfWealth = 'PROOF_OF_WEALTH',
}

export type Kyc_Kyc = {
  readonly id: Scalars['String'];
  readonly updatedAt: Scalars['String'];
  readonly createdAt: Scalars['String'];
  readonly userId: Scalars['String'];
  readonly isPep: Scalars['Boolean'];
  readonly kycQuestion: ReadonlyArray<Kyc_KycQuestion>;
  readonly files: ReadonlyArray<Kyc_File>;
};

export type Kyc_KycBusiness = Kyc_Kyc & {
  readonly __typename?: 'kyc_KYCBusiness';
  readonly id: Scalars['String'];
  readonly updatedAt: Scalars['String'];
  readonly createdAt: Scalars['String'];
  readonly userId: Scalars['String'];
  readonly isPep: Scalars['Boolean'];
  readonly kycQuestion: ReadonlyArray<Kyc_KycQuestion>;
  readonly files: ReadonlyArray<Kyc_File>;
  readonly businessOwners: ReadonlyArray<Kyc_BusinessOwner>;
};

export type Kyc_KycDefaultQuestion = Kyc_KycQuestion & {
  readonly __typename?: 'kyc_KYCDefaultQuestion';
  readonly id: Scalars['String'];
  readonly questionId: Scalars['String'];
  readonly question: Scalars['String'];
  readonly answer: Scalars['String'];
  readonly type: Kyc_PrimitiveType;
  readonly subKYCQuestions: ReadonlyArray<Kyc_KycQuestion>;
  readonly screenId?: Maybe<Scalars['String']>;
};

export type Kyc_KycPrivate = Kyc_Kyc & {
  readonly __typename?: 'kyc_KYCPrivate';
  readonly id: Scalars['String'];
  readonly updatedAt: Scalars['String'];
  readonly createdAt: Scalars['String'];
  readonly userId: Scalars['String'];
  readonly isPep: Scalars['Boolean'];
  readonly kycQuestion: ReadonlyArray<Kyc_KycQuestion>;
  readonly files: ReadonlyArray<Kyc_File>;
};

export type Kyc_KycQuestion = {
  readonly id: Scalars['String'];
  readonly questionId: Scalars['String'];
  readonly question: Scalars['String'];
  readonly answer: Scalars['String'];
  readonly type: Kyc_PrimitiveType;
  readonly subKYCQuestions: ReadonlyArray<Kyc_KycQuestion>;
  readonly screenId?: Maybe<Scalars['String']>;
};

export type Kyc_KycTaxQuestion = Kyc_KycQuestion & {
  readonly __typename?: 'kyc_KYCTaxQuestion';
  readonly id: Scalars['String'];
  readonly questionId: Scalars['String'];
  readonly question: Scalars['String'];
  readonly answer: Scalars['String'];
  readonly type: Kyc_PrimitiveType;
  readonly subKYCQuestions: ReadonlyArray<Kyc_KycQuestion>;
  readonly screenId?: Maybe<Scalars['String']>;
  readonly taxInformation: ReadonlyArray<Kyc_TaxInformation>;
};

export enum Kyc_PhotoType {
  Other = 'OTHER',
  Passport = 'PASSPORT',
  DriverLicense = 'DRIVER_LICENSE',
  SocialSecurityCard = 'SOCIAL_SECURITY_CARD',
}

export enum Kyc_PrimitiveType {
  Bool = 'BOOL',
  String = 'STRING',
  Int = 'INT',
  Date = 'DATE',
  Countrycode = 'COUNTRYCODE',
}

export enum Kyc_Relation {
  Dklb = 'DKLB',
  Dklbb = 'DKLBB',
  Selb = 'SELB',
  Nolb = 'NOLB',
}

export type Kyc_Response = {
  readonly __typename?: 'kyc_Response';
  readonly success: Scalars['Boolean'];
  readonly error?: Maybe<Scalars['String']>;
};

export enum Kyc_SupportedCountries {
  Af = 'AF',
  Al = 'AL',
  Dz = 'DZ',
  As = 'AS',
  Ad = 'AD',
  Ao = 'AO',
  Ai = 'AI',
  Aq = 'AQ',
  Ag = 'AG',
  Ar = 'AR',
  Am = 'AM',
  Aw = 'AW',
  Au = 'AU',
  At = 'AT',
  Az = 'AZ',
  Bs = 'BS',
  Bh = 'BH',
  Bd = 'BD',
  Bb = 'BB',
  By = 'BY',
  Be = 'BE',
  Bz = 'BZ',
  Bj = 'BJ',
  Bm = 'BM',
  Bt = 'BT',
  Bo = 'BO',
  Bq = 'BQ',
  Ba = 'BA',
  Bw = 'BW',
  Bv = 'BV',
  Br = 'BR',
  Io = 'IO',
  Bn = 'BN',
  Bg = 'BG',
  Bf = 'BF',
  Bi = 'BI',
  Cv = 'CV',
  Kh = 'KH',
  Cm = 'CM',
  Ca = 'CA',
  Ky = 'KY',
  Cf = 'CF',
  Td = 'TD',
  Cl = 'CL',
  Cn = 'CN',
  Cx = 'CX',
  Cc = 'CC',
  Co = 'CO',
  Km = 'KM',
  Cd = 'CD',
  Ck = 'CK',
  Cr = 'CR',
  Hr = 'HR',
  Cu = 'CU',
  Cw = 'CW',
  Cy = 'CY',
  Cz = 'CZ',
  Ci = 'CI',
  Dk = 'DK',
  Dj = 'DJ',
  Dm = 'DM',
  Do = 'DO',
  Ec = 'EC',
  Eg = 'EG',
  Sv = 'SV',
  Gq = 'GQ',
  Er = 'ER',
  Ee = 'EE',
  Sz = 'SZ',
  Et = 'ET',
  Fk = 'FK',
  Fo = 'FO',
  Fj = 'FJ',
  Fi = 'FI',
  Fr = 'FR',
  Gf = 'GF',
  Pf = 'PF',
  Ga = 'GA',
  Gm = 'GM',
  Ge = 'GE',
  De = 'DE',
  Gh = 'GH',
  Gi = 'GI',
  Gr = 'GR',
  Gl = 'GL',
  Gd = 'GD',
  Gp = 'GP',
  Gu = 'GU',
  Gt = 'GT',
  Gg = 'GG',
  Gn = 'GN',
  Gw = 'GW',
  Gy = 'GY',
  Ht = 'HT',
  Hm = 'HM',
  Va = 'VA',
  Hn = 'HN',
  Hk = 'HK',
  Hu = 'HU',
  Is = 'IS',
  In = 'IN',
  Id = 'ID',
  Ir = 'IR',
  Iq = 'IQ',
  Ie = 'IE',
  Im = 'IM',
  Il = 'IL',
  It = 'IT',
  Jm = 'JM',
  Jp = 'JP',
  Je = 'JE',
  Jo = 'JO',
  Kz = 'KZ',
  Ke = 'KE',
  Ki = 'KI',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Kg = 'KG',
  La = 'LA',
  Lv = 'LV',
  Lb = 'LB',
  Ls = 'LS',
  Lr = 'LR',
  Ly = 'LY',
  Li = 'LI',
  Lt = 'LT',
  Lu = 'LU',
  Mo = 'MO',
  Mg = 'MG',
  Mw = 'MW',
  My = 'MY',
  Mv = 'MV',
  Ml = 'ML',
  Mt = 'MT',
  Mh = 'MH',
  Mq = 'MQ',
  Mr = 'MR',
  Mu = 'MU',
  Yt = 'YT',
  Mx = 'MX',
  Fm = 'FM',
  Md = 'MD',
  Mc = 'MC',
  Mn = 'MN',
  Me = 'ME',
  Ms = 'MS',
  Ma = 'MA',
  Mz = 'MZ',
  Mm = 'MM',
  Na = 'NA',
  Nr = 'NR',
  Np = 'NP',
  Nl = 'NL',
  Nc = 'NC',
  Nz = 'NZ',
  Ni = 'NI',
  Ne = 'NE',
  Ng = 'NG',
  Nu = 'NU',
  Nf = 'NF',
  Mp = 'MP',
  No = 'NO',
  Om = 'OM',
  Pk = 'PK',
  Pw = 'PW',
  Ps = 'PS',
  Pa = 'PA',
  Pg = 'PG',
  Py = 'PY',
  Pe = 'PE',
  Ph = 'PH',
  Pn = 'PN',
  Pl = 'PL',
  Pt = 'PT',
  Pr = 'PR',
  Qa = 'QA',
  Mk = 'MK',
  Ro = 'RO',
  Ru = 'RU',
  Rw = 'RW',
  Re = 'RE',
  Bl = 'BL',
  Sh = 'SH',
  Kn = 'KN',
  Lc = 'LC',
  Mf = 'MF',
  Pm = 'PM',
  Vc = 'VC',
  Ws = 'WS',
  Sm = 'SM',
  St = 'ST',
  Sa = 'SA',
  Sn = 'SN',
  Rs = 'RS',
  Sc = 'SC',
  Sl = 'SL',
  Sg = 'SG',
  Sx = 'SX',
  Sk = 'SK',
  Si = 'SI',
  Sb = 'SB',
  So = 'SO',
  Za = 'ZA',
  Gs = 'GS',
  Ss = 'SS',
  Es = 'ES',
  Lk = 'LK',
  Sd = 'SD',
  Sr = 'SR',
  Sj = 'SJ',
  Se = 'SE',
  Ch = 'CH',
  Sy = 'SY',
  Tw = 'TW',
  Tj = 'TJ',
  Tz = 'TZ',
  Th = 'TH',
  Tl = 'TL',
  Tg = 'TG',
  Tk = 'TK',
  To = 'TO',
  Tt = 'TT',
  Tn = 'TN',
  Tr = 'TR',
  Tm = 'TM',
  Tc = 'TC',
  Tv = 'TV',
  Ug = 'UG',
  Ua = 'UA',
  Ae = 'AE',
  Gb = 'GB',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Vu = 'VU',
  Ve = 'VE',
  Vn = 'VN',
  Vg = 'VG',
  Vi = 'VI',
  Wf = 'WF',
  Eh = 'EH',
  Ye = 'YE',
  Zm = 'ZM',
  Zw = 'ZW',
  Ax = 'AX',
  Xk = 'XK',
  Tf = 'TF',
  Cg = 'CG',
}

export type Kyc_TaxInformation = {
  readonly __typename?: 'kyc_TaxInformation';
  /** This is a Alpha2 code. */
  readonly countryCode: Scalars['String'];
  /**
   * Tax number or Tin number is usually something like SSN. But it is country
   * specific. We do no input validation of this field
   */
  readonly taxNumber: Scalars['String'];
};

export type Kyc_TaxInformationInput = {
  /** This is a Alpha2 code. */
  readonly countryCode: Scalars['String'];
  /**
   * Tax number or Tin number is usually something like SSN. But it is country
   * specific. We do no input validation of this field
   */
  readonly taxNumber: Scalars['String'];
};

export type Kyc_TaxLiableInCountries = {
  readonly __typename?: 'kyc_TaxLiableInCountries';
  readonly taxInformation: ReadonlyArray<Kyc_TaxInformation>;
};

export type Kyc_TaxLiableInCountriesInput = {
  readonly taxInformation: ReadonlyArray<Kyc_TaxInformationInput>;
};

export type Kyc_UserInput = {
  readonly userId: Scalars['ID'];
  readonly relation: Kyc_Relation;
};

export enum LastSelectedContextId {
  Dkka = 'dkka',
  Dknb = 'dknb',
  Dknbb = 'dknbb',
  Dklb = 'dklb',
  Sepp = 'sepp',
  Nopp = 'nopp',
  Selb = 'selb',
  Nolb = 'nolb',
}

export type MerchantInfo = {
  readonly __typename?: 'MerchantInfo';
  readonly id?: Maybe<Scalars['ID']>;
  readonly merchantId?: Maybe<Scalars['ID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly zipCode?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly countryAlpha2?: Maybe<Scalars['String']>;
  readonly countryAlpha3?: Maybe<Scalars['String']>;
  readonly countryNumeric?: Maybe<Scalars['Int']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly website?: Maybe<Scalars['String']>;
  readonly mcc?: Maybe<Scalars['String']>;
  readonly mccDescription?: Maybe<Scalars['String']>;
};

export type Message = {
  readonly __typename?: 'Message';
  readonly id: Scalars['ID'];
  readonly from: Scalars['String'];
  readonly content: Scalars['String'];
  readonly isFromMe: Scalars['Boolean'];
  readonly created: Scalars['String'];
};

export type MessageTemplate = {
  readonly __typename?: 'MessageTemplate';
  readonly id: Scalars['ID'];
  readonly category: MessageTemplateCategory;
  readonly alias: Scalars['String'];
  readonly content: Scalars['String'];
  readonly disabled: Scalars['Boolean'];
};

export enum MessageTemplateCategory {
  SignupRequest = 'signup_request',
  BankSignupRequest = 'bank_signup_request',
  Credit = 'credit',
  CreditCancellation = 'credit_cancellation',
  CustomerSuspension = 'customer_suspension',
  CloseAccount = 'close_account',
  RevokeTokens = 'revoke_tokens',
  Accounting = 'accounting',
  Premium = 'premium',
  CardReservation = 'card_reservation',
  Pro = 'pro',
  FileRequests = 'file_requests',
  Product = 'product',
}

export type Migration_Biz_BsState = {
  readonly __typename?: 'migration_biz_BsState';
  readonly numCvrsWithBsMoved: Scalars['Int'];
  readonly cvrsReadyForBsMove: ReadonlyArray<Scalars['String']>;
};

export type Migration_Biz_EngineConfig = {
  readonly __typename?: 'migration_biz_EngineConfig';
  readonly dailyOnboardingLimit: Scalars['Int'];
  readonly executorMainLoopIntervalMs: Scalars['Int'];
  readonly executorAdvanceLoopIntervalMs: Scalars['Int'];
};

export type Migration_Biz_EngineConfigInput = {
  readonly dailyOnboardingLimit?: Maybe<Scalars['Int']>;
  readonly executorMainLoopIntervalMs?: Maybe<Scalars['Int']>;
  readonly executorAdvanceLoopIntervalMs?: Maybe<Scalars['Int']>;
};

export type Migration_Biz_EngineState = {
  readonly __typename?: 'migration_biz_EngineState';
  readonly executorState: Migration_Biz_ExecutorStateType;
  readonly numActiveEventHandlers: Scalars['Int'];
  readonly numActiveStateTransitions: Scalars['Int'];
  readonly numActiveStateHandlers: Scalars['Int'];
  readonly numOnboardingsToday: Scalars['Int'];
  readonly numOnboardingsLastMinute: Scalars['Int'];
  readonly isInBecServiceWindow: Scalars['Boolean'];
  readonly config: Migration_Biz_EngineConfig;
};

export type Migration_Biz_ExecutorOperationResult = {
  readonly __typename?: 'migration_biz_ExecutorOperationResult';
  readonly success: Scalars['Boolean'];
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type Migration_Biz_ExecutorStateTransition = {
  readonly __typename?: 'migration_biz_ExecutorStateTransition';
  readonly fromState: Migration_Biz_ExecutorStateType;
  readonly toState: Migration_Biz_ExecutorStateType;
  readonly time: Scalars['String'];
  readonly actor: Scalars['String'];
  readonly message?: Maybe<Scalars['String']>;
};

export enum Migration_Biz_ExecutorStateType {
  Stopped = 'stopped',
  Running = 'running',
}

export type Migration_Biz_FailedBatchMigrationOperationResult = {
  readonly __typename?: 'migration_biz_FailedBatchMigrationOperationResult';
  readonly userId: Scalars['ID'];
  readonly errorMessage: Scalars['String'];
};

export type Migration_Biz_FilteredMigrations = {
  readonly __typename?: 'migration_biz_FilteredMigrations';
  readonly slice: ReadonlyArray<Migration_Biz_Migration>;
  readonly allBatches: ReadonlyArray<Scalars['String']>;
  readonly allStates: ReadonlyArray<Scalars['String']>;
  readonly allPrevStates: ReadonlyArray<Scalars['String']>;
};

export type Migration_Biz_Migration = {
  readonly __typename?: 'migration_biz_Migration';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['String'];
  readonly updatedAt: Scalars['String'];
  readonly oldUserId: Scalars['String'];
  readonly newUserId?: Maybe<Scalars['String']>;
  readonly state: Migration_Biz_MigrationState;
  readonly creator: Scalars['String'];
  readonly batchName: Scalars['String'];
  readonly isExecutorDisabled: Scalars['Boolean'];
  readonly bsStatus: Migration_Biz_MigrationBsStatus;
  readonly isForced: Scalars['Boolean'];
};

export enum Migration_Biz_MigrationBsStatus {
  NotReadyForMove = 'not_ready_for_move',
  ReadyForMove = 'ready_for_move',
  Moved = 'moved',
}

export type Migration_Biz_MigrationHistoryCurrentState = {
  readonly __typename?: 'migration_biz_MigrationHistoryCurrentState';
  readonly type: Migration_Biz_MigrationStateType;
  readonly enteredAt: Scalars['String'];
  readonly isAdvanceable: Scalars['Boolean'];
  readonly isError: Scalars['Boolean'];
  readonly isSkipped: Scalars['Boolean'];
};

export type Migration_Biz_MigrationOperationResult = {
  readonly __typename?: 'migration_biz_MigrationOperationResult';
  readonly migrationId: Scalars['ID'];
  readonly success: Scalars['Boolean'];
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type Migration_Biz_MigrationsFilter = {
  readonly freeText?: Maybe<Scalars['String']>;
  readonly batchName?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly prevState?: Maybe<Scalars['String']>;
  readonly limit?: Maybe<Scalars['Int']>;
  readonly sortBy?: Maybe<Migration_Biz_MigrationSortType>;
  readonly sortOrder?: Maybe<Migration_Biz_SortOrder>;
};

export enum Migration_Biz_MigrationSortType {
  Created = 'created',
  Updated = 'updated',
}

export type Migration_Biz_MigrationState = {
  readonly __typename?: 'migration_biz_MigrationState';
  readonly type: Migration_Biz_MigrationStateType;
  readonly isAdvanceable: Scalars['Boolean'];
  readonly enteredAt: Scalars['String'];
  readonly exitedAt: Scalars['String'];
};

export type Migration_Biz_MigrationStateHistory = {
  readonly __typename?: 'migration_biz_MigrationStateHistory';
  readonly previousStates: ReadonlyArray<Migration_Biz_MigrationState>;
  readonly currentState: Migration_Biz_MigrationHistoryCurrentState;
  readonly futureStates: ReadonlyArray<Migration_Biz_MigrationStateType>;
};

export type Migration_Biz_MigrationStateStats = {
  readonly __typename?: 'migration_biz_MigrationStateStats';
  readonly type: Migration_Biz_MigrationStateType;
  readonly numMigrationsHere: Scalars['Int'];
  readonly numMigrationsHereInMemory: Scalars['Int'];
  readonly numMigrationsErroredFromHere: Scalars['Int'];
  readonly config: Migration_Biz_StateConfig;
};

export type Migration_Biz_MigrationStateTransition = {
  readonly __typename?: 'migration_biz_MigrationStateTransition';
  readonly id: Scalars['ID'];
  readonly migrationId: Scalars['ID'];
  readonly fromState: Migration_Biz_MigrationStateType;
  readonly toState: Migration_Biz_MigrationStateType;
  readonly time: Scalars['String'];
  readonly actor: Scalars['String'];
  readonly isError: Scalars['Boolean'];
  readonly message?: Maybe<Scalars['String']>;
};

export enum Migration_Biz_MigrationStateType {
  NotStarted = 'not_started',
  Started = 'started',
  Validate = 'validate',
  ValidateCompleted = 'validate_completed',
  BecPreSync = 'bec_pre_sync',
  BecPreSyncCompleted = 'bec_pre_sync_completed',
  Onboarding = 'onboarding',
  OnboardingBecQueued = 'onboarding_bec_queued',
  OnboardingCompleted = 'onboarding_completed',
  NotifyUserCardOrder = 'notify_user_card_order',
  WaitUserCardOrder = 'wait_user_card_order',
  UserCardOrderCompleted = 'user_card_order_completed',
  NotifyUserCardVerification = 'notify_user_card_verification',
  WaitUserCardVerification = 'wait_user_card_verification',
  UserCardVerificationCompleted = 'user_card_verification_completed',
  UnloadFunds = 'unload_funds',
  UnloadFundsCompleted = 'unload_funds_completed',
  MoveMoney = 'move_money',
  MoveMoneyCompleted = 'move_money_completed',
  RunMigrators = 'run_migrators',
  RunMigratorsCompleted = 'run_migrators_completed',
  SetFeatures = 'set_features',
  Completed = 'completed',
  Error = 'error',
  Handled = 'handled',
  Skipped = 'skipped',
}

export type Migration_Biz_MigrationStats = {
  readonly __typename?: 'migration_biz_MigrationStats';
  readonly numTotal: Scalars['Int'];
  readonly numNotStarted: Scalars['Int'];
  readonly numCompletedWithoutErrors: Scalars['Int'];
  readonly numCompletedWithErrors: Scalars['Int'];
  readonly numSkipped: Scalars['Int'];
  readonly numHandled: Scalars['Int'];
};

export type Migration_Biz_MigratorResult = {
  readonly __typename?: 'migration_biz_MigratorResult';
  readonly id: Scalars['ID'];
  readonly migrator: Scalars['String'];
  readonly status: Migration_Biz_MigratorStatusType;
  readonly message?: Maybe<Scalars['String']>;
  readonly startedAt?: Maybe<Scalars['String']>;
  readonly endedAt?: Maybe<Scalars['String']>;
};

export type Migration_Biz_MigratorStateStats = {
  readonly __typename?: 'migration_biz_MigratorStateStats';
  readonly name: Scalars['String'];
  readonly numNotStarted: Scalars['Int'];
  readonly numRunning: Scalars['Int'];
  readonly numSucceeded: Scalars['Int'];
  readonly numFailed: Scalars['Int'];
  readonly numTimedOut: Scalars['Int'];
  readonly numSkipped: Scalars['Int'];
};

export enum Migration_Biz_MigratorStatusType {
  NotStarted = 'not_started',
  Running = 'running',
  Success = 'success',
  Fail = 'fail',
  Timeout = 'timeout',
  Skipped = 'skipped',
}

export enum Migration_Biz_SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Migration_Biz_StateConfig = {
  readonly __typename?: 'migration_biz_StateConfig';
  readonly id: Scalars['ID'];
  readonly concurrencyLimit?: Maybe<Scalars['Int']>;
  readonly priority?: Maybe<Scalars['Int']>;
  readonly minDelayMs?: Maybe<Scalars['Int']>;
};

export type Migration_Biz_StateConfigInput = {
  readonly concurrencyLimit?: Maybe<Scalars['Int']>;
  readonly priority?: Maybe<Scalars['Int']>;
  readonly minDelayMs?: Maybe<Scalars['Int']>;
};

export type Migration_BsState = {
  readonly __typename?: 'migration_BsState';
  readonly numSsnsWithBsMoved: Scalars['Int'];
  readonly ssnsReadyForBsMove: ReadonlyArray<Scalars['String']>;
};

export type Migration_EngineConfig = {
  readonly __typename?: 'migration_EngineConfig';
  readonly dailyOnboardingLimit: Scalars['Int'];
  readonly executorMainLoopIntervalMs: Scalars['Int'];
  readonly executorAdvanceLoopIntervalMs: Scalars['Int'];
};

export type Migration_EngineConfigInput = {
  readonly dailyOnboardingLimit?: Maybe<Scalars['Int']>;
  readonly executorMainLoopIntervalMs?: Maybe<Scalars['Int']>;
  readonly executorAdvanceLoopIntervalMs?: Maybe<Scalars['Int']>;
};

export type Migration_EngineState = {
  readonly __typename?: 'migration_EngineState';
  readonly executorState: Migration_ExecutorStateType;
  readonly numActiveEventHandlers: Scalars['Int'];
  readonly numActiveStateTransitions: Scalars['Int'];
  readonly numActiveStateHandlers: Scalars['Int'];
  readonly numOnboardingsToday: Scalars['Int'];
  readonly numOnboardingsLastMinute: Scalars['Int'];
  readonly isInBecServiceWindow: Scalars['Boolean'];
  readonly config: Migration_EngineConfig;
};

export type Migration_ExecutorOperationResult = {
  readonly __typename?: 'migration_ExecutorOperationResult';
  readonly success: Scalars['Boolean'];
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type Migration_ExecutorStateTransition = {
  readonly __typename?: 'migration_ExecutorStateTransition';
  readonly fromState: Migration_ExecutorStateType;
  readonly toState: Migration_ExecutorStateType;
  readonly time: Scalars['String'];
  readonly actor: Scalars['String'];
  readonly message?: Maybe<Scalars['String']>;
};

export enum Migration_ExecutorStateType {
  Stopped = 'stopped',
  Running = 'running',
}

export type Migration_FailedBatchMigrationOperationResult = {
  readonly __typename?: 'migration_FailedBatchMigrationOperationResult';
  readonly userId: Scalars['ID'];
  readonly errorMessage: Scalars['String'];
};

export type Migration_FilteredMigrations = {
  readonly __typename?: 'migration_FilteredMigrations';
  readonly slice: ReadonlyArray<Migration_Migration>;
  readonly allBatches: ReadonlyArray<Scalars['String']>;
  readonly allStates: ReadonlyArray<Scalars['String']>;
  readonly allPrevStates: ReadonlyArray<Scalars['String']>;
};

export type Migration_Migration = {
  readonly __typename?: 'migration_Migration';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['String'];
  readonly updatedAt: Scalars['String'];
  readonly oldUserId: Scalars['String'];
  readonly newUserId?: Maybe<Scalars['String']>;
  readonly state: Migration_MigrationState;
  readonly creator: Scalars['String'];
  readonly batchName: Scalars['String'];
  readonly isExecutorDisabled: Scalars['Boolean'];
  readonly bsStatus: Migration_MigrationBsStatus;
  readonly isForced: Scalars['Boolean'];
};

export enum Migration_MigrationBsStatus {
  NotReadyForMove = 'not_ready_for_move',
  ReadyForMove = 'ready_for_move',
  Moved = 'moved',
}

export type Migration_MigrationHistoryCurrentState = {
  readonly __typename?: 'migration_MigrationHistoryCurrentState';
  readonly type: Migration_MigrationStateType;
  readonly enteredAt: Scalars['String'];
  readonly isAdvanceable: Scalars['Boolean'];
  readonly isError: Scalars['Boolean'];
  readonly isSkipped: Scalars['Boolean'];
};

export type Migration_MigrationOperationResult = {
  readonly __typename?: 'migration_MigrationOperationResult';
  readonly migrationId: Scalars['ID'];
  readonly success: Scalars['Boolean'];
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type Migration_MigrationsFilter = {
  readonly freeText?: Maybe<Scalars['String']>;
  readonly batchName?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly prevState?: Maybe<Scalars['String']>;
  readonly limit?: Maybe<Scalars['Int']>;
  readonly sortBy?: Maybe<Migration_MigrationSortType>;
  readonly sortOrder?: Maybe<Migration_SortOrder>;
};

export enum Migration_MigrationSortType {
  Created = 'created',
  Updated = 'updated',
}

export type Migration_MigrationState = {
  readonly __typename?: 'migration_MigrationState';
  readonly type: Migration_MigrationStateType;
  readonly isAdvanceable: Scalars['Boolean'];
  readonly enteredAt: Scalars['String'];
  readonly exitedAt: Scalars['String'];
};

export type Migration_MigrationStateHistory = {
  readonly __typename?: 'migration_MigrationStateHistory';
  readonly previousStates: ReadonlyArray<Migration_MigrationState>;
  readonly currentState: Migration_MigrationHistoryCurrentState;
  readonly futureStates: ReadonlyArray<Migration_MigrationStateType>;
};

export type Migration_MigrationStateStats = {
  readonly __typename?: 'migration_MigrationStateStats';
  readonly type: Migration_MigrationStateType;
  readonly numMigrationsHere: Scalars['Int'];
  readonly numMigrationsHereInMemory: Scalars['Int'];
  readonly numMigrationsErroredFromHere: Scalars['Int'];
  readonly config: Migration_StateConfig;
};

export type Migration_MigrationStateTransition = {
  readonly __typename?: 'migration_MigrationStateTransition';
  readonly id: Scalars['ID'];
  readonly migrationId: Scalars['ID'];
  readonly fromState: Migration_MigrationStateType;
  readonly toState: Migration_MigrationStateType;
  readonly time: Scalars['String'];
  readonly actor: Scalars['String'];
  readonly isError: Scalars['Boolean'];
  readonly message?: Maybe<Scalars['String']>;
};

export enum Migration_MigrationStateType {
  NotStarted = 'not_started',
  Started = 'started',
  Validate = 'validate',
  ValidateCompleted = 'validate_completed',
  BecPreSync = 'bec_pre_sync',
  BecPreSyncCompleted = 'bec_pre_sync_completed',
  Onboarding = 'onboarding',
  OnboardingBecQueued = 'onboarding_bec_queued',
  OnboardingCompleted = 'onboarding_completed',
  NotifyUserCardOrder = 'notify_user_card_order',
  WaitUserCardOrder = 'wait_user_card_order',
  UserCardOrderCompleted = 'user_card_order_completed',
  NotifyUserCardVerification = 'notify_user_card_verification',
  WaitUserCardVerification = 'wait_user_card_verification',
  UserCardVerificationCompleted = 'user_card_verification_completed',
  MoveMoney = 'move_money',
  MoveMoneyCompleted = 'move_money_completed',
  RunMigrators = 'run_migrators',
  RunMigratorsCompleted = 'run_migrators_completed',
  SetFeatures = 'set_features',
  Completed = 'completed',
  Error = 'error',
  Handled = 'handled',
  Skipped = 'skipped',
}

export type Migration_MigrationStats = {
  readonly __typename?: 'migration_MigrationStats';
  readonly numTotal: Scalars['Int'];
  readonly numNotStarted: Scalars['Int'];
  readonly numCompletedWithoutErrors: Scalars['Int'];
  readonly numCompletedWithErrors: Scalars['Int'];
  readonly numSkipped: Scalars['Int'];
  readonly numHandled: Scalars['Int'];
};

export type Migration_MigratorResult = {
  readonly __typename?: 'migration_MigratorResult';
  readonly id: Scalars['ID'];
  readonly migrator: Scalars['String'];
  readonly status: Migration_MigratorStatusType;
  readonly message?: Maybe<Scalars['String']>;
  readonly startedAt?: Maybe<Scalars['String']>;
  readonly endedAt?: Maybe<Scalars['String']>;
};

export type Migration_MigratorStateStats = {
  readonly __typename?: 'migration_MigratorStateStats';
  readonly name: Scalars['String'];
  readonly numNotStarted: Scalars['Int'];
  readonly numRunning: Scalars['Int'];
  readonly numSucceeded: Scalars['Int'];
  readonly numFailed: Scalars['Int'];
  readonly numTimedOut: Scalars['Int'];
  readonly numSkipped: Scalars['Int'];
};

export enum Migration_MigratorStatusType {
  NotStarted = 'not_started',
  Running = 'running',
  Success = 'success',
  Fail = 'fail',
  Timeout = 'timeout',
  Skipped = 'skipped',
}

export enum Migration_SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Migration_StateConfig = {
  readonly __typename?: 'migration_StateConfig';
  readonly id: Scalars['ID'];
  readonly concurrencyLimit?: Maybe<Scalars['Int']>;
  readonly priority?: Maybe<Scalars['Int']>;
  readonly minDelayMs?: Maybe<Scalars['Int']>;
};

export type Migration_StateConfigInput = {
  readonly concurrencyLimit?: Maybe<Scalars['Int']>;
  readonly priority?: Maybe<Scalars['Int']>;
  readonly minDelayMs?: Maybe<Scalars['Int']>;
};

export type MigrationFuturePaymentsFailedForPayment = {
  readonly __typename?: 'MigrationFuturePaymentsFailedForPayment';
  readonly oldUserId: Scalars['ID'];
  readonly oldTransactionID: Scalars['String'];
  readonly oldAccountNumber: Scalars['String'];
  readonly newUserId: Scalars['ID'];
  readonly paymentDate: Scalars['String'];
  readonly message: Scalars['String'];
  readonly longDescription?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly receiver: Scalars['String'];
  readonly amount: Amount;
  readonly reason: Scalars['String'];
};

export type MigrationMoneyAccountOverdrawn = {
  readonly __typename?: 'MigrationMoneyAccountOverdrawn';
  readonly oldUserId: Scalars['ID'];
  readonly newUserId: Scalars['ID'];
};

export type Money = {
  readonly __typename?: 'Money';
  readonly amount: Scalars['Float'];
  readonly currency: Scalars['String'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly accountmanagement_approveMandate: Scalars['Boolean'];
  readonly accountmanagement_bootstrapAccountEvents: Accountmanagement_CommandResponse;
  readonly accountmanagement_bootstrapAllDocuments: Accountmanagement_CommandResponse;
  readonly accountsview_generateAccountOrderAgreement?: Maybe<
    Accountsview_AccountOrderAgreement
  >;
  readonly accountsview_acceptAccountOrderAgreement: Accountsview_Account;
  readonly accountsview_debugEmulateCreateAccountResponse: Scalars['Boolean'];
  readonly accountsview_debugSetNemkontoStatusActiveForAccount: Scalars['Boolean'];
  readonly accountsview_debugSetNemkontoStatusDeactivatedForAccount: Scalars['Boolean'];
  readonly accountsview_debugEnsureSubscriptions: Scalars['Boolean'];
  readonly accountsview_cancelAccountCloseRequest: Accountsview_Account;
  readonly accountsview_generateStatement: Scalars['Boolean'];
  readonly accountsview_generateStatementForAllAccounts: Scalars['Boolean'];
  readonly accountsview_startWaitingStatements: Scalars['Boolean'];
  readonly accountsview_bootstrapAccounts: Scalars['Boolean'];
  readonly accountsview_bootstrapAccountsFromRails: Scalars['Boolean'];
  readonly accountsview_orderCloseAccount: Accountsview_Account;
  readonly accountsview_debugFixDuplicatePrimaryAccounts: Scalars['Boolean'];
  readonly accountsview_debugDeliverAccountStatement: Scalars['Boolean'];
  readonly accountsview_migrationAddMetadataToDocuments: Scalars['Boolean'];
  readonly appsync_debugAppSync: Scalars['Boolean'];
  readonly astralis_MCDeleteProduct: Astralis_CommandResponse;
  readonly astralis_MCOrderProduct: Astralis_CommandResponse;
  readonly bankaccount_syncUsers: Bankaccount_CommandResponse;
  readonly bankaccount_initiateAccountClosure: Bankaccount_CommandResponse;
  readonly bankaccount_mcCancelCardReservation: Bankaccount_CommandResponse;
  readonly bankaccount_mcCancelCardReservations: Bankaccount_ListCommandResponse;
  readonly bankaccount_mcCancelDepositReservation: Bankaccount_CommandResponse;
  readonly bankaccount_mcCreateCorrectionTransaction: Bankaccount_CommandResponse;
  readonly bankaccount_mcCreateTransactionReversalCorrection: Bankaccount_CommandResponse;
  readonly bankaccount_mcPokeUserToSystemAccountingTransfer: Bankaccount_CommandResponse;
  readonly bankaccount_mcPokeSystemToUserAccountingTransfer: Bankaccount_CommandResponse;
  readonly bankaccount_mcPokeSystemToSystemAccountingTransfer: Bankaccount_CommandResponse;
  readonly bankaccount_mcPokeOnboarding: Bankaccount_CommandResponse;
  readonly bankaccount_mcManuallyFailOnboarding: Bankaccount_CommandResponse;
  readonly bankaccount_mcPokeAccountCreation: Bankaccount_ListCommandResponse;
  readonly bankaccount_mcPokeCreditTransfer: Bankaccount_CommandResponse;
  readonly bankaccount_mcRepublishExternalEvent: Bankaccount_ListCommandResponse;
  readonly bankaccount_mcBatchSync: Bankaccount_CommandResponse;
  readonly becadapter_syncAccountTransactions: Becadapter_CommandResponse;
  readonly becadapter_syncAccountFutures: Becadapter_CommandResponse;
  readonly becadapter_createAccountInBEC: Becadapter_CommandResponse;
  readonly becadapter_MCManualSucceedAccountingTransfer: Becadapter_CommandResponse;
  readonly becadapter_MCManualRetryAccountingTransfer: Becadapter_ListCommandResponse;
  readonly becadapter_MCManualFailAccountingTransfer: Becadapter_ListCommandResponse;
  readonly becadapter_MCPokeAccountingTransfer: Becadapter_ListCommandResponse;
  readonly becadapter_MCReverseUserToSystemAccountingTransfer?: Maybe<
    Becadapter_CommandResponse
  >;
  readonly becadapter_MCManualBootstrapCustomer: Becadapter_McManualBoostrapCustomerResponse;
  readonly becadapter_MCManualRetryCustomerOnboarding: Becadapter_ListCommandResponse;
  readonly becadapter_MCManualFailCustomerOnboarding: Becadapter_ListCommandResponse;
  readonly becadapter_MCPokeCustomerOnboarding: Becadapter_ListCommandResponse;
  readonly becadapter_MCRepublishBECPostingEntryCreated: Becadapter_CommandResponse;
  readonly becadapter_MCCreateNewBECHomeBanking: Becadapter_CommandResponse;
  readonly becadapter_MCUpdateBECHomeBanking: Becadapter_CommandResponse;
  readonly becadapter_MCManualRetryAccountCreation: Becadapter_ListCommandResponse;
  readonly becadapter_MCManualSkipBECAccountCreation: Becadapter_CommandResponse;
  readonly becadapter_MCManualFailAccountCreation: Becadapter_CommandResponse;
  readonly becadapter_MCPokeAccountCreation: Becadapter_CommandResponse;
  readonly becadapter_MCManualResetAccountCreationStateToInitiated: Becadapter_CommandResponse;
  readonly becadapter_MCManualFailCreditTransfer: Becadapter_CommandResponse;
  readonly becadapter_MCManualSucceedCreditTransfer: Becadapter_CommandResponse;
  readonly becadapter_MCCreateCreditTransferCorrectionPosting: Becadapter_CommandResponse;
  readonly becadapter_MCPokeCreditTransfer: Becadapter_ListCommandResponse;
  readonly becadapter_MCManualFailPaymentSlip: Becadapter_CommandResponse;
  readonly becadapter_MCBootstrapSync: Becadapter_CommandResponse;
  readonly becadapter_MCSetAccountBankZoneIDs: Becadapter_ListCommandResponse;
  readonly becadapter_MCManualRetryLoanConfigs: Becadapter_ListCommandResponse;
  readonly becadapter_MCManualFailLoanConfigs: Becadapter_McManualFailLoanConfigsResponse;
  readonly becadapter_MCRepublishExternalEvent: Becadapter_ListCommandResponse;
  readonly brand_createBrand: Brand_BrandCreated;
  readonly brand_updateBrand: Brand_Brand;
  readonly brand_updateLogo: Scalars['String'];
  readonly brand_connectUnbranded: ReadonlyArray<Brand_Title>;
  readonly brand_bootstrapBrands: Scalars['Boolean'];
  readonly brand_bootstrapBrandTitle: Scalars['Boolean'];
  /** This will send the boardingapporved event to braze for all provided userids. */
  readonly braze_sync_resendApplicationApproved: Braze_Sync_Response;
  readonly cardmanagement_orderCardPhysical: Cardmanagement_Card;
  readonly cardmanagement_orderCardVirtual: Cardmanagement_Card;
  readonly cardmanagement_debugOrderCardPhysicalWithPIN: Cardmanagement_Card;
  readonly cardmanagement_replacePhysicalCard: Cardmanagement_Card;
  readonly cardmanagement_closeCard: Cardmanagement_Card;
  readonly cardmanagement_forceCloseCard: Cardmanagement_Card;
  readonly cardmanagement_pokeCloseCardSaga: Cardmanagement_CommandResponse;
  readonly cardmanagement_changeCardStatus: Cardmanagement_Card;
  readonly cardmanagement_changeUserClassification?: Maybe<Scalars['Boolean']>;
  readonly cardmanagement_changeUserRelation: Scalars['Boolean'];
  readonly cardmanagement_blockUser: Scalars['Boolean'];
  readonly cardmanagement_unblockUser: Scalars['Boolean'];
  readonly cardmanagement_syncUsersAtNETS: Scalars['Boolean'];
  readonly cardmanagement_bootstrapCardInLCM: Scalars['Boolean'];
  readonly cardmanagement_updateCompanyInfo: Cardmanagement_Company;
  readonly cardmanagement_addUserToCompany: Scalars['Boolean'];
  readonly cardmanagement_removeUserFromCompany: Scalars['Boolean'];
  readonly cardmanagement_setCompanyClassifier: Cardmanagement_Company;
  readonly cardmanagement_debugRehydrateCardAccounts: Scalars['Boolean'];
  readonly cardmanagement_debugRehydrateCardsByAccount: Scalars['Boolean'];
  readonly cardmanagement_debugPokeEngraving: Scalars['Boolean'];
  readonly cardmanagement_approveEngraving: Cardmanagement_Engraving;
  readonly cardmanagement_rejectEngraving: Cardmanagement_Engraving;
  readonly cardmanagement_Bootstrap: Cardmanagement_CommandResponse;
  readonly cardsettlement_startPlan: Cardsettlement_CommandResponse;
  readonly cardsettlement_rerunClearing: Cardsettlement_CommandResponse;
  readonly cardsettlement_rerunSettlement: Cardsettlement_CommandResponse;
  readonly cardsettlement_ignoreClearing: Cardsettlement_CommandResponse;
  readonly creditengine_processApplication: Scalars['ID'];
  readonly creditengine_setRKIStatus: Creditengine_ApplicationResponse;
  readonly creditengine_setElevated: Creditengine_ApplicationResponse;
  readonly creditengine_setState: Creditengine_SetStateResponse;
  readonly creditengine_elevateApplication: Creditengine_ApplicationResponse;
  readonly creditengine_overrideRejection: Creditengine_ApplicationResponse;
  readonly credit_loan_payDownpayment: Credit_Loan_LoanResponse;
  readonly credit_loan_setLoanAsDefaulted: Credit_Loan_LoanResponse;
  readonly credit_loan_setLoanAsCompleted: Credit_Loan_LoanResponse;
  readonly credit_loan_setLoanAsCancelled: Credit_Loan_LoanResponse;
  readonly credit_loan_resendAmortizationEvent: Scalars['Boolean'];
  readonly credit_loan_createPayLaterForUser: Scalars['Boolean'];
  readonly credit_loan_removePayLaterForUser: Scalars['Boolean'];
  readonly credit_loan_triggerUpdateOrArchivePendingLoans: Scalars['Boolean'];
  readonly credit_loan_triggerCheckAllPayLaterExpired: Scalars['Boolean'];
  readonly credit_loan_triggerNotifyAllUpcomingLoanPayments: Scalars['Boolean'];
  readonly directdebit_revokeBetalingsserviceMandate: Scalars['Boolean'];
  readonly directdebit_createBetalingsserviceMandate: Scalars['Boolean'];
  readonly directdebit_moveBetalingsserviceMandate: Scalars['Boolean'];
  readonly directdebit_rejectBetalingsserviceInstruction: Scalars['Boolean'];
  readonly directdebit_moveMandatesFromBank: Scalars['Boolean'];
  readonly document_generateUrl: Scalars['String'];
  readonly document_debugDeliverDocuments: Scalars['Boolean'];
  readonly eskat_replayRawEskat: Eskat_ReplayRawEskatResponse;
  readonly eskat_recollectEskat: Eskat_RecollectEskatResponse;
  readonly externalaccounts_deleteConnection: Scalars['Boolean'];
  readonly feature_setEmployee: Scalars['Boolean'];
  readonly friend_bootstrapFriends: Scalars['Boolean'];
  readonly goalscore_recalculateGoalsBalance: Goalscore_BalanceRecalculation;
  readonly goalsrules_DebugDeleteRule: Goalsrules_CommandResponse;
  readonly view: Viewing;
  readonly unview: Scalars['Boolean'];
  readonly addUser: AddUserPayload;
  readonly deleteUser?: Maybe<Scalars['Boolean']>;
  readonly updateUserSettings: User;
  readonly updateUser: UpdateUserPayload;
  readonly updateCustomer: Customer;
  readonly addCustomerNote: Note;
  readonly updateNote: Note;
  readonly deleteNote: Note;
  readonly addCustomerTag: Customer;
  readonly deleteCustomerTag: Customer;
  readonly assignTask: Task;
  readonly unassignTask: Task;
  readonly archiveTask: Task;
  readonly unarchiveTask: Task;
  readonly addTaskTag: TagPayload;
  readonly deleteTaskTag: TagPayload;
  readonly approveTask: ApproveTaskPayload;
  readonly audit: AuditPayload;
  /** Charge a fee to a user account */
  readonly charge: ChargePayload;
  /** Refund a fee to a user account */
  readonly refund: RefundPayload;
  readonly insurance_regenerateInsurancePolicyForId: Insurance_Insurance;
  readonly insurance_cancelInsuranceForId: Insurance_Insurance;
  readonly insurance_deleteInsuranceForId: Insurance_Insurance;
  readonly internationalpayment_initiateOutgoingInternationPayment: Internationalpayment_CommandResponse;
  readonly international_payment_api_processSanctionListHit: International_Payment_Api_Payment;
  readonly international_payment_api_processReceiverCountryLimitExceeded: International_Payment_Api_Payment;
  readonly international_payment_api_generateDocumentationUrl: Scalars['String'];
  readonly international_payment_api_debugEmulateValidationResponse: Scalars['Boolean'];
  readonly international_payment_api_debugEmulateInitiateResponse: Scalars['Boolean'];
  readonly invest_MCBootstrapClientInfo: Invest_CommandResponse;
  /** This is deprecated. Use the V2 instead as this will only do lookup in the DKLB database */
  readonly kyc_updateFile: Kyc_File;
  readonly kyc_updatePepStatus: ReadonlyArray<Kyc_Kyc>;
  readonly kyc_updateFileV2: Kyc_File;
  readonly kyc_addBusinessOwner: Kyc_AddBusinessOwnerPayload;
  readonly kyc_recalculateRiskScore: Kyc_Response;
  readonly kyc_setBirthCity: ReadonlyArray<Kyc_Kyc>;
  readonly kyc_setCitizenships: ReadonlyArray<Kyc_Kyc>;
  readonly kyc_setCountryOfResidence: ReadonlyArray<Kyc_Kyc>;
  readonly kyc_setBirthCountry: ReadonlyArray<Kyc_Kyc>;
  readonly kyc_setTaxInformation: ReadonlyArray<Kyc_Kyc>;
  readonly kyc_setExpectedUsage: ReadonlyArray<Kyc_Kyc>;
  readonly kyc_setExpectsInternationalTransfer: ReadonlyArray<Kyc_Kyc>;
  readonly migration_createMigrations: ReadonlyArray<
    Migration_FailedBatchMigrationOperationResult
  >;
  readonly migration_startMigrations: ReadonlyArray<
    Migration_FailedBatchMigrationOperationResult
  >;
  readonly migration_forceMigrations: ReadonlyArray<
    Migration_FailedBatchMigrationOperationResult
  >;
  readonly migration_startMigration: Migration_MigrationOperationResult;
  readonly migration_resetMigration: Migration_MigrationOperationResult;
  readonly migration_advanceMigration: Migration_MigrationOperationResult;
  readonly migration_retryPreviousState: Migration_MigrationOperationResult;
  readonly migration_gotoState: Migration_MigrationOperationResult;
  readonly migration_enableExecutor: Migration_MigrationOperationResult;
  readonly migration_disableExecutor: Migration_MigrationOperationResult;
  readonly migration_skipMigrator: Scalars['Boolean'];
  readonly migration_markBsMoveComplete?: Maybe<Scalars['String']>;
  readonly migration_startExecutor: Migration_ExecutorOperationResult;
  readonly migration_stopExecutor: Migration_ExecutorOperationResult;
  readonly migration_updateEngineConfig: Scalars['Boolean'];
  readonly migration_updateStateConfig: Scalars['Boolean'];
  readonly migration_biz_createMigrations: ReadonlyArray<
    Migration_Biz_FailedBatchMigrationOperationResult
  >;
  readonly migration_biz_startMigrations: ReadonlyArray<
    Migration_Biz_FailedBatchMigrationOperationResult
  >;
  readonly migration_biz_forceMigrations: ReadonlyArray<
    Migration_Biz_FailedBatchMigrationOperationResult
  >;
  readonly migration_biz_startMigration: Migration_Biz_MigrationOperationResult;
  readonly migration_biz_resetMigration: Migration_Biz_MigrationOperationResult;
  readonly migration_biz_advanceMigration: Migration_Biz_MigrationOperationResult;
  readonly migration_biz_retryPreviousState: Migration_Biz_MigrationOperationResult;
  readonly migration_biz_gotoState: Migration_Biz_MigrationOperationResult;
  readonly migration_biz_enableExecutor: Migration_Biz_MigrationOperationResult;
  readonly migration_biz_disableExecutor: Migration_Biz_MigrationOperationResult;
  readonly migration_biz_skipMigrator: Scalars['Boolean'];
  readonly migration_biz_markBsMoveComplete?: Maybe<Scalars['String']>;
  readonly migration_biz_startExecutor: Migration_Biz_ExecutorOperationResult;
  readonly migration_biz_stopExecutor: Migration_Biz_ExecutorOperationResult;
  readonly migration_biz_updateEngineConfig: Scalars['Boolean'];
  readonly migration_biz_updateStateConfig: Scalars['Boolean'];
  readonly migration_biz_backfillCvr: Scalars['Boolean'];
  readonly nets3dsacs_debugOobConfirmation: Scalars['Boolean'];
  readonly offboarding_RequestPrivateOffboarding: Offboarding_RequestOffboardingResult;
  readonly offboarding_FinalisePrivateOffboarding: Offboarding_FinaliseOffboardingResult;
  readonly offboarding_RequestBusinessOffboarding: Offboarding_RequestOffboardingResult;
  readonly offboarding_FinaliseBusinessOffboarding: Offboarding_FinaliseOffboardingResult;
  readonly offboarding_ForceUpdateState: Offboarding_ForceUpdateStateResult;
  readonly onboarding_automaticApprove: Onboarding_AutomaticApprovePayload;
  readonly onboarding_approve: Onboarding_Response;
  readonly onboarding_cancel: Onboarding_Response;
  readonly onboarding_reject: Onboarding_Response;
  readonly onboarding_redo: Onboarding_Response;
  /** Should only be used for manual fixing of stuck users */
  readonly onboarding_confirmRejection: Onboarding_Response;
  readonly onboarding_confirmRejectionBatch: Onboarding_Response;
  /** This is deprecated. Use the V2 instead as this will only do lookup in the DKLB database */
  readonly onboarding_retryScanCustomer: Onboarding_Response;
  readonly onboarding_retryScanCustomerV2: Onboarding_Response;
  /**
   * Retry command in flow which is stuck i.e. due to exceeding maximum retry
   * limit. The updated parameter is the updated property value on the flow which
   * is used for validation
   */
  readonly onboarding_retryCommandDklb: Onboarding_Response;
  readonly onboarding_retryCommand: Onboarding_Response;
  /** Update dklbb flow state for approving test user */
  readonly onboarding_updateState: Onboarding_Response;
  /**
   * This will ensure we never start a business subscription for a given userID. If
   * the subscription has already started this has no effect.
   */
  readonly onboarding_deleteSubscription: Onboarding_Response;
  readonly onboarding_linkUsers: Onboarding_Response;
  readonly onboarding_forceRejection: Onboarding_Response;
  readonly paymentlimit_setDailyLimitOverrideForAccount: Paymentlimit_CommandResponse;
  readonly premium_MCDeletePlan: Premium_CommandResponse;
  readonly premium_MCOrderPlan: Premium_CommandResponse;
  readonly premium_RehydratePlanSubscriptionDeleted: Premium_CommandResponse;
  readonly premium_RehydratePlanSubscriptionChanged: Premium_CommandResponse;
  readonly premium_RehydrateOrderPlanCompleted: Premium_CommandResponse;
  readonly premium_RehydrateCancelPlanCompleted: Premium_CommandResponse;
  readonly premium_DebugActivateUserPlan: Premium_CommandResponse;
  readonly premium_DebugSyncPlanSubscription: Premium_CommandResponse;
  readonly premium_DebugMarkPlanDeleted: Premium_CommandResponse;
  readonly premium_Reconstitute: Premium_CommandResponse;
  readonly signup_reject?: Maybe<Signup_RejectResponse>;
  readonly subaio_registerUser: Scalars['Boolean'];
  readonly subaio_withdrawConsent: Scalars['Boolean'];
  readonly subaio_convertUserToDemo: Scalars['Boolean'];
  readonly subaio_bootstrapUsers: Subaio_BootstrapUsersResults;
  readonly subaio_debugDeleteBusinessUsersInWrongSubaioEnvironment: Scalars['Boolean'];
  readonly subaio_debugUnsetEnrolled: Scalars['Boolean'];
  readonly subscription_switchSubscriptionPlan?: Maybe<
    Subscription_SwitchSubscriptionPlanPayload
  >;
  readonly subscription_holdSubscription?: Maybe<
    Subscription_HoldSubscriptionPayload
  >;
  readonly subscription_resumeSubscription?: Maybe<
    Subscription_ResumeSubscriptionPayload
  >;
  readonly subscription_setNextChargeDate?: Maybe<
    Subscription_SetNextChargeDatePayload
  >;
  readonly subscription_publishSubscriptionDeleted?: Maybe<
    Subscription_PublishSubscriptionDeletedPayload
  >;
  readonly tdschallenge_debugCreateChallenge: Scalars['Boolean'];
  readonly tink_deleteUser?: Maybe<Scalars['Boolean']>;
  readonly cancelFuture: CancelFuturePayload;
  readonly transactioncore_resendTransactions: Scalars['Boolean'];
  readonly transactionlabel_bootstrapUserData: Scalars['Boolean'];
  readonly transactionprocessing_createIndex: Scalars['Boolean'];
  readonly transactionprocessing_deleteIndex: Scalars['Boolean'];
  readonly transactionusercontent_bootstrapAttachments: Scalars['Boolean'];
  readonly transactionusercontent_bootstrapNotes: Scalars['Boolean'];
  readonly transfer_createAccountTransferRequest: Transfer_TransferRequestStatus;
  readonly transfer_createPaymentSlipPaymentRequest: Transfer_TransferRequestStatus;
  readonly transfer_debugTriggerTransferRequestUpdated: Scalars['Boolean'];
  readonly transfer_debugRemoveAutomaticTransfers: Scalars['Boolean'];
  readonly transfer_debugRemoveFutureInWrongState: Scalars['Boolean'];
  readonly user_details_update_TriggerUpdates: User_Details_Update_Response;
  readonly userfile_createRequest: Userfile_Request;
  readonly userfile_generateUploadUrlForRequest: Scalars['String'];
  readonly userfile_getSignedUrl: Scalars['String'];
  readonly userfile_deleteRequest: Userfile_Request;
  readonly userfile_markAsDone: Userfile_Request;
};

export type MutationAccountmanagement_ApproveMandateArgs = {
  challengeId: Scalars['String'];
  accountId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationAccountmanagement_BootstrapAccountEventsArgs = {
  exchange: Scalars['String'];
};

export type MutationAccountsview_GenerateAccountOrderAgreementArgs = {
  userId: Scalars['String'];
};

export type MutationAccountsview_AcceptAccountOrderAgreementArgs = {
  userId: Scalars['String'];
  accountOrderId: Scalars['String'];
};

export type MutationAccountsview_DebugEmulateCreateAccountResponseArgs = {
  accountOrderId: Scalars['String'];
};

export type MutationAccountsview_DebugSetNemkontoStatusActiveForAccountArgs = {
  userId: Scalars['String'];
  accountId: Scalars['String'];
};

export type MutationAccountsview_DebugSetNemkontoStatusDeactivatedForAccountArgs = {
  userId: Scalars['String'];
  accountId: Scalars['String'];
};

export type MutationAccountsview_DebugEnsureSubscriptionsArgs = {
  userId: Scalars['String'];
};

export type MutationAccountsview_CancelAccountCloseRequestArgs = {
  userId: Scalars['String'];
  accountId: Scalars['String'];
};

export type MutationAccountsview_GenerateStatementArgs = {
  accountId: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
};

export type MutationAccountsview_GenerateStatementForAllAccountsArgs = {
  start: Scalars['String'];
  end: Scalars['String'];
  areyousure: Scalars['Boolean'];
};

export type MutationAccountsview_StartWaitingStatementsArgs = {
  n: Scalars['Int'];
  ratelimit: Scalars['Int'];
};

export type MutationAccountsview_BootstrapAccountsArgs = {
  exchange: Scalars['String'];
};

export type MutationAccountsview_BootstrapAccountsFromRailsArgs = {
  userIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  rate?: Maybe<Scalars['Int']>;
};

export type MutationAccountsview_OrderCloseAccountArgs = {
  userId: Scalars['String'];
  accountId: Scalars['String'];
};

export type MutationAccountsview_DebugFixDuplicatePrimaryAccountsArgs = {
  userIds: ReadonlyArray<Scalars['String']>;
};

export type MutationAccountsview_DebugDeliverAccountStatementArgs = {
  accountId: Scalars['String'];
};

export type MutationAppsync_DebugAppSyncArgs = {
  userId: Scalars['String'];
  syncType: Scalars['String'];
};

export type MutationAstralis_McDeleteProductArgs = {
  mcDeleteProductCommand: Astralis_McDeleteProductCommand;
};

export type MutationAstralis_McOrderProductArgs = {
  mcOrderProductCommand: Astralis_McOrderProductCommand;
};

export type MutationBankaccount_SyncUsersArgs = {
  input: Bankaccount_SyncUsersEventCommand;
};

export type MutationBankaccount_InitiateAccountClosureArgs = {
  input: Bankaccount_InitiateAccountClosure;
};

export type MutationBankaccount_McCancelCardReservationArgs = {
  input: Bankaccount_McCancelCardReservationCommand;
};

export type MutationBankaccount_McCancelCardReservationsArgs = {
  input: Bankaccount_McCancelCardReservationsCommand;
};

export type MutationBankaccount_McCancelDepositReservationArgs = {
  input: Bankaccount_McCancelDepositReservationCommand;
};

export type MutationBankaccount_McCreateCorrectionTransactionArgs = {
  input: Bankaccount_McCreateCorrectionTransactionCommand;
};

export type MutationBankaccount_McCreateTransactionReversalCorrectionArgs = {
  input: Bankaccount_McCreateTransactionReversalCorrectionCommand;
};

export type MutationBankaccount_McPokeUserToSystemAccountingTransferArgs = {
  input: Bankaccount_McPokeUserToSystemAccountingTransferCommand;
};

export type MutationBankaccount_McPokeSystemToUserAccountingTransferArgs = {
  input: Bankaccount_McPokeSystemToUserAccountingTransferCommand;
};

export type MutationBankaccount_McPokeSystemToSystemAccountingTransferArgs = {
  input: Bankaccount_McPokeSystemToSystemAccountingTransferCommand;
};

export type MutationBankaccount_McPokeOnboardingArgs = {
  input: Bankaccount_McPokeOnboardingCommand;
};

export type MutationBankaccount_McManuallyFailOnboardingArgs = {
  input: Bankaccount_McManuallyFailOnboardingCommand;
};

export type MutationBankaccount_McPokeAccountCreationArgs = {
  input: Bankaccount_McPokeAccountCreationCommand;
};

export type MutationBankaccount_McPokeCreditTransferArgs = {
  input: Bankaccount_McPokeCreditTransferCommand;
};

export type MutationBankaccount_McRepublishExternalEventArgs = {
  input: Bankaccount_McRepublishExternalEventCommand;
};

export type MutationBankaccount_McBatchSyncArgs = {
  input: Bankaccount_McBatchSyncCommand;
};

export type MutationBecadapter_SyncAccountTransactionsArgs = {
  input: Becadapter_AccountReq;
};

export type MutationBecadapter_SyncAccountFuturesArgs = {
  input: Becadapter_AccountReq;
};

export type MutationBecadapter_CreateAccountInBecArgs = {
  input: Becadapter_CreateAccountInBec;
};

export type MutationBecadapter_McManualSucceedAccountingTransferArgs = {
  input: Becadapter_McManualSucceedAccountingTransfer;
};

export type MutationBecadapter_McManualRetryAccountingTransferArgs = {
  input: Becadapter_McManualRetryAccountingTransfer;
};

export type MutationBecadapter_McManualFailAccountingTransferArgs = {
  input: Becadapter_McManualFailAccountingTransfer;
};

export type MutationBecadapter_McPokeAccountingTransferArgs = {
  input: Becadapter_McPokeAccountingTransfer;
};

export type MutationBecadapter_McReverseUserToSystemAccountingTransferArgs = {
  input: Becadapter_McReverseUserToSystemAccountingTransfer;
};

export type MutationBecadapter_McManualBootstrapCustomerArgs = {
  input: Becadapter_McManualBoostrapCustomer;
};

export type MutationBecadapter_McManualRetryCustomerOnboardingArgs = {
  input: Becadapter_McManualRetryCustomerOnboarding;
};

export type MutationBecadapter_McManualFailCustomerOnboardingArgs = {
  input: Becadapter_McManualFailCustomerOnboarding;
};

export type MutationBecadapter_McPokeCustomerOnboardingArgs = {
  input: Becadapter_McPokeCustomerOnboarding;
};

export type MutationBecadapter_McRepublishBecPostingEntryCreatedArgs = {
  input: Becadapter_McRepublishBecPostingEntryCreated;
};

export type MutationBecadapter_McCreateNewBecHomeBankingArgs = {
  input: Becadapter_McCreateNewBecHomeBanking;
};

export type MutationBecadapter_McUpdateBecHomeBankingArgs = {
  input: Becadapter_McUpdateBecHomeBanking;
};

export type MutationBecadapter_McManualRetryAccountCreationArgs = {
  input: Becadapter_McManualRetryAccountCreation;
};

export type MutationBecadapter_McManualSkipBecAccountCreationArgs = {
  input: Becadapter_McManualSkipBecAccountCreation;
};

export type MutationBecadapter_McManualFailAccountCreationArgs = {
  input: Becadapter_McManualFailAccountCreation;
};

export type MutationBecadapter_McPokeAccountCreationArgs = {
  input: Becadapter_McPokeAccountCreation;
};

export type MutationBecadapter_McManualResetAccountCreationStateToInitiatedArgs = {
  input: Becadapter_McManualResetAccountCreationStateToInitiated;
};

export type MutationBecadapter_McManualFailCreditTransferArgs = {
  input: Becadapter_McManualFailCreditTransfer;
};

export type MutationBecadapter_McManualSucceedCreditTransferArgs = {
  input: Becadapter_McManualSucceedCreditTransfer;
};

export type MutationBecadapter_McCreateCreditTransferCorrectionPostingArgs = {
  input: Becadapter_McCreateCreditTransferCorrectionPosting;
};

export type MutationBecadapter_McPokeCreditTransferArgs = {
  input: Becadapter_McPokeCreditTransfer;
};

export type MutationBecadapter_McManualFailPaymentSlipArgs = {
  input: Becadapter_McManualFailPaymentSlip;
};

export type MutationBecadapter_McBootstrapSyncArgs = {
  input: Becadapter_McBootstrapSync;
};

export type MutationBecadapter_McSetAccountBankZoneIDsArgs = {
  input: Becadapter_McSetAccountBankZoneIDs;
};

export type MutationBecadapter_McManualRetryLoanConfigsArgs = {
  input: Becadapter_McManualRetryLoanConfigs;
};

export type MutationBecadapter_McManualFailLoanConfigsArgs = {
  input: Becadapter_McManualFailLoanConfigs;
};

export type MutationBecadapter_McRepublishExternalEventArgs = {
  input: Becadapter_McRepublishExternalEventCommand;
};

export type MutationBrand_CreateBrandArgs = {
  input: Brand_BrandInput;
};

export type MutationBrand_UpdateBrandArgs = {
  brandId: Scalars['ID'];
  input: Brand_BrandInput;
};

export type MutationBrand_UpdateLogoArgs = {
  brandId: Scalars['ID'];
};

export type MutationBrand_ConnectUnbrandedArgs = {
  brandId: Scalars['ID'];
  unbrandedIds: ReadonlyArray<Scalars['String']>;
};

export type MutationBraze_Sync_ResendApplicationApprovedArgs = {
  userids: ReadonlyArray<Scalars['String']>;
};

export type MutationCardmanagement_OrderCardPhysicalArgs = {
  request: Cardmanagement_PhysicalOrderCardInput;
};

export type MutationCardmanagement_OrderCardVirtualArgs = {
  request: Cardmanagement_VirtualOrderCardInput;
};

export type MutationCardmanagement_DebugOrderCardPhysicalWithPinArgs = {
  request: Cardmanagement_PhysicalOrderWithPinInput;
};

export type MutationCardmanagement_ReplacePhysicalCardArgs = {
  request: Cardmanagement_ReplacePhysicalCardInput;
};

export type MutationCardmanagement_CloseCardArgs = {
  userId: Scalars['String'];
  cardId: Scalars['String'];
};

export type MutationCardmanagement_ForceCloseCardArgs = {
  userId: Scalars['String'];
  cardId: Scalars['String'];
};

export type MutationCardmanagement_PokeCloseCardSagaArgs = {
  cardId: Scalars['String'];
};

export type MutationCardmanagement_ChangeCardStatusArgs = {
  userId: Scalars['String'];
  cardId: Scalars['String'];
  newStatus: Cardmanagement_InputCardState;
};

export type MutationCardmanagement_ChangeUserClassificationArgs = {
  userId: Scalars['String'];
  classification: Cardmanagement_UserClassification;
};

export type MutationCardmanagement_ChangeUserRelationArgs = {
  userId: Scalars['String'];
  relation: Scalars['String'];
};

export type MutationCardmanagement_BlockUserArgs = {
  userId: Scalars['String'];
};

export type MutationCardmanagement_UnblockUserArgs = {
  userId: Scalars['String'];
};

export type MutationCardmanagement_SyncUsersAtNetsArgs = {
  userIds: ReadonlyArray<Scalars['String']>;
};

export type MutationCardmanagement_BootstrapCardInLcmArgs = {
  userId: Scalars['String'];
  cardId: Scalars['String'];
};

export type MutationCardmanagement_UpdateCompanyInfoArgs = {
  request: Cardmanagement_UpdateCompanyInfoInput;
};

export type MutationCardmanagement_AddUserToCompanyArgs = {
  companyId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationCardmanagement_RemoveUserFromCompanyArgs = {
  companyId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationCardmanagement_SetCompanyClassifierArgs = {
  companyId: Scalars['String'];
  classifier: Cardmanagement_CompanyClassification;
};

export type MutationCardmanagement_DebugPokeEngravingArgs = {
  id: Scalars['String'];
};

export type MutationCardmanagement_ApproveEngravingArgs = {
  id: Scalars['String'];
};

export type MutationCardmanagement_RejectEngravingArgs = {
  id: Scalars['String'];
  reason: Scalars['String'];
};

export type MutationCardmanagement_BootstrapArgs = {
  exchange: Scalars['String'];
};

export type MutationCardsettlement_StartPlanArgs = {
  cmd: Cardsettlement_StartPlanCommand;
};

export type MutationCardsettlement_RerunClearingArgs = {
  cmd: Cardsettlement_RerunCommand;
};

export type MutationCardsettlement_RerunSettlementArgs = {
  cmd: Cardsettlement_RerunCommand;
};

export type MutationCardsettlement_IgnoreClearingArgs = {
  cmd: Cardsettlement_IgnoreCmd;
};

export type MutationCreditengine_ProcessApplicationArgs = {
  input: Creditengine_ApplicationInput;
};

export type MutationCreditengine_SetRkiStatusArgs = {
  input: Creditengine_SetRkiStatusInput;
};

export type MutationCreditengine_SetElevatedArgs = {
  input: Creditengine_SetElevatedInput;
};

export type MutationCreditengine_SetStateArgs = {
  input: Creditengine_SetStateInput;
};

export type MutationCreditengine_ElevateApplicationArgs = {
  input: Creditengine_ElevateApplicationInput;
};

export type MutationCreditengine_OverrideRejectionArgs = {
  input: Creditengine_OverrideRejectionInput;
};

export type MutationCredit_Loan_PayDownpaymentArgs = {
  input?: Maybe<Credit_Loan_PayDownpaymentInput>;
};

export type MutationCredit_Loan_SetLoanAsDefaultedArgs = {
  input?: Maybe<Credit_Loan_LoanInput>;
};

export type MutationCredit_Loan_SetLoanAsCompletedArgs = {
  input?: Maybe<Credit_Loan_LoanInput>;
};

export type MutationCredit_Loan_SetLoanAsCancelledArgs = {
  input?: Maybe<Credit_Loan_LoanInput>;
};

export type MutationCredit_Loan_CreatePayLaterForUserArgs = {
  input: Credit_Loan_PayLaterInput;
};

export type MutationCredit_Loan_RemovePayLaterForUserArgs = {
  input: Credit_Loan_PayLaterInput;
};

export type MutationDirectdebit_RevokeBetalingsserviceMandateArgs = {
  userId: Scalars['String'];
  mandateId: Scalars['String'];
};

export type MutationDirectdebit_CreateBetalingsserviceMandateArgs = {
  input?: Maybe<Directdebit_CreateBetalingserviceMandateInput>;
};

export type MutationDirectdebit_MoveBetalingsserviceMandateArgs = {
  userId: Scalars['String'];
  accountId: Scalars['String'];
  mandateId: Scalars['String'];
};

export type MutationDirectdebit_RejectBetalingsserviceInstructionArgs = {
  userId: Scalars['String'];
  instructionId: Scalars['String'];
};

export type MutationDirectdebit_MoveMandatesFromBankArgs = {
  input: Directdebit_MoveMandatesFromBankInput;
};

export type MutationDocument_GenerateUrlArgs = {
  agreementId: Scalars['ID'];
};

export type MutationDocument_DebugDeliverDocumentsArgs = {
  userId: Scalars['ID'];
};

export type MutationEskat_ReplayRawEskatArgs = {
  input: Eskat_ReplayRawEskatInput;
};

export type MutationEskat_RecollectEskatArgs = {
  input: Eskat_RecollectEskatInput;
};

export type MutationExternalaccounts_DeleteConnectionArgs = {
  userId: Scalars['String'];
  connectionId: Scalars['String'];
};

export type MutationFeature_SetEmployeeArgs = {
  userId: Scalars['String'];
  employee: Scalars['Boolean'];
};

export type MutationFriend_BootstrapFriendsArgs = {
  exchange: Scalars['String'];
};

export type MutationGoalscore_RecalculateGoalsBalanceArgs = {
  userId: Scalars['String'];
};

export type MutationGoalsrules_DebugDeleteRuleArgs = {
  deleteRuleCommand: Goalsrules_DeleteRuleCommand;
};

export type MutationViewArgs = {
  userId: Scalars['ID'];
  customerId: Scalars['ID'];
};

export type MutationUnviewArgs = {
  userId: Scalars['ID'];
  customerId: Scalars['ID'];
};

export type MutationAddUserArgs = {
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};

export type MutationUpdateUserSettingsArgs = {
  userId: Scalars['String'];
  settings: SettingsInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateCustomerArgs = {
  customerId: Scalars['ID'];
  input?: Maybe<UpdateCustomerInput>;
};

export type MutationAddCustomerNoteArgs = {
  customerId: Scalars['ID'];
  input: NoteInput;
};

export type MutationUpdateNoteArgs = {
  noteId: Scalars['ID'];
  input: NoteInput;
};

export type MutationDeleteNoteArgs = {
  noteId: Scalars['ID'];
};

export type MutationAddCustomerTagArgs = {
  input: CustomerTagInput;
};

export type MutationDeleteCustomerTagArgs = {
  input: CustomerTagInput;
};

export type MutationAssignTaskArgs = {
  userId: Scalars['ID'];
  taskId: Scalars['ID'];
};

export type MutationUnassignTaskArgs = {
  taskId: Scalars['ID'];
};

export type MutationArchiveTaskArgs = {
  taskId: Scalars['ID'];
};

export type MutationUnarchiveTaskArgs = {
  taskId: Scalars['ID'];
};

export type MutationAddTaskTagArgs = {
  input: TaskTagInput;
};

export type MutationDeleteTaskTagArgs = {
  input: TaskTagInput;
};

export type MutationApproveTaskArgs = {
  input: ApproveTaskInput;
};

export type MutationAuditArgs = {
  input: AuditInput;
};

export type MutationChargeArgs = {
  input: ChargeInput;
};

export type MutationRefundArgs = {
  input: RefundInput;
};

export type MutationInsurance_RegenerateInsurancePolicyForIdArgs = {
  insuranceId: Scalars['String'];
};

export type MutationInsurance_CancelInsuranceForIdArgs = {
  userId: Scalars['String'];
  insuranceId: Scalars['String'];
  activityContext: Insurance_CreateActivityContextInput;
};

export type MutationInsurance_DeleteInsuranceForIdArgs = {
  userId: Scalars['String'];
  insuranceId: Scalars['String'];
  activityContext: Insurance_CreateActivityContextInput;
};

export type MutationInternationalpayment_InitiateOutgoingInternationPaymentArgs = {
  input: Internationalpayment_InitiateOutgoingInternationPayment;
};

export type MutationInternational_Payment_Api_ProcessSanctionListHitArgs = {
  input: International_Payment_Api_ProcessSanctionListHitInput;
};

export type MutationInternational_Payment_Api_ProcessReceiverCountryLimitExceededArgs = {
  input: International_Payment_Api_ProcessReceiverCountryLimitExceededInput;
};

export type MutationInternational_Payment_Api_GenerateDocumentationUrlArgs = {
  documentationId: Scalars['ID'];
};

export type MutationInternational_Payment_Api_DebugEmulateValidationResponseArgs = {
  paymentID: Scalars['String'];
  success: Scalars['Boolean'];
};

export type MutationInternational_Payment_Api_DebugEmulateInitiateResponseArgs = {
  paymentID: Scalars['String'];
  success: Scalars['Boolean'];
};

export type MutationInvest_McBootstrapClientInfoArgs = {
  mcBootstrapClientInfoCommand: Invest_McBootstrapClientInfoRequest;
};

export type MutationKyc_UpdateFileArgs = {
  userId: Scalars['String'];
  input: Kyc_FileInput;
};

export type MutationKyc_UpdatePepStatusArgs = {
  input: Kyc_UserInput;
  isPep: Scalars['Boolean'];
};

export type MutationKyc_UpdateFileV2Args = {
  input: Kyc_UserInput;
  fileInput: Kyc_FileInput;
};

export type MutationKyc_AddBusinessOwnerArgs = {
  baseUser: Kyc_UserInput;
  businessOwner: Kyc_BusinessOwnerInput;
};

export type MutationKyc_RecalculateRiskScoreArgs = {
  relation: Kyc_Relation;
  userids: ReadonlyArray<Scalars['String']>;
};

export type MutationKyc_SetBirthCityArgs = {
  input: Kyc_UserInput;
  audit: Kyc_AuditInput;
  birthCity: Scalars['String'];
};

export type MutationKyc_SetCitizenshipsArgs = {
  input: Kyc_UserInput;
  audit: Kyc_AuditInput;
  countryCodes: ReadonlyArray<Kyc_SupportedCountries>;
};

export type MutationKyc_SetCountryOfResidenceArgs = {
  input: Kyc_UserInput;
  audit: Kyc_AuditInput;
  countryOfResidence: Kyc_SupportedCountries;
};

export type MutationKyc_SetBirthCountryArgs = {
  input: Kyc_UserInput;
  audit: Kyc_AuditInput;
  birthCountry: Kyc_SupportedCountries;
};

export type MutationKyc_SetTaxInformationArgs = {
  input: Kyc_UserInput;
  audit: Kyc_AuditInput;
  taxInformation: Kyc_TaxLiableInCountriesInput;
};

export type MutationKyc_SetExpectedUsageArgs = {
  input: Kyc_UserInput;
  audit: Kyc_AuditInput;
  text?: Maybe<Scalars['String']>;
};

export type MutationKyc_SetExpectsInternationalTransferArgs = {
  input: Kyc_UserInput;
  audit: Kyc_AuditInput;
  expectsToReceive: Scalars['Boolean'];
  receiveReason?: Maybe<Scalars['String']>;
  expectsToSend: Scalars['Boolean'];
  sendReason?: Maybe<Scalars['String']>;
};

export type MutationMigration_CreateMigrationsArgs = {
  userIds: ReadonlyArray<Scalars['String']>;
};

export type MutationMigration_StartMigrationsArgs = {
  userIds: ReadonlyArray<Scalars['String']>;
  batchName: Scalars['String'];
};

export type MutationMigration_ForceMigrationsArgs = {
  userIds: ReadonlyArray<Scalars['String']>;
};

export type MutationMigration_StartMigrationArgs = {
  id: Scalars['ID'];
};

export type MutationMigration_ResetMigrationArgs = {
  id: Scalars['ID'];
};

export type MutationMigration_AdvanceMigrationArgs = {
  id: Scalars['ID'];
};

export type MutationMigration_RetryPreviousStateArgs = {
  migrationId: Scalars['ID'];
};

export type MutationMigration_GotoStateArgs = {
  migrationId: Scalars['ID'];
  state: Migration_MigrationStateType;
};

export type MutationMigration_EnableExecutorArgs = {
  migrationId: Scalars['ID'];
};

export type MutationMigration_DisableExecutorArgs = {
  migrationId: Scalars['ID'];
};

export type MutationMigration_SkipMigratorArgs = {
  id: Scalars['ID'];
};

export type MutationMigration_MarkBsMoveCompleteArgs = {
  ssns: ReadonlyArray<Scalars['String']>;
};

export type MutationMigration_UpdateEngineConfigArgs = {
  config: Migration_EngineConfigInput;
};

export type MutationMigration_UpdateStateConfigArgs = {
  id: Scalars['ID'];
  config: Migration_StateConfigInput;
};

export type MutationMigration_Biz_CreateMigrationsArgs = {
  userIds: ReadonlyArray<Scalars['String']>;
};

export type MutationMigration_Biz_StartMigrationsArgs = {
  userIds: ReadonlyArray<Scalars['String']>;
  batchName: Scalars['String'];
};

export type MutationMigration_Biz_ForceMigrationsArgs = {
  userIds: ReadonlyArray<Scalars['String']>;
};

export type MutationMigration_Biz_StartMigrationArgs = {
  id: Scalars['ID'];
};

export type MutationMigration_Biz_ResetMigrationArgs = {
  id: Scalars['ID'];
};

export type MutationMigration_Biz_AdvanceMigrationArgs = {
  id: Scalars['ID'];
};

export type MutationMigration_Biz_RetryPreviousStateArgs = {
  migrationId: Scalars['ID'];
};

export type MutationMigration_Biz_GotoStateArgs = {
  migrationId: Scalars['ID'];
  state: Migration_Biz_MigrationStateType;
};

export type MutationMigration_Biz_EnableExecutorArgs = {
  migrationId: Scalars['ID'];
};

export type MutationMigration_Biz_DisableExecutorArgs = {
  migrationId: Scalars['ID'];
};

export type MutationMigration_Biz_SkipMigratorArgs = {
  id: Scalars['ID'];
};

export type MutationMigration_Biz_MarkBsMoveCompleteArgs = {
  cvrs: ReadonlyArray<Scalars['String']>;
};

export type MutationMigration_Biz_UpdateEngineConfigArgs = {
  config: Migration_Biz_EngineConfigInput;
};

export type MutationMigration_Biz_UpdateStateConfigArgs = {
  id: Scalars['ID'];
  config: Migration_Biz_StateConfigInput;
};

export type MutationNets3dsacs_DebugOobConfirmationArgs = {
  id: Scalars['Int'];
  status: Scalars['Int'];
};

export type MutationOffboarding_RequestPrivateOffboardingArgs = {
  input: Offboarding_RequestPrivateOffboardingInput;
};

export type MutationOffboarding_FinalisePrivateOffboardingArgs = {
  input: Offboarding_FinalisePrivateOffboardingInput;
};

export type MutationOffboarding_RequestBusinessOffboardingArgs = {
  input: Offboarding_RequestBusinessOffboardingInput;
};

export type MutationOffboarding_FinaliseBusinessOffboardingArgs = {
  input: Offboarding_FinaliseBusinessOffboardingInput;
};

export type MutationOffboarding_ForceUpdateStateArgs = {
  input: Offboarding_ForceUpdateStateInput;
};

export type MutationOnboarding_AutomaticApproveArgs = {
  input: Onboarding_AutomaticApproveInput;
};

export type MutationOnboarding_ApproveArgs = {
  userId: Scalars['String'];
  relation: Onboarding_Relation;
  message?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  silent?: Maybe<Scalars['Boolean']>;
  homeBankingId: Scalars['String'];
};

export type MutationOnboarding_CancelArgs = {
  userId: Scalars['String'];
  relation: Onboarding_Relation;
  message?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  silent?: Maybe<Scalars['Boolean']>;
};

export type MutationOnboarding_RejectArgs = {
  userId: Scalars['String'];
  relation: Onboarding_Relation;
  message?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  silent?: Maybe<Scalars['Boolean']>;
};

export type MutationOnboarding_RedoArgs = {
  input: Onboarding_RedoInput;
};

export type MutationOnboarding_ConfirmRejectionArgs = {
  input: Onboarding_UserInput;
};

export type MutationOnboarding_ConfirmRejectionBatchArgs = {
  input: Onboarding_ConfirmRejectionBatchInput;
};

export type MutationOnboarding_RetryScanCustomerArgs = {
  referenceId: Scalars['String'];
};

export type MutationOnboarding_RetryScanCustomerV2Args = {
  input: Onboarding_UserInput;
};

export type MutationOnboarding_RetryCommandDklbArgs = {
  input: Onboarding_RetryCommandInputDklb;
};

export type MutationOnboarding_RetryCommandArgs = {
  input: Onboarding_RetryCommandInput;
};

export type MutationOnboarding_DeleteSubscriptionArgs = {
  input: Onboarding_UserInput;
};

export type MutationOnboarding_LinkUsersArgs = {
  input: Onboarding_UserInput;
};

export type MutationOnboarding_ForceRejectionArgs = {
  input: Onboarding_UserInput;
};

export type MutationPaymentlimit_SetDailyLimitOverrideForAccountArgs = {
  input: Paymentlimit_SetDailyLimitOverrideForAccountCommand;
};

export type MutationPremium_McDeletePlanArgs = {
  mcDeletePlanCommand: Premium_McDeletePlanCommand;
};

export type MutationPremium_McOrderPlanArgs = {
  mcOrderPlanCommand: Premium_McOrderPlanCommand;
};

export type MutationPremium_RehydratePlanSubscriptionDeletedArgs = {
  rehydratePlanSubscriptionDeletedCommand: Premium_RehydrateCommand;
};

export type MutationPremium_RehydratePlanSubscriptionChangedArgs = {
  rehydratePlanSubscriptionChangedCommand: Premium_RehydrateCommand;
};

export type MutationPremium_RehydrateOrderPlanCompletedArgs = {
  rehydrateOrderPlanCompletedCommand: Premium_RehydrateCommand;
};

export type MutationPremium_RehydrateCancelPlanCompletedArgs = {
  rehydrateCancelPlanCompletedCommand: Premium_RehydrateCommand;
};

export type MutationPremium_DebugActivateUserPlanArgs = {
  debugActivateUserPlan: Premium_DebugActivateUserPlanCommand;
};

export type MutationPremium_DebugSyncPlanSubscriptionArgs = {
  debugSyncPlanSubscription: Premium_DebugSyncPlanSubscriptionCommand;
};

export type MutationPremium_DebugMarkPlanDeletedArgs = {
  debugMarkPlanDeleted: Premium_DebugMarkPlanDeletedCommand;
};

export type MutationPremium_ReconstituteArgs = {
  exchange: Scalars['String'];
};

export type MutationSignup_RejectArgs = {
  signupId: Scalars['String'];
  supporterEmail: Scalars['String'];
  alias: Scalars['String'];
  message: Scalars['String'];
};

export type MutationSubaio_RegisterUserArgs = {
  userId: Scalars['String'];
};

export type MutationSubaio_WithdrawConsentArgs = {
  userId: Scalars['String'];
};

export type MutationSubaio_ConvertUserToDemoArgs = {
  userId: Scalars['String'];
};

export type MutationSubaio_BootstrapUsersArgs = {
  userIds: ReadonlyArray<Scalars['String']>;
};

export type MutationSubaio_DebugUnsetEnrolledArgs = {
  userId: Scalars['String'];
};

export type MutationSubscription_SwitchSubscriptionPlanArgs = {
  input: Subscription_SwitchSubscriptionPlanInput;
};

export type MutationSubscription_HoldSubscriptionArgs = {
  input: Subscription_HoldSubscriptionInput;
};

export type MutationSubscription_ResumeSubscriptionArgs = {
  input: Subscription_ResumeSubscriptionInput;
};

export type MutationSubscription_SetNextChargeDateArgs = {
  input: Subscription_SetNextChargeDateInput;
};

export type MutationSubscription_PublishSubscriptionDeletedArgs = {
  input: Subscription_PublishSubscriptionDeletedInput;
};

export type MutationTdschallenge_DebugCreateChallengeArgs = {
  input?: Maybe<Tdschallenge_CreateChallenge>;
};

export type MutationTink_DeleteUserArgs = {
  userId: Scalars['String'];
};

export type MutationCancelFutureArgs = {
  userId: Scalars['String'];
  transactionId: Scalars['String'];
};

export type MutationTransactioncore_ResendTransactionsArgs = {
  input: Transactioncore_ResendFilter;
};

export type MutationTransactionlabel_BootstrapUserDataArgs = {
  exchange: Scalars['String'];
};

export type MutationTransactionusercontent_BootstrapAttachmentsArgs = {
  exchange: Scalars['String'];
};

export type MutationTransactionusercontent_BootstrapNotesArgs = {
  exchange: Scalars['String'];
};

export type MutationTransfer_CreateAccountTransferRequestArgs = {
  input: Transfer_CreateAccountTransferRequestInput;
};

export type MutationTransfer_CreatePaymentSlipPaymentRequestArgs = {
  input: Transfer_CreatePaymentSlipPaymentRequestInput;
};

export type MutationTransfer_DebugTriggerTransferRequestUpdatedArgs = {
  transferRequestId: Scalars['String'];
};

export type MutationTransfer_DebugRemoveAutomaticTransfersArgs = {
  accountId: Scalars['String'];
};

export type MutationTransfer_DebugRemoveFutureInWrongStateArgs = {
  transferRequestId: Scalars['String'];
};

export type MutationUserfile_CreateRequestArgs = {
  req: Userfile_CreateRequest;
};

export type MutationUserfile_GenerateUploadUrlForRequestArgs = {
  req: Userfile_GenerateUploadUrlForRequestInput;
};

export type MutationUserfile_GetSignedUrlArgs = {
  fileId: Scalars['ID'];
};

export type MutationUserfile_DeleteRequestArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type MutationUserfile_MarkAsDoneArgs = {
  id: Scalars['ID'];
};

export type Name = {
  readonly __typename?: 'Name';
  readonly first: Scalars['String'];
  readonly last: Scalars['String'];
};

export type NameInput = {
  readonly first: Scalars['String'];
  readonly last: Scalars['String'];
};

export type Note = {
  readonly __typename?: 'Note';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly content: Scalars['String'];
  readonly created: Scalars['Date'];
  readonly createdBy?: Maybe<User>;
  readonly deleted?: Maybe<Scalars['Date']>;
  readonly deletedBy?: Maybe<User>;
  readonly tags: TagCollection;
};

export type NoteCollection = {
  readonly __typename?: 'NoteCollection';
  readonly count: Scalars['Int'];
  readonly nodes: ReadonlyArray<Note>;
};

export type NoteInput = {
  readonly content: Scalars['String'];
  readonly tags?: Maybe<ReadonlyArray<NoteTagInput>>;
};

export type NoteTagInput = {
  readonly name: Scalars['String'];
};

export type Offboarding_FinaliseBusinessOffboardingInput = {
  readonly id: Scalars['String'];
};

export type Offboarding_FinaliseOffboardingResult = {
  readonly __typename?: 'offboarding_FinaliseOffboardingResult';
  readonly id: Scalars['String'];
  readonly success: Scalars['Boolean'];
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type Offboarding_FinalisePrivateOffboardingInput = {
  readonly id: Scalars['String'];
};

export type Offboarding_ForceUpdateStateInput = {
  readonly id: Scalars['String'];
  readonly state: Offboarding_State;
};

export type Offboarding_ForceUpdateStateResult = {
  readonly __typename?: 'offboarding_ForceUpdateStateResult';
  readonly id: Scalars['String'];
  readonly success: Scalars['Boolean'];
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type Offboarding_RequestBusinessOffboardingInput = {
  readonly userId: Scalars['String'];
  readonly requestedBy: Offboarding_RequestedBy;
};

export enum Offboarding_RequestedBy {
  User = 'USER',
  Lunar = 'LUNAR',
}

export type Offboarding_RequestOffboardingResult = {
  readonly __typename?: 'offboarding_RequestOffboardingResult';
  readonly id: Scalars['String'];
  readonly success: Scalars['Boolean'];
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type Offboarding_RequestPrivateOffboardingInput = {
  readonly userId: Scalars['String'];
  readonly requestedBy: Offboarding_RequestedBy;
};

export enum Offboarding_State {
  Created = 'CREATED',
  Requested = 'REQUESTED',
  Blocked = 'BLOCKED',
  Inprogress = 'INPROGRESS',
  Lastaccountoffboarded = 'LASTACCOUNTOFFBOARDED',
  Approvedinhouston = 'APPROVEDINHOUSTON',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Null = 'NULL',
}

export type Onboarding_AutomaticApproveInput = {
  readonly userId: Scalars['String'];
  readonly relation: Onboarding_Relation;
  readonly message?: Maybe<Scalars['String']>;
  readonly alias?: Maybe<Scalars['String']>;
  readonly silent?: Maybe<Scalars['Boolean']>;
};

export type Onboarding_AutomaticApprovePayload = {
  readonly __typename?: 'onboarding_AutomaticApprovePayload';
  /** This is populated with the errors if the mutation went wrong synchronously */
  readonly errors?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** User is populated as long as errors is not */
  readonly user?: Maybe<Onboarding_User>;
};

export type Onboarding_AutomaticOnboarding = {
  readonly __typename?: 'onboarding_AutomaticOnboarding';
  readonly status: Onboarding_AutomaticOnboardingStatus;
  /** This is populated with the errors from automatic onboarding asynchronously */
  readonly error?: Maybe<Scalars['String']>;
};

export enum Onboarding_AutomaticOnboardingStatus {
  /** If automatic onboarding has not been intiated - this includes manually onboarded users */
  NotInitiated = 'NOT_INITIATED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export type Onboarding_ConfirmRejectionBatchInput = {
  readonly relation: Onboarding_Relation;
  readonly userIds?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type Onboarding_Country = {
  readonly __typename?: 'onboarding_Country';
  readonly countryCode: Scalars['String'];
  readonly iconURL: Scalars['String'];
};

export type Onboarding_CountryPayload = {
  readonly __typename?: 'onboarding_CountryPayload';
  readonly defaultCountry: Onboarding_Country;
  /** This is deprecated. Use the individual lists intead */
  readonly countries: ReadonlyArray<Onboarding_Country>;
  readonly citizenshipCountries: ReadonlyArray<Onboarding_Country>;
  readonly taxLiabilityCountries: ReadonlyArray<Onboarding_Country>;
  readonly residenceCountries: ReadonlyArray<Onboarding_Country>;
};

export type Onboarding_RedoInput = {
  readonly userId: Scalars['String'];
  readonly relation: Onboarding_Relation;
  readonly message?: Maybe<Scalars['String']>;
  readonly alias?: Maybe<Scalars['String']>;
  readonly silent?: Maybe<Scalars['Boolean']>;
  readonly proofOfAddress?: Maybe<Scalars['Boolean']>;
  readonly screenIds: ReadonlyArray<Scalars['String']>;
};

export type Onboarding_Rejection = {
  readonly __typename?: 'onboarding_Rejection';
  readonly reason: Scalars['String'];
  readonly message?: Maybe<Scalars['String']>;
  readonly confirmed: Scalars['Boolean'];
};

export enum Onboarding_Relation {
  Dklb = 'DKLB',
  Dklbb = 'DKLBB',
  Selb = 'SELB',
  Nolb = 'NOLB',
}

export type Onboarding_Response = {
  readonly __typename?: 'onboarding_Response';
  readonly success: Scalars['Boolean'];
  readonly error?: Maybe<Scalars['String']>;
};

export type Onboarding_RetryCommandInput = {
  readonly userId: Scalars['String'];
  readonly groupId: Scalars['String'];
  readonly relation: Onboarding_Relation;
};

export type Onboarding_RetryCommandInputDklb = {
  readonly userId: Scalars['String'];
  readonly stepId: Scalars['String'];
  readonly oldStepState: Onboarding_StepState;
  readonly newStepState: Onboarding_StepState;
  readonly updated: Scalars['String'];
};

export enum Onboarding_ScanStatus {
  Unknown = 'UNKNOWN',
  Yes = 'YES',
  No = 'NO',
}

export enum Onboarding_State {
  Apply = 'APPLY',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Redo = 'REDO',
  Rejected = 'REJECTED',
  All = 'ALL',
  Unknown = 'UNKNOWN',
}

export enum Onboarding_StepState {
  Completed = 'COMPLETED',
  Posted = 'POSTED',
  Created = 'CREATED',
  Error = 'ERROR',
}

export type Onboarding_User = {
  readonly __typename?: 'onboarding_User';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['String'];
  readonly updatedAt: Scalars['String'];
  readonly version: Scalars['String'];
  readonly flowId: Scalars['String'];
  readonly state: Onboarding_State;
  readonly userId: Scalars['String'];
  readonly potentialScanHit: Onboarding_ScanStatus;
  readonly bban: Scalars['String'];
  readonly socialSecurity: Scalars['String'];
  readonly approvedBy?: Maybe<Scalars['String']>;
  readonly redoReasons: ReadonlyArray<Scalars['String']>;
  readonly redoMessage?: Maybe<Scalars['String']>;
  readonly rejection?: Maybe<Onboarding_Rejection>;
  readonly language: Scalars['String'];
  /** Shows the state of automatic onboarding against BEC */
  readonly automaticOnboarding: Onboarding_AutomaticOnboarding;
};

export type Onboarding_UserCollection = {
  readonly __typename?: 'onboarding_UserCollection';
  readonly count: Scalars['Int'];
  readonly nodes: ReadonlyArray<Onboarding_User>;
};

export type Onboarding_UserInput = {
  readonly userId: Scalars['ID'];
  readonly relation: Onboarding_Relation;
};

export type Onboarding_UserPayload = {
  readonly __typename?: 'onboarding_UserPayload';
  readonly user?: Maybe<Onboarding_User>;
  readonly errors?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type Onboarding_UsersInput = {
  readonly state?: Maybe<Onboarding_State>;
  readonly limit?: Maybe<Scalars['Int']>;
  readonly offset?: Maybe<Scalars['Int']>;
};

export type Onboarding_UsersPayload = {
  readonly __typename?: 'onboarding_UsersPayload';
  readonly users: Onboarding_UserCollection;
  readonly errors?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export enum OriginType {
  Account = 'ACCOUNT',
  Prepaid = 'PREPAID',
}

export type PaymentInfo = {
  readonly __typename?: 'PaymentInfo';
  readonly canDelete?: Maybe<Scalars['Boolean']>;
  readonly counterPartyName?: Maybe<Scalars['String']>;
  readonly counterPartyID?: Maybe<Scalars['String']>;
  readonly paymentID?: Maybe<Scalars['String']>;
  readonly correlationID?: Maybe<Scalars['String']>;
  readonly subType?: Maybe<Scalars['String']>;
};

export type Paymentlimit_AccountViewInfo = {
  readonly __typename?: 'paymentlimit_AccountViewInfo';
  readonly totalTransactionVolume?: Maybe<Scalars['paymentlimit_Decimal']>;
  readonly numberOfUsers?: Maybe<Scalars['Int']>;
  readonly dailyAccountLimitOverride?: Maybe<Scalars['paymentlimit_Decimal']>;
};

export type Paymentlimit_CommandResponse = {
  readonly __typename?: 'paymentlimit_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Paymentlimit_SetDailyLimitOverrideForAccountCommand = {
  readonly accountID: Scalars['String'];
  readonly limitOverride: Scalars['paymentlimit_Decimal'];
  readonly reson: Scalars['String'];
};

export type PaymentSubscription = {
  readonly __typename?: 'PaymentSubscription';
  readonly id: Scalars['ID'];
  readonly created: Scalars['Date'];
  readonly updated?: Maybe<Scalars['Date']>;
  readonly amount: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly frequency?: Maybe<SubscriptionFrequencyType>;
  readonly type?: Maybe<SubscriptionType>;
  readonly campaign: Scalars['String'];
};

export type PhoneNumber = {
  readonly __typename?: 'PhoneNumber';
  readonly countryCode: Scalars['String'];
  readonly number: Scalars['String'];
};

export type Premium_CommandResponse = {
  readonly __typename?: 'premium_CommandResponse';
  readonly successful: Scalars['Boolean'];
  readonly errorMessage: Scalars['String'];
};

export type Premium_DebugActivateUserPlanCommand = {
  readonly userId: Scalars['String'];
};

export type Premium_DebugMarkPlanDeletedCommand = {
  readonly userId: Scalars['String'];
};

export type Premium_DebugSyncPlanSubscriptionCommand = {
  readonly userId: Scalars['String'];
  readonly subscriptionId: Scalars['String'];
  readonly publishPlanChanged?: Maybe<Scalars['Boolean']>;
};

export type Premium_McDeletePlanCommand = {
  readonly userId: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Premium_McOrderPlanCommand = {
  readonly userId: Scalars['String'];
  readonly plan: Premium_PlanId;
  readonly promoCode: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type Premium_Plan = {
  readonly __typename?: 'premium_Plan';
  readonly id: Premium_PlanId;
  readonly createdAt: Scalars['String'];
  readonly state: Premium_PlanState;
};

export enum Premium_PlanId {
  Unknown = 'UNKNOWN',
  DkPremium = 'DK_PREMIUM',
  DkPro = 'DK_PRO',
  SePremium = 'SE_PREMIUM',
  SePro = 'SE_PRO',
  NoPremium = 'NO_PREMIUM',
  NoPro = 'NO_PRO',
  DkSolo = 'DK_SOLO',
  DkGrow = 'DK_GROW',
}

export enum Premium_PlanState {
  Available = 'Available',
  Active = 'Active',
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
}

export type Premium_RehydrateCommand = {
  readonly eventId: Scalars['String'];
};

export enum ProgressType {
  /** Still not done being processed */
  Ongoing = 'ONGOING',
  /** Transfer has gone through */
  Success = 'SUCCESS',
  /** Transfer failed */
  Fail = 'FAIL',
}

export type Query = {
  readonly __typename?: 'Query';
  readonly stitching_state: ReadonlyArray<StitchingState>;
  readonly accountmanagement_ping: Scalars['String'];
  readonly accountmanagement_debugAccountOverview: Scalars['String'];
  readonly accountsview_accounts: ReadonlyArray<Accountsview_Account>;
  readonly accountsview_account: Accountsview_Account;
  readonly appsync_ping: Scalars['String'];
  readonly astralis_getProduct?: Maybe<Astralis_Product>;
  readonly bankaccount_getAccountCatalog: ReadonlyArray<Bankaccount_Account>;
  readonly bankaccount_getAccountClosureStatus: Bankaccount_AccountClosureStatus;
  readonly bankaccount_getAccountView: Bankaccount_GetAccountResponse;
  readonly becadapter_lookupAccount?: Maybe<Becadapter_Account>;
  readonly becadapter_checkAccounts?: Maybe<
    ReadonlyArray<Becadapter_CheckAccountResult>
  >;
  readonly billys_billing_getUserInfo?: Maybe<Billys_Billing_UserInfo>;
  readonly brand_brands: ReadonlyArray<Brand_Brand>;
  readonly brand_titles: ReadonlyArray<Brand_Title>;
  readonly brand_lookupCompanyByDomain: Brand_Company;
  readonly brand_categories: Brand_Categories;
  readonly braze_sync_ping: Scalars['String'];
  readonly cardmanagement_getCards: ReadonlyArray<Cardmanagement_Card>;
  readonly cardmanagement_getCardById: Cardmanagement_Card;
  readonly cardmanagement_getEngravings: ReadonlyArray<
    Cardmanagement_Engraving
  >;
  readonly cardmanagement_getEngravingImage: Cardmanagement_EngravingImage;
  readonly cardsettlement_listPlans: Cardsettlement_PlanListResponse;
  readonly cardsettlement_listClearings: Cardsettlement_ClearingResponse;
  readonly cardsettlement_listFTPFiles: ReadonlyArray<Cardsettlement_FileInfo>;
  readonly creditengine_application: Creditengine_ApplicationResponse;
  readonly creditengine_applications: Creditengine_ApplicationsResponse;
  readonly credit_loan_loanInformation: Credit_Loan_LoanInformation;
  readonly credit_loan_loan: Credit_Loan_LoanResponse;
  readonly dinero_getUserInfo?: Maybe<Dinero_UserInfo>;
  readonly directdebit_getBetalingserviceMandates: ReadonlyArray<
    Directdebit_BetalingsserviceMandate
  >;
  readonly directdebit_getBetalingserviceMandate: Directdebit_BetalingsserviceMandate;
  readonly directdebit_getBetalingsserviceInstructions: ReadonlyArray<
    Directdebit_BetalingsserviceInstruction
  >;
  readonly document_acceptedAgreements: ReadonlyArray<Document_Agreement>;
  readonly economic_getUserInfo?: Maybe<Economic_UserInfo>;
  readonly eskat_getRawEskat: Eskat_RawEskatResponse;
  readonly externalaccounts_getConnections: ReadonlyArray<
    Externalaccounts_Connection
  >;
  readonly externalaccounts_getPayments: ReadonlyArray<
    Externalaccounts_Payment
  >;
  readonly externalaccounts_exportPaymentsCsv: Scalars['String'];
  readonly feature_ping: Scalars['String'];
  readonly friend_ping: Scalars['String'];
  readonly goalscore_feedForGoalId: ReadonlyArray<Goalscore_FeedItem>;
  readonly goalscore_goalsForUserId: ReadonlyArray<Goalscore_Goal>;
  readonly goalsrules_getRulesForUser: ReadonlyArray<Maybe<Goalsrules_Rule>>;
  readonly viewings: ReadonlyArray<Maybe<Viewing>>;
  readonly env: Environment;
  readonly users?: Maybe<UserCollection>;
  readonly customers: ReadonlyArray<Customer>;
  readonly customer?: Maybe<Customer>;
  readonly interactions: ReadonlyArray<Maybe<Interaction>>;
  readonly task?: Maybe<Task>;
  /** Search for tags which match all filter conditions provided */
  readonly tasks: ReadonlyArray<Task>;
  readonly searchMessageTemplates: ReadonlyArray<MessageTemplate>;
  readonly messageTemplate?: Maybe<MessageTemplate>;
  /** Returns achievement stats for a specific user */
  readonly achievements: ReadonlyArray<Achievement>;
  /** Returns trophy stats for all users through the given year */
  readonly trophies: ReadonlyArray<Trophy>;
  /** Returns a specific users progress on earning trophies */
  readonly trophyProgress: ReadonlyArray<TrophyProgress>;
  /** View the state of a transfer */
  readonly transferStatus: TransferStatus;
  /** Get transfer status for all transfers matching the input */
  readonly transferStatusList: TransferStatusListPayload;
  /** A list of descriptors for each possible refund type */
  readonly refundDescriptors: RefundDescriptorCollection;
  /** A list of descriptors for each possible charge type */
  readonly chargeDescriptors: ChargeDescriptorCollection;
  /** Get an Intercom user link */
  readonly intercomUserLink?: Maybe<IntercomUserLinkPayload>;
  /** Get count of open Intercom conversations */
  readonly intercomOpenConversationCount?: Maybe<
    IntercomOpenConversationCountPayload
  >;
  readonly insurance_insurancesForUserId: ReadonlyArray<
    Maybe<Insurance_Insurance>
  >;
  readonly insurance_insuranceActivityForId: ReadonlyArray<Insurance_Activity>;
  readonly internationalpayment_ping: Scalars['String'];
  readonly international_payment_api_payments: ReadonlyArray<
    International_Payment_Api_Payment
  >;
  readonly international_payment_api_payment: International_Payment_Api_Payment;
  readonly invest_getClientInfo?: Maybe<Invest_ClientInfo>;
  readonly invest_getUserInfo?: Maybe<Invest_UserInfo>;
  readonly invest_getSaxoInstrument: Invest_SaxoInstrument;
  readonly invest_getSaxoInfoPrice: Invest_SaxoInfoPrice;
  readonly invest_getSaxoExchange: Invest_SaxoExchange;
  readonly invest_getInstrumentInfo: Invest_InstrumentInfo;
  /** This is deprecated. Use the V2 instead as this will only do lookup in the DKLB database */
  readonly kyc_get: ReadonlyArray<Kyc_Kyc>;
  readonly kyc_getV2: ReadonlyArray<Kyc_Kyc>;
  readonly kyc_bootstrapEvents: Kyc_BootstrapResponse;
  readonly migration_migrationStats: Migration_MigrationStats;
  readonly migration_migrationStateStats: ReadonlyArray<
    Migration_MigrationStateStats
  >;
  readonly migration_migratorStateStats: ReadonlyArray<
    Migration_MigratorStateStats
  >;
  readonly migration_bsState: Migration_BsState;
  readonly migration_engineState: Migration_EngineState;
  readonly migration_migrations: Migration_FilteredMigrations;
  readonly migration_migration?: Maybe<Migration_Migration>;
  readonly migration_migrationStateHistory: Migration_MigrationStateHistory;
  readonly migration_migrationStateTransitions: ReadonlyArray<
    Migration_MigrationStateTransition
  >;
  readonly migration_migratorResults: ReadonlyArray<Migration_MigratorResult>;
  readonly migration_executorStateTransitions: ReadonlyArray<
    Migration_ExecutorStateTransition
  >;
  readonly migration_recentTransitions: ReadonlyArray<
    Migration_MigrationStateTransition
  >;
  readonly migration_biz_migrationStats: Migration_Biz_MigrationStats;
  readonly migration_biz_migrationStateStats: ReadonlyArray<
    Migration_Biz_MigrationStateStats
  >;
  readonly migration_biz_migratorStateStats: ReadonlyArray<
    Migration_Biz_MigratorStateStats
  >;
  readonly migration_biz_bsState: Migration_Biz_BsState;
  readonly migration_biz_engineState: Migration_Biz_EngineState;
  readonly migration_biz_migrations: Migration_Biz_FilteredMigrations;
  readonly migration_biz_migration?: Maybe<Migration_Biz_Migration>;
  readonly migration_biz_migrationStateHistory: Migration_Biz_MigrationStateHistory;
  readonly migration_biz_migrationStateTransitions: ReadonlyArray<
    Migration_Biz_MigrationStateTransition
  >;
  readonly migration_biz_migratorResults: ReadonlyArray<
    Migration_Biz_MigratorResult
  >;
  readonly migration_biz_executorStateTransitions: ReadonlyArray<
    Migration_Biz_ExecutorStateTransition
  >;
  readonly migration_biz_recentTransitions: ReadonlyArray<
    Migration_Biz_MigrationStateTransition
  >;
  readonly nets3dsacs_ping: Scalars['String'];
  readonly offboarding_ping: Scalars['String'];
  readonly onboarding_users: Onboarding_UsersPayload;
  /** This is deprecated. Use the V2 instead as this will only do lookup in the DKLB database */
  readonly onboarding_user: Onboarding_UserPayload;
  readonly onboarding_userV2: Onboarding_UserPayload;
  readonly onboarding_countries: Onboarding_CountryPayload;
  readonly paymentlimit_accountViewInfo: Paymentlimit_AccountViewInfo;
  readonly premium_getPlan: ReadonlyArray<Premium_Plan>;
  readonly premium_userPlan?: Maybe<Premium_Plan>;
  readonly signup_getSignups?: Maybe<Signup_SignupResponse>;
  readonly subaio_getPayments: ReadonlyArray<Subaio_Payment>;
  readonly subaio_getSubscriptions: ReadonlyArray<Subaio_PaymentSubscription>;
  readonly subaio_getJWT?: Maybe<Scalars['String']>;
  readonly subaio_getConfig: Subaio_UserConfig;
  readonly subscription_user?: Maybe<Subscription_User>;
  readonly subscription_subscription?: Maybe<Subscription_SubscriptionModel>;
  readonly subscription_invoice?: Maybe<Subscription_Invoice>;
  readonly subscription_plans: ReadonlyArray<Subscription_Plan>;
  readonly subscription_expiredSubscriptions: Subscription_ExpiredSubscriptions;
  readonly tdschallenge_ping: Scalars['String'];
  readonly tink_user?: Maybe<Tink_User>;
  readonly tink_availableProviders: ReadonlyArray<Tink_Provider>;
  readonly tink_transfers: ReadonlyArray<Tink_Transfer>;
  readonly topupv2_getLimitsForUserID: Topupv2_LimitInfo;
  readonly transactions: ReadonlyArray<Transaction>;
  readonly exportCsv: Scalars['String'];
  readonly exportCustomerPDF: Scalars['String'];
  readonly transaction: Transaction;
  readonly debugSearch: ReadonlyArray<Transaction>;
  readonly debugTransactions: ReadonlyArray<Transaction>;
  readonly transactioncore_Ping: Scalars['String'];
  readonly transactionlabel_ping: Scalars['Boolean'];
  readonly transactionprocessing_ping: Scalars['Boolean'];
  readonly transactionusercontent_ping: Scalars['String'];
  readonly transfer_transferRequestStatus: Transfer_TransferRequestStatus;
  readonly transfer_lookupBankBySortcode: Transfer_Bank;
  readonly user_details_update_Ping: Scalars['String'];
  readonly userfile_getRequestsForUser: ReadonlyArray<Userfile_Request>;
  readonly userfile_getRequest: Userfile_Request;
  readonly userfile_getCategories: ReadonlyArray<Userfile_Category>;
};

export type QueryAccountmanagement_DebugAccountOverviewArgs = {
  userId: Scalars['String'];
};

export type QueryAccountsview_AccountsArgs = {
  userId: Scalars['String'];
};

export type QueryAccountsview_AccountArgs = {
  userId: Scalars['String'];
  accountId: Scalars['String'];
};

export type QueryAstralis_GetProductArgs = {
  userId: Scalars['String'];
};

export type QueryBankaccount_GetAccountCatalogArgs = {
  userId: Scalars['String'];
};

export type QueryBankaccount_GetAccountClosureStatusArgs = {
  accountId: Scalars['String'];
};

export type QueryBankaccount_GetAccountViewArgs = {
  accountId: Scalars['String'];
};

export type QueryBecadapter_LookupAccountArgs = {
  input: Becadapter_AccountReq;
};

export type QueryBecadapter_CheckAccountsArgs = {
  input: Becadapter_CheckAccountsInput;
};

export type QueryBillys_Billing_GetUserInfoArgs = {
  userId: Scalars['String'];
};

export type QueryBrand_BrandsArgs = {
  brandIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type QueryBrand_TitlesArgs = {
  filter: Brand_TitlesFilter;
  pageSize: Scalars['Int'];
  offset: Scalars['Int'];
  searchTerm?: Maybe<Scalars['String']>;
  origin?: Maybe<Brand_Origin>;
};

export type QueryBrand_LookupCompanyByDomainArgs = {
  domain: Scalars['String'];
};

export type QueryCardmanagement_GetCardsArgs = {
  userId: Scalars['String'];
};

export type QueryCardmanagement_GetCardByIdArgs = {
  userId: Scalars['String'];
  cardId: Scalars['String'];
};

export type QueryCardmanagement_GetEngravingsArgs = {
  filter: Cardmanagement_EngravingFilter;
};

export type QueryCardmanagement_GetEngravingImageArgs = {
  id: Scalars['String'];
};

export type QueryCardsettlement_ListPlansArgs = {
  query?: Maybe<Cardsettlement_ListPlansQuery>;
};

export type QueryCardsettlement_ListClearingsArgs = {
  query: Cardsettlement_ClearingQuery;
};

export type QueryCreditengine_ApplicationArgs = {
  input: Creditengine_ApplicationInput;
};

export type QueryCreditengine_ApplicationsArgs = {
  input: Creditengine_UserInput;
};

export type QueryCredit_Loan_LoanInformationArgs = {
  input: Credit_Loan_LoanInformationInput;
};

export type QueryCredit_Loan_LoanArgs = {
  input: Credit_Loan_LoanInput;
};

export type QueryDinero_GetUserInfoArgs = {
  userId: Scalars['String'];
};

export type QueryDirectdebit_GetBetalingserviceMandatesArgs = {
  userId: Scalars['String'];
};

export type QueryDirectdebit_GetBetalingserviceMandateArgs = {
  userId: Scalars['String'];
  mandateId: Scalars['String'];
};

export type QueryDirectdebit_GetBetalingsserviceInstructionsArgs = {
  input: Directdebit_GetBetalingsserviceInstructionsInput;
};

export type QueryDocument_AcceptedAgreementsArgs = {
  userId: Scalars['ID'];
};

export type QueryEconomic_GetUserInfoArgs = {
  userId: Scalars['String'];
};

export type QueryEskat_GetRawEskatArgs = {
  input: Eskat_RawEskatInput;
};

export type QueryExternalaccounts_GetConnectionsArgs = {
  userId: Scalars['String'];
};

export type QueryExternalaccounts_GetPaymentsArgs = {
  userId: Scalars['String'];
};

export type QueryExternalaccounts_ExportPaymentsCsvArgs = {
  input: Externalaccounts_ExportPaymentsCsvInput;
};

export type QueryGoalscore_FeedForGoalIdArgs = {
  goalId: Scalars['String'];
};

export type QueryGoalscore_GoalsForUserIdArgs = {
  userId: Scalars['String'];
};

export type QueryGoalsrules_GetRulesForUserArgs = {
  userId: Scalars['String'];
};

export type QueryCustomersArgs = {
  phone: Scalars['String'];
};

export type QueryCustomerArgs = {
  customerId: Scalars['String'];
};

export type QueryInteractionsArgs = {
  phone: Scalars['String'];
};

export type QueryTaskArgs = {
  taskId: Scalars['ID'];
};

export type QueryTasksArgs = {
  customerId?: Maybe<Scalars['ID']>;
  context?: Maybe<TaskContext>;
  types?: Maybe<ReadonlyArray<TaskType>>;
  tag?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type QuerySearchMessageTemplatesArgs = {
  query: SearchMessageTemplateInput;
};

export type QueryMessageTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryAchievementsArgs = {
  userId?: Maybe<Scalars['String']>;
};

export type QueryTrophiesArgs = {
  year: Scalars['Int'];
};

export type QueryTrophyProgressArgs = {
  userId: Scalars['ID'];
};

export type QueryTransferStatusArgs = {
  id: Scalars['ID'];
};

export type QueryTransferStatusListArgs = {
  input: TransferStatusListInput;
};

export type QueryIntercomUserLinkArgs = {
  input: IntercomUserLinkInput;
};

export type QueryIntercomOpenConversationCountArgs = {
  input: IntercomOpenConversationCountInput;
};

export type QueryInsurance_InsurancesForUserIdArgs = {
  userId: Scalars['String'];
};

export type QueryInsurance_InsuranceActivityForIdArgs = {
  insuranceId: Scalars['String'];
};

export type QueryInternational_Payment_Api_PaymentsArgs = {
  input: International_Payment_Api_PaymentsInput;
};

export type QueryInternational_Payment_Api_PaymentArgs = {
  id: Scalars['ID'];
};

export type QueryInvest_GetClientInfoArgs = {
  clientId: Scalars['String'];
};

export type QueryInvest_GetUserInfoArgs = {
  userId: Scalars['String'];
};

export type QueryInvest_GetSaxoInstrumentArgs = {
  uic: Scalars['String'];
  assetType?: Maybe<Scalars['String']>;
};

export type QueryInvest_GetSaxoInfoPriceArgs = {
  uic: Scalars['String'];
  assetType?: Maybe<Scalars['String']>;
};

export type QueryInvest_GetSaxoExchangeArgs = {
  exchangeId: Scalars['String'];
};

export type QueryInvest_GetInstrumentInfoArgs = {
  uic: Scalars['String'];
};

export type QueryKyc_GetArgs = {
  userId: Scalars['String'];
};

export type QueryKyc_GetV2Args = {
  input: Kyc_UserInput;
};

export type QueryKyc_BootstrapEventsArgs = {
  input: Kyc_BootstrapInput;
};

export type QueryMigration_MigrationsArgs = {
  filter?: Maybe<Migration_MigrationsFilter>;
};

export type QueryMigration_MigrationArgs = {
  id: Scalars['ID'];
};

export type QueryMigration_MigrationStateHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryMigration_MigrationStateTransitionsArgs = {
  id: Scalars['ID'];
};

export type QueryMigration_MigratorResultsArgs = {
  migrationId: Scalars['ID'];
};

export type QueryMigration_Biz_MigrationsArgs = {
  filter?: Maybe<Migration_Biz_MigrationsFilter>;
};

export type QueryMigration_Biz_MigrationArgs = {
  id: Scalars['ID'];
};

export type QueryMigration_Biz_MigrationStateHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryMigration_Biz_MigrationStateTransitionsArgs = {
  id: Scalars['ID'];
};

export type QueryMigration_Biz_MigratorResultsArgs = {
  migrationId: Scalars['ID'];
};

export type QueryOnboarding_UsersArgs = {
  input: Onboarding_UsersInput;
};

export type QueryOnboarding_UserArgs = {
  userId: Scalars['ID'];
};

export type QueryOnboarding_UserV2Args = {
  input: Onboarding_UserInput;
};

export type QueryPaymentlimit_AccountViewInfoArgs = {
  accountID: Scalars['String'];
};

export type QueryPremium_GetPlanArgs = {
  userId: Scalars['String'];
};

export type QueryPremium_UserPlanArgs = {
  userId: Scalars['String'];
};

export type QuerySignup_GetSignupsArgs = {
  searchTerm: Scalars['String'];
};

export type QuerySubaio_GetPaymentsArgs = {
  userId: Scalars['String'];
};

export type QuerySubaio_GetSubscriptionsArgs = {
  userId: Scalars['String'];
};

export type QuerySubaio_GetJwtArgs = {
  userId: Scalars['String'];
};

export type QuerySubaio_GetConfigArgs = {
  userId: Scalars['String'];
};

export type QuerySubscription_UserArgs = {
  id: Scalars['String'];
};

export type QuerySubscription_SubscriptionArgs = {
  userId: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type QuerySubscription_InvoiceArgs = {
  userId: Scalars['String'];
  invoiceId: Scalars['String'];
};

export type QuerySubscription_PlansArgs = {
  type: Subscription_SubscriptionType;
};

export type QueryTink_UserArgs = {
  userId: Scalars['String'];
};

export type QueryTink_AvailableProvidersArgs = {
  userId: Scalars['String'];
};

export type QueryTink_TransfersArgs = {
  userId: Scalars['String'];
};

export type QueryTopupv2_GetLimitsForUserIdArgs = {
  userId: Scalars['String'];
};

export type QueryTransactionsArgs = {
  filter?: Maybe<TransactionFilter>;
};

export type QueryExportCsvArgs = {
  userId: Scalars['String'];
  originType: OriginType;
  originGroupID?: Maybe<Scalars['String']>;
};

export type QueryExportCustomerPdfArgs = {
  userId: Scalars['String'];
  accountId: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
};

export type QueryTransactionArgs = {
  id: Scalars['ID'];
};

export type QueryDebugSearchArgs = {
  query: Scalars['String'];
};

export type QueryDebugTransactionsArgs = {
  filter: TransactionV2Filter;
};

export type QueryTransfer_TransferRequestStatusArgs = {
  requestId: Scalars['ID'];
};

export type QueryTransfer_LookupBankBySortcodeArgs = {
  sortCode: Scalars['String'];
};

export type QueryUserfile_GetRequestsForUserArgs = {
  userId: Scalars['ID'];
};

export type QueryUserfile_GetRequestArgs = {
  id: Scalars['ID'];
};

export type ReasonInput = {
  /** Message template alias for the reason */
  readonly alias: Scalars['String'];
  /** Text content for the reason */
  readonly message: Scalars['String'];
};

export type RefundDescriptor = {
  readonly __typename?: 'RefundDescriptor';
  readonly id: Scalars['ID'];
  readonly type: RefundType;
  readonly defaultTransactionTitle?: Maybe<Scalars['String']>;
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly currency: Scalars['Currency'];
  readonly label: Scalars['String'];
  readonly description: Scalars['String'];
  /** The bank relation */
  readonly relations: ReadonlyArray<Relation>;
};

export type RefundDescriptorCollection = {
  readonly __typename?: 'RefundDescriptorCollection';
  readonly count: Scalars['Int'];
  readonly nodes: ReadonlyArray<RefundDescriptor>;
};

export type RefundInput = {
  readonly type: RefundType;
  /** Id of the customer to refund to */
  readonly customerId: Scalars['ID'];
  /** Id of the user account to refund to */
  readonly accountId: Scalars['ID'];
  /** Funds refunded in account currency */
  readonly amount: Scalars['Decimal'];
  /** The currency in the ISO-4217 currency code format. */
  readonly currency: Scalars['String'];
  /** What to display in the user's transaction list - uses a default for each refund type if not set */
  readonly transactionTitle?: Maybe<Scalars['String']>;
  /** Documentation for initiating the refund */
  readonly reason: ReasonInput;
};

export type RefundPayload = {
  readonly __typename?: 'RefundPayload';
  /** Populated with reason if the refund failed immediately */
  readonly error?: Maybe<Scalars['String']>;
  /** Transfer status of the refund */
  readonly status: TransferStatus;
};

/** The type of refund for a system-to-user accounting transaction */
export enum RefundType {
  /** Withdrawal fee ATM: For an ATM withdrawal fee */
  RefundSelbWitdhrawalFee = 'REFUND_SELB_WITDHRAWAL_FEE',
  /** Multiple account: One month of an extra account */
  RefundSelbExtraAccountMonthly = 'REFUND_SELB_EXTRA_ACCOUNT_MONTHLY',
  /** Pro subscription: One month of Pro subscription */
  RefundSelbProSubscriptionMonthly = 'REFUND_SELB_PRO_SUBSCRIPTION_MONTHLY',
  /** Premium subscription: One month of Premium subscription */
  RefundSelbPremiumSubscriptionMonthly = 'REFUND_SELB_PREMIUM_SUBSCRIPTION_MONTHLY',
  /** Replacement card - Pro: For a Pro replacement card */
  RefundSelbReplacementCardPro = 'REFUND_SELB_REPLACEMENT_CARD_PRO',
  /** Replacement card: For a normal replacement card */
  RefundSelbReplacementCard = 'REFUND_SELB_REPLACEMENT_CARD',
  /** Service fee: Service fee 2 for minus on account   */
  RefundSelbServiceFeeTwo = 'REFUND_SELB_SERVICE_FEE_TWO',
  /** Apple Pay incentives: Give money back due to apple pay incentives */
  RefundSelbApplePayIncentives = 'REFUND_SELB_APPLE_PAY_INCENTIVES',
  /** Service Fee 2: Service fee from second reminder */
  RefundServiceFeeTwo = 'REFUND_SERVICE_FEE_TWO',
  /** Service Fee BS: Service fee from BS */
  RefundServiceFeeBs = 'REFUND_SERVICE_FEE_BS',
  /** Chargeback: E.g. a product has not arrived */
  RefundChargeback = 'REFUND_CHARGEBACK',
  /** Replacement card fee: Free replacement card */
  RefundReplacementCardFee = 'REFUND_REPLACEMENT_CARD_FEE',
  /** Premium subscription: Free month for a premium subscription */
  RefundMonthlyPremiumFee = 'REFUND_MONTHLY_PREMIUM_FEE',
  /** Multiple account: Refund one month of an extra account */
  RefundExtraAccountMonthly = 'REFUND_EXTRA_ACCOUNT_MONTHLY',
  /** Withdrawal fee: Refund a withdrawal fee */
  RefundWithdrawalFee = 'REFUND_WITHDRAWAL_FEE',
  /** Overdraft provision: Refund an overdraft provision */
  RefundOverdraftProvision = 'REFUND_OVERDRAFT_PROVISION',
  /** Stock brokerage: Refund a stock brokerage */
  RefundStockBrokerage = 'REFUND_STOCK_BROKERAGE',
  /** Pro subscription: Refund a month of pro subscription */
  RefundProSubscriptionMonthly = 'REFUND_PRO_SUBSCRIPTION_MONTHLY',
  /** Visa debit fee: Refund a wrongly charged Visa debit fee */
  RefundVisaDebit = 'REFUND_VISA_DEBIT',
  /** Annual business subscription fee: 12 months subscription fee. */
  RefundDklbbSubscriptionAnnual = 'REFUND_DKLBB_SUBSCRIPTION_ANNUAL',
  /** Service fee for business subscription payment: For missing a business subscription payment. */
  RefundDklbbSubscriptionMissedPayment = 'REFUND_DKLBB_SUBSCRIPTION_MISSED_PAYMENT',
  /** FI card creation fee: For the creation of an FI card ("fælles indbetalingskort"). */
  RefundDklbbFiCreate = 'REFUND_DKLBB_FI_CREATE',
  /** Existing FI move fee: For the move of an existing FI-creditor. */
  RefundDklbbFiMove = 'REFUND_DKLBB_FI_MOVE',
  /** Produce and deliver FI fee: For producing and delivering an FI card ("fælles indbetalingskort"). */
  RefundDklbbFiProduceAndDeliver = 'REFUND_DKLBB_FI_PRODUCE_AND_DELIVER',
}

export enum Relation {
  Dkka = 'dkka',
  Dknb = 'dknb',
  Dknbb = 'dknbb',
  Sepp = 'sepp',
  Nopp = 'nopp',
  Dklb = 'dklb',
  Dklbb = 'dklbb',
  Selb = 'selb',
  Nolb = 'nolb',
}

export type Role = {
  readonly __typename?: 'Role';
  readonly name: RoleName;
};

export type RoleCollection = {
  readonly __typename?: 'RoleCollection';
  readonly count: Scalars['Int'];
  readonly nodes: ReadonlyArray<Role>;
};

export enum RoleName {
  SquadVoyager = 'SquadVoyager',
  SquadApollo = 'SquadApollo',
  SquadArtemis = 'SquadArtemis',
  SquadMercury = 'SquadMercury',
  SquadGemini = 'SquadGemini',
  SquadNasa = 'SquadNasa',
  SquadSkylab = 'SquadSkylab',
  SquadOrion = 'SquadOrion',
  Supporter = 'Supporter',
  Developer = 'Developer',
  Partner = 'Partner',
  Businesspartner = 'Businesspartner',
  Brands = 'Brands',
  UserManager = 'UserManager',
  Aml = 'Aml',
  Tagging = 'Tagging',
  PrivilegedSupporter = 'PrivilegedSupporter',
  Migrator = 'Migrator',
  LegacyApprove = 'LegacyApprove',
  InternalSupporter = 'InternalSupporter',
  Hr = 'HR',
}

export type SearchMessageTemplateInput = {
  readonly category?: Maybe<MessageTemplateCategory>;
  readonly alias?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['String']>;
  readonly disabled?: Maybe<Scalars['Boolean']>;
};

export type Settings = {
  readonly __typename?: 'Settings';
  readonly navigationIsCollapsed: Scalars['Boolean'];
  readonly lastSelectedContextId?: Maybe<LastSelectedContextId>;
  readonly theme: UserTheme;
};

export type SettingsInput = {
  readonly navigationIsCollapsed?: Maybe<Scalars['Boolean']>;
  readonly lastSelectedContextId?: Maybe<LastSelectedContextId>;
  readonly theme?: Maybe<UserTheme>;
};

export type Signup_Device = {
  readonly __typename?: 'signup_Device';
  readonly appVersion?: Maybe<Scalars['String']>;
  readonly deviceModel?: Maybe<Scalars['String']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly os?: Maybe<Scalars['String']>;
  readonly osVersion?: Maybe<Scalars['String']>;
};

export type Signup_DknbApproval = {
  readonly __typename?: 'signup_DknbApproval';
  readonly bankIdentifier?: Maybe<Scalars['String']>;
  readonly accountNumber?: Maybe<Scalars['String']>;
  readonly supporterEmail?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Signup_Message>;
};

export type Signup_DknbbApproval = {
  readonly __typename?: 'signup_DknbbApproval';
  readonly bankIdentifier?: Maybe<Scalars['String']>;
  readonly accountNumber?: Maybe<Scalars['String']>;
  readonly supporterEmail?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Signup_Message>;
};

export type Signup_DknbbCompany = {
  readonly __typename?: 'signup_DknbbCompany';
  readonly cvr?: Maybe<Scalars['String']>;
  readonly companyName?: Maybe<Scalars['String']>;
  readonly companyForm?: Maybe<Scalars['String']>;
  readonly industryCode?: Maybe<Scalars['String']>;
};

export type Signup_DknbbSpecificInfo = {
  readonly __typename?: 'signup_DknbbSpecificInfo';
  readonly accountCreated?: Maybe<Scalars['Boolean']>;
  readonly approval?: Maybe<Signup_DknbbApproval>;
  readonly company?: Maybe<Signup_DknbbCompany>;
  readonly cpr?: Maybe<Scalars['String']>;
  readonly cvrCprRelationVerified?: Maybe<Scalars['Boolean']>;
  readonly prepaidCardCreated?: Maybe<Scalars['Boolean']>;
};

export type Signup_DknbSpecificInfo = {
  readonly __typename?: 'signup_DknbSpecificInfo';
  readonly approval?: Maybe<Signup_DknbApproval>;
  readonly cpr?: Maybe<Scalars['String']>;
};

export type Signup_Message = {
  readonly __typename?: 'signup_Message';
  readonly alias?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
};

export type Signup_PhoneNumber = {
  readonly __typename?: 'signup_PhoneNumber';
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly number?: Maybe<Scalars['String']>;
};

export type Signup_Redo = {
  readonly __typename?: 'signup_Redo';
  readonly reason?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Signup_Message>;
  readonly supporterEmail?: Maybe<Scalars['String']>;
};

export type Signup_Rejection = {
  readonly __typename?: 'signup_Rejection';
  readonly reason?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Signup_Message>;
  readonly supporterEmail?: Maybe<Scalars['String']>;
};

export type Signup_RejectResponse = {
  readonly __typename?: 'signup_RejectResponse';
  readonly signupId: Scalars['String'];
  readonly rejectionCompleted: Scalars['Boolean'];
  readonly error?: Maybe<Scalars['String']>;
};

export type Signup_Signup = {
  readonly __typename?: 'signup_Signup';
  readonly id: Scalars['String'];
  readonly userId: Scalars['String'];
  readonly relation: Scalars['String'];
  readonly state: Signup_State;
  readonly stateIndication: Signup_StateIndication;
  readonly created: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly rejectable: Scalars['Boolean'];
  readonly email?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Signup_PhoneNumber>;
  readonly device?: Maybe<Signup_Device>;
  readonly redo?: Maybe<Signup_Redo>;
  readonly rejection?: Maybe<Signup_Rejection>;
  readonly dknb?: Maybe<Signup_DknbSpecificInfo>;
  readonly dknbb?: Maybe<Signup_DknbbSpecificInfo>;
};

export type Signup_SignupResponse = {
  readonly __typename?: 'signup_SignupResponse';
  readonly signups?: Maybe<ReadonlyArray<Maybe<Signup_Signup>>>;
};

export enum Signup_State {
  Apply = 'apply',
  Wait = 'wait',
  Redo = 'redo',
  Approved = 'approved',
  ApprovedError = 'approved_error',
  Sign = 'sign',
  SignRedo = 'sign_redo',
  Rejected = 'rejected',
  Accepted = 'accepted',
  Closed = 'closed',
}

export enum Signup_StateIndication {
  Success = 'success',
  Neutral = 'neutral',
  Error = 'error',
}

export enum SpecialTransferType {
  SpecialTransferTypeMigrationIn = 'SpecialTransferTypeMigrationIn',
  SpecialTransferTypeMigrationOut = 'SpecialTransferTypeMigrationOut',
  SpecialTransferTypeCredit10K = 'SpecialTransferTypeCredit10K',
  SpecialTransferTypeCredit10KFirst = 'SpecialTransferTypeCredit10KFirst',
  SpecialTransferTypeCredit10KCancel = 'SpecialTransferTypeCredit10KCancel',
  SpecialTransferTypeCredit10KDown = 'SpecialTransferTypeCredit10KDown',
  SpecialTransferTypeCredit20K = 'SpecialTransferTypeCredit20K',
  SpecialTransferTypeCredit20KFirst = 'SpecialTransferTypeCredit20KFirst',
  SpecialTransferTypeCredit20KCancel = 'SpecialTransferTypeCredit20KCancel',
  SpecialTransferTypeCredit20KUp = 'SpecialTransferTypeCredit20KUp',
  SpecialTransferTypeTopUp = 'SpecialTransferTypeTopUp',
  SpecialTransferTypePrepaidTopUp = 'SpecialTransferTypePrepaidTopUp',
  SpecialTransferTypePrepaidWithdrawal = 'SpecialTransferTypePrepaidWithdrawal',
  SpecialTransferTypePrepaidReplacement = 'SpecialTransferTypePrepaidReplacement',
  SpecialTransferTypePrepaidNew = 'SpecialTransferTypePrepaidNew',
  SpecialTransferTypeInternationalTransfer = 'SpecialTransferTypeInternationalTransfer',
  SpecialTransferTypeInternationalTransferRefund = 'SpecialTransferTypeInternationalTransferRefund',
  SpecialTransferTypeReferral = 'SpecialTransferTypeReferral',
}

export type StitchingState = {
  readonly __typename?: 'StitchingState';
  readonly serviceName: Scalars['String'];
  readonly successful: Scalars['Boolean'];
  readonly updatedAt?: Maybe<Scalars['String']>;
  readonly error?: Maybe<Scalars['String']>;
};

export type Subaio_BootstrapUsersResults = {
  readonly __typename?: 'subaio_BootstrapUsersResults';
  readonly success: Scalars['Boolean'];
  readonly failedUserIds: ReadonlyArray<Scalars['String']>;
};

export type Subaio_Payment = {
  readonly __typename?: 'subaio_Payment';
  readonly serviceId: Scalars['String'];
  readonly expectedPaymentDate: Scalars['String'];
  readonly expectedPaymentCost: Scalars['Float'];
  readonly currency: Scalars['String'];
  readonly expectedPaymentCostInBaseCurrency: Scalars['Float'];
  readonly baseCurrency: Scalars['String'];
  readonly accountId: Scalars['String'];
  readonly cardId: Scalars['String'];
  readonly title: Scalars['String'];
  readonly imageUrl: Scalars['String'];
};

export type Subaio_PaymentSubscription = {
  readonly __typename?: 'subaio_PaymentSubscription';
  readonly id: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly accountId: Scalars['String'];
  readonly cardId: Scalars['String'];
  readonly title: Scalars['String'];
  readonly imageUrl: Scalars['String'];
  readonly status: Subaio_StatusType;
  readonly userRequiredActions?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly expectedPaymentDate: Scalars['String'];
  readonly expectedPaymentCost: Scalars['String'];
};

export enum Subaio_StatusType {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  UnderCancellation = 'UNDER_CANCELLATION',
  Cancelled = 'CANCELLED',
  Unknown = 'UNKNOWN',
}

export type Subaio_UserConfig = {
  readonly __typename?: 'subaio_UserConfig';
  readonly Url: Scalars['String'];
  readonly Token: Scalars['String'];
};

export type Subscription = {
  readonly __typename?: 'Subscription';
  readonly viewings?: Maybe<ReadonlyArray<Maybe<Viewing>>>;
};

export type Subscription_AccountInfo = {
  readonly __typename?: 'subscription_AccountInfo';
  readonly iban: Scalars['String'];
  readonly bban: Scalars['String'];
};

export type Subscription_AccountPaymentMethod = {
  readonly __typename?: 'subscription_AccountPaymentMethod';
  readonly id: Scalars['String'];
  readonly created: Scalars['String'];
  readonly creditorName: Scalars['String'];
  readonly debtorAccount?: Maybe<Subscription_AccountInfo>;
  readonly creditorAccount?: Maybe<Subscription_AccountInfo>;
};

export type Subscription_Action = {
  readonly __typename?: 'subscription_Action';
  readonly created: Scalars['String'];
  readonly identifier: Subscription_ActionIdentifier;
  readonly message: Scalars['String'];
};

export enum Subscription_ActionIdentifier {
  Markpaid = 'markpaid',
  Recharge = 'recharge',
  Canceled = 'canceled',
}

export type Subscription_CardPaymentMethod = {
  readonly __typename?: 'subscription_CardPaymentMethod';
  readonly id: Scalars['String'];
  readonly created: Scalars['String'];
  readonly paylikeId: Scalars['String'];
  readonly bin: Scalars['String'];
  readonly lastFour: Scalars['String'];
  readonly expiry?: Maybe<Scalars['String']>;
};

export type Subscription_ChangeToAccountPaymentMethodInput = {
  readonly userId: Scalars['String'];
  readonly subscriptionId: Scalars['String'];
};

export type Subscription_ExpiredSubscriptions = {
  readonly __typename?: 'subscription_ExpiredSubscriptions';
  readonly users?: Maybe<ReadonlyArray<Subscription_User>>;
  readonly total: Scalars['Int'];
};

export enum Subscription_Frequency {
  Daily = 'daily',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export type Subscription_HoldSubscriptionInput = {
  readonly userId: Scalars['String'];
  readonly subscriptionId: Scalars['String'];
};

export type Subscription_HoldSubscriptionPayload = {
  readonly __typename?: 'subscription_HoldSubscriptionPayload';
  readonly userId?: Maybe<Scalars['String']>;
};

export type Subscription_Invoice = {
  readonly __typename?: 'subscription_Invoice';
  readonly id: Scalars['String'];
  readonly invoiceNumber: Scalars['String'];
  readonly invoiceDate: Scalars['String'];
  readonly dueDate: Scalars['String'];
  readonly invoiceStatus: Subscription_InvoiceStatus;
  readonly paymentStatus: Subscription_InvoicePaymentStatus;
  readonly paymentDate?: Maybe<Scalars['String']>;
  readonly totalExclVat: Scalars['Float'];
  readonly totalInclVat: Scalars['Float'];
  readonly currencyCode: Scalars['String'];
  readonly fileId?: Maybe<Scalars['String']>;
  readonly transactions: ReadonlyArray<Subscription_Transaction>;
  readonly actions: ReadonlyArray<Subscription_Action>;
  readonly subscription: Subscription_SubscriptionModel;
  readonly user: Subscription_User;
};

export enum Subscription_InvoicePaymentStatus {
  Paid = 'paid',
  Cancelled = 'cancelled',
  PendingResponse = 'pending_response',
  Failed = 'failed',
  Declined = 'declined',
  PendingPayment = 'pending_payment',
  NotPaid = 'not_paid',
  PendingRetry = 'pending_retry',
  Dunning = 'dunning',
}

export enum Subscription_InvoiceStatus {
  Draft = 'draft',
  Booked = 'booked',
  Cancelled = 'cancelled',
  Failed = 'failed',
  PendingBooked = 'pending_booked',
  CollativeDraft = 'collative_draft',
}

export type Subscription_PaymentMethod =
  | Subscription_CardPaymentMethod
  | Subscription_AccountPaymentMethod;

export type Subscription_Plan = {
  readonly __typename?: 'subscription_Plan';
  readonly planIdentifier: Subscription_ProductPlanIdentifier;
  readonly campaign?: Maybe<Scalars['String']>;
  readonly campaignEndDate?: Maybe<Scalars['String']>;
  readonly amount: Scalars['Float'];
  readonly currency: Scalars['String'];
};

export enum Subscription_ProductPlanIdentifier {
  Unknown = 'unknown',
  BusinessBetaDaily = 'business_beta_daily',
  BusinessCampaign_201808Daily = 'business_campaign_201808_daily',
  BusinessBetaMonthly = 'business_beta_monthly',
  BusinessBetaYearly = 'business_beta_yearly',
  BusinessMonthly = 'business_monthly',
  BusinessYearly = 'business_yearly',
  BusinessDklbYearly = 'business_dklb_yearly',
  BusinessDklb_10Yearly = 'business_dklb_10_yearly',
  BusinessCampaign_201808Monthly = 'business_campaign_201808_monthly',
  BusinessCampaign_201808Yearly = 'business_campaign_201808_yearly',
  BusinessCampaign_201909Yearly = 'business_campaign_201909_yearly',
  BusinessCampaign_201910Yearly = 'business_campaign_201910_yearly',
  TravelCardYearly = 'travel_card_yearly',
  BusinessCardYearly = 'business_card_yearly',
  MulticurrencyCardSeYearly = 'multicurrency_card_se_yearly',
  MulticurrencyCardNoYearly = 'multicurrency_card_no_yearly',
  InsuranceTravelEurope = 'insurance_travel_europe',
  InsuranceTravelWorld = 'insurance_travel_world',
  InsuranceLbTravelEurope = 'insurance_lb_travel_europe',
  InsuranceLbTravelWorld = 'insurance_lb_travel_world',
  PremiumDkMonthly = 'premium_dk_monthly',
  PremiumDkCampaignReducedExpirationMonthly = 'premium_dk_campaign_reduced_expiration_monthly',
  PremiumSeMonthly = 'premium_se_monthly',
  PremiumNoMonthly = 'premium_no_monthly',
  ProDkMonthly = 'pro_dk_monthly',
  ProSeMonthly = 'pro_se_monthly',
  ProNoMonthly = 'pro_no_monthly',
  DklbExtraAccount = 'dklb_extra_account',
  SelbExtraAccount = 'selb_extra_account',
  NolbExtraAccount = 'nolb_extra_account',
  OverdraftDkLegacy_10k = 'overdraft_dk_legacy_10k',
  OverdraftDkLegacy_20k = 'overdraft_dk_legacy_20k',
  BufferDk_3k = 'buffer_dk_3k',
  AstralisDk = 'astralis_dk',
}

export type Subscription_PublishSubscriptionDeletedInput = {
  readonly subscriptions?: Maybe<
    ReadonlyArray<Subscription_SubscriptionForDeletion>
  >;
  readonly dryRun: Scalars['Boolean'];
};

export type Subscription_PublishSubscriptionDeletedPayload = {
  readonly __typename?: 'subscription_PublishSubscriptionDeletedPayload';
  readonly success: Scalars['Boolean'];
};

export type Subscription_ResumeSubscriptionInput = {
  readonly userId: Scalars['String'];
  readonly subscriptionId: Scalars['String'];
};

export type Subscription_ResumeSubscriptionPayload = {
  readonly __typename?: 'subscription_ResumeSubscriptionPayload';
  readonly userId?: Maybe<Scalars['String']>;
};

export type Subscription_SetNextChargeDateInput = {
  readonly userId: Scalars['String'];
  readonly subscriptionId: Scalars['String'];
  readonly nextChargeDate: Scalars['String'];
};

export type Subscription_SetNextChargeDatePayload = {
  readonly __typename?: 'subscription_SetNextChargeDatePayload';
  readonly userId?: Maybe<Scalars['String']>;
};

export type Subscription_SubscriptionDeletedEvent = {
  readonly __typename?: 'subscription_SubscriptionDeletedEvent';
  readonly userId: Scalars['String'];
  readonly subscriptionId: Scalars['String'];
  readonly deleted: Scalars['String'];
};

export type Subscription_SubscriptionForDeletion = {
  readonly userId: Scalars['String'];
  readonly subscriptionId: Scalars['String'];
};

export type Subscription_SubscriptionModel = {
  readonly __typename?: 'subscription_SubscriptionModel';
  readonly id: Scalars['String'];
  readonly created: Scalars['String'];
  readonly state: Subscription_SubscriptionState;
  readonly status: Subscription_SubscriptionStatus;
  readonly amount: Scalars['Float'];
  readonly frequency: Subscription_Frequency;
  readonly currency: Scalars['String'];
  readonly nextChargeDate?: Maybe<Scalars['String']>;
  readonly type: Subscription_SubscriptionType;
  readonly productPlanIdentifier: Subscription_ProductPlanIdentifier;
  readonly campaign?: Maybe<Scalars['String']>;
  readonly discountCode?: Maybe<Scalars['String']>;
  readonly invoices: ReadonlyArray<Subscription_Invoice>;
  readonly user: Subscription_User;
  readonly paymentMethod?: Maybe<Subscription_PaymentMethod>;
  readonly endDate?: Maybe<Scalars['String']>;
};

export enum Subscription_SubscriptionState {
  Active = 'active',
  Renewing = 'renewing',
  Grace = 'grace',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Draft = 'draft',
  PendingEnding = 'pending_ending',
  Ended = 'ended',
  Hold = 'hold',
}

export enum Subscription_SubscriptionStatus {
  Draft = 'draft',
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
}

export enum Subscription_SubscriptionType {
  Business = 'business',
  Travelcard = 'travelcard',
  BusinessCard = 'business_card',
  MulticurrencyCardSe = 'multicurrency_card_se',
  MulticurrencyCardNo = 'multicurrency_card_no',
  InsuranceTravel = 'insurance_travel',
  LunarPlanDkPrivate = 'lunar_plan_dk_private',
  LunarPlanSePrivate = 'lunar_plan_se_private',
  LunarPlanNoPrivate = 'lunar_plan_no_private',
  LunarDkExtraAccount = 'lunar_dk_extra_account',
  LunarSeExtraAccount = 'lunar_se_extra_account',
  LunarNoExtraAccount = 'lunar_no_extra_account',
  OverdraftDk = 'overdraft_dk',
  BufferDk = 'buffer_dk',
  AstralisDk = 'astralis_dk',
}

export type Subscription_SwitchSubscriptionPlanInput = {
  readonly userId: Scalars['String'];
  readonly subscriptionId: Scalars['String'];
  readonly productPlanIdentifier: Subscription_ProductPlanIdentifier;
};

export type Subscription_SwitchSubscriptionPlanPayload = {
  readonly __typename?: 'subscription_SwitchSubscriptionPlanPayload';
  readonly userId?: Maybe<Scalars['String']>;
};

export type Subscription_Transaction = {
  readonly __typename?: 'subscription_Transaction';
  readonly id: Scalars['String'];
  readonly created: Scalars['String'];
  readonly total: Scalars['Float'];
  readonly currencyCode: Scalars['String'];
  readonly stateCode: Subscription_TransactionStateCode;
  readonly errorCode?: Maybe<Subscription_TransactionErrorCode>;
  readonly gatewayResponse?: Maybe<Scalars['String']>;
};

export enum Subscription_TransactionErrorCode {
  None = 'none',
  Generic = 'generic',
  CardExpired = 'card_expired',
  InvalidCardNumber = 'invalid_card_number',
  InvalidCardExpiry = 'invalid_card_expiry',
  InvalidCardCvc = 'invalid_card_cvc',
  CardDecline = 'card_decline',
  CardDeclineFraud = 'card_decline_fraud',
  CardHolder = 'card_holder',
}

export enum Subscription_TransactionStateCode {
  Unknown = 'unknown',
  Success = 'success',
  Decline = 'decline',
  Failure = 'failure',
  Manual = 'manual',
}

export type Subscription_Upodi = {
  readonly __typename?: 'subscription_Upodi';
  readonly id?: Maybe<Scalars['String']>;
};

export type Subscription_User = {
  readonly __typename?: 'subscription_User';
  readonly id: Scalars['String'];
  readonly name?: Maybe<Subscription_UserName>;
  readonly email?: Maybe<Scalars['String']>;
  readonly subscriptions: ReadonlyArray<Maybe<Subscription_SubscriptionModel>>;
  readonly upodi?: Maybe<Subscription_Upodi>;
};

export type Subscription_UserSubscriptionsArgs = {
  type?: Maybe<Subscription_SubscriptionType>;
};

export type Subscription_UserName = {
  readonly __typename?: 'subscription_UserName';
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
};

export enum SubscriptionFrequencyType {
  Daily = 'daily',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export type SubscriptionPaymentFailureContent = {
  readonly __typename?: 'SubscriptionPaymentFailureContent';
  readonly id: Scalars['ID'];
  readonly created: Scalars['Date'];
  readonly updated?: Maybe<Scalars['Date']>;
  readonly subscription?: Maybe<PaymentSubscription>;
  readonly ssn: Scalars['String'];
  readonly cvr?: Maybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly phoneNumber?: Maybe<PhoneNumber>;
  readonly companyName?: Maybe<Scalars['String']>;
  readonly address: Scalars['String'];
};

export enum SubscriptionType {
  Business = 'business',
  Travelcard = 'travelcard',
  BusinessCard = 'businessCard',
  MulticurrencyCardSe = 'multicurrencyCardSE',
  MulticurrencyCardNo = 'multicurrencyCardNO',
  Any = 'any',
}

export type Tag = {
  readonly __typename?: 'Tag';
  readonly created: Scalars['Date'];
  readonly createdBy?: Maybe<User>;
  readonly deleted?: Maybe<Scalars['Date']>;
  readonly deletedBy?: Maybe<User>;
  readonly name: Scalars['String'];
};

export type TagCollection = {
  readonly __typename?: 'TagCollection';
  readonly count: Scalars['Int'];
  readonly nodes: ReadonlyArray<Tag>;
};

export type TagPayload = {
  readonly __typename?: 'TagPayload';
  readonly error?: Maybe<Scalars['String']>;
  readonly tag?: Maybe<Tag>;
  readonly task?: Maybe<Task>;
};

export type Task = {
  readonly __typename?: 'Task';
  readonly id: Scalars['ID'];
  readonly customerId: Scalars['String'];
  readonly customer?: Maybe<Customer>;
  readonly type: TaskType;
  readonly context?: Maybe<TaskContext>;
  readonly content: TaskContents;
  readonly failed: Scalars['Boolean'];
  readonly errors: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly tags: TagCollection;
  readonly created: Scalars['Date'];
  readonly updated?: Maybe<Scalars['Date']>;
  readonly assignedUserId?: Maybe<Scalars['ID']>;
  readonly archived: Scalars['Boolean'];
  readonly processed?: Maybe<Scalars['String']>;
  readonly processedBy?: Maybe<User>;
};

export type TaskContents =
  | SubscriptionPaymentFailureContent
  | BankSignupContent
  | BankPrivateSignupContent
  | BankPrivateSignupAmlContent
  | BankSelbSignupContent
  | BankBusinessSignupContent
  | BankSignupAmlContent
  | BankSelbSignupAmlContent
  | BankBusinessSignupAmlContent
  | CreditApplicationContent
  | BankNemkontoContent
  | MigrationFuturePaymentsFailedForPayment
  | MigrationMoneyAccountOverdrawn
  | BankMinorToAdult
  | BankCardEngravingContent
  | BankMoveBetalingsserviceMandateFromOtherBankContent
  | BankCloseCustomerContent
  | GenericContent;

export enum TaskContext {
  Dkka = 'dkka',
  Dknb = 'dknb',
  Dknbb = 'dknbb',
  Sepp = 'sepp',
  Nopp = 'nopp',
  Dklb = 'dklb',
  Dklbb = 'dklbb',
  Selb = 'selb',
  Nolb = 'nolb',
}

export type TaskTagInput = {
  readonly name: Scalars['String'];
  readonly taskId: Scalars['ID'];
};

export enum TaskType {
  Signup = 'signup',
  AgreementUpload = 'agreementUpload',
  SignupAudit = 'signupAudit',
  SignupCompletion = 'signupCompletion',
  NemKonto = 'nemKonto',
  CloseAccount = 'closeAccount',
  CloseAccountStay = 'closeAccountStay',
  CreditApplicationModelEval = 'creditApplicationModelEval',
  CreditApplicationElevatedModelEval = 'creditApplicationElevatedModelEval',
  CreditApplicationRkiLookup = 'creditApplicationRKILookup',
  CreditRequest = 'creditRequest',
  CreditCancellationRequest = 'creditCancellationRequest',
  PrepaidCardAccountWentNegative = 'prepaidCardAccountWentNegative',
  PayMyBill = 'payMyBill',
  Subscriptionpaymentfailure = 'subscriptionpaymentfailure',
  MigrationFailed = 'migrationFailed',
  BankSignup = 'bank_signup',
  BankPrivateSignup = 'bank_private_signup',
  BankPrivateSignupAml = 'bank_private_signupAML',
  BankSelbSignup = 'bank_selb_signup',
  BankBusinessSignup = 'bank_business_signup',
  BankSignupAml = 'bank_signupAML',
  BankSelbSignupAml = 'bank_selb_signupAML',
  BankBusinessSignupAml = 'bank_business_signupAML',
  BankPrivateCloseCustomer = 'bank_private_closeCustomer',
  BankBusinessCloseCustomer = 'bank_business_closeCustomer',
  BankNemkonto = 'bank_nemkonto',
  BankMinorToAdult = 'bank_minorToAdult',
  BankCardEngraving = 'bank_cardEngraving',
  BankMoveBetalingsserviceMandateFromOtherBank = 'bank_moveBetalingsserviceMandateFromOtherBank',
  MigrationFuturePaymentsFailedForPayment = 'migration_futurePaymentsFailedForPayment',
  MigrationMoneyAccountOverdrawn = 'migration_moneyAccountOverdrawn',
}

export type Tdschallenge_CreateChallenge = {
  readonly userId: Scalars['String'];
  readonly type: Scalars['String'];
  readonly sca: Scalars['Boolean'];
  readonly lifecycleId?: Maybe<Scalars['String']>;
  readonly transactionInfo: Tdschallenge_TransactionInfo;
};

export type Tdschallenge_Money = {
  readonly amount: Scalars['Float'];
  readonly currency: Scalars['String'];
};

export type Tdschallenge_TransactionInfo = {
  readonly id: Scalars['String'];
  readonly maskedPan: Scalars['String'];
  readonly merchantName: Scalars['String'];
  readonly amount: Tdschallenge_Money;
};

export type Tink_Account = {
  readonly __typename?: 'tink_Account';
  readonly name: Scalars['String'];
  readonly accountNumber: Scalars['String'];
  readonly balance: Scalars['Float'];
  readonly closed: Scalars['Boolean'];
  readonly hidden: Scalars['Boolean'];
  readonly type: Scalars['String'];
};

export enum Tink_AccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
  Investment = 'INVESTMENT',
  Mortgage = 'MORTGAGE',
  CreditCard = 'CREDIT_CARD',
  Loan = 'LOAN',
  Pension = 'PENSION',
  Other = 'OTHER',
  External = 'EXTERNAL',
}

export type Tink_Connection = {
  readonly __typename?: 'tink_Connection';
  readonly name: Scalars['String'];
  readonly providerName: Scalars['String'];
  readonly tinkCredentialsId: Scalars['String'];
  readonly status: Tink_ConnectionStatus;
  readonly statusPayload: Scalars['String'];
  readonly iconURL: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly accounts: ReadonlyArray<Tink_Account>;
};

export enum Tink_ConnectionStatus {
  Created = 'CREATED',
  Authenticating = 'AUTHENTICATING',
  AwaitingMobileBankidAuthentication = 'AWAITING_MOBILE_BANKID_AUTHENTICATION',
  AwaitingSupplementalInformation = 'AWAITING_SUPPLEMENTAL_INFORMATION',
  Updating = 'UPDATING',
  Updated = 'UPDATED',
  AuthenticationError = 'AUTHENTICATION_ERROR',
  TemporaryError = 'TEMPORARY_ERROR',
  PermanentError = 'PERMANENT_ERROR',
  AwaitingThirdPartyAppAuthentication = 'AWAITING_THIRD_PARTY_APP_AUTHENTICATION',
  Deleted = 'DELETED',
  SessionExpired = 'SESSION_EXPIRED',
}

export type Tink_Consent = {
  readonly __typename?: 'tink_Consent';
  readonly version: Scalars['String'];
  readonly date: Scalars['String'];
};

export enum Tink_CredentialsType {
  Password = 'PASSWORD',
  MobileBankid = 'MOBILE_BANKID',
  Keyfob = 'KEYFOB',
  ThirdPartyApp = 'THIRD_PARTY_APP',
}

export type Tink_Images = {
  readonly __typename?: 'tink_Images';
  readonly banner: Scalars['String'];
  readonly icon: Scalars['String'];
};

export type Tink_Provider = {
  readonly __typename?: 'tink_Provider';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly displayName: Scalars['String'];
  readonly groupDisplayName: Scalars['String'];
  readonly multiFactor: Scalars['Boolean'];
  readonly passwordHelpText: Scalars['String'];
  readonly popular: Scalars['Boolean'];
  readonly transactional: Scalars['Boolean'];
  readonly status: Tink_ProviderStatus;
  readonly type: Tink_ProviderType;
  readonly currency: Scalars['String'];
  readonly images: Tink_Images;
};

export enum Tink_ProviderStatus {
  Enabled = 'ENABLED',
  TemporaryDisabled = 'TEMPORARY_DISABLED',
  Disabled = 'DISABLED',
}

export enum Tink_ProviderType {
  Bank = 'BANK',
  CreditCard = 'CREDIT_CARD',
  Broker = 'BROKER',
  Test = 'TEST',
  Other = 'OTHER',
  Fraud = 'FRAUD',
}

export type Tink_Transfer = {
  readonly __typename?: 'tink_Transfer';
  readonly id: Scalars['String'];
  readonly created: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly tinkUnderlyingID: Scalars['String'];
  readonly tinkID: Scalars['String'];
  readonly credentialsID: Scalars['String'];
  readonly type: Tink_TransferType;
  readonly amount: Scalars['Float'];
  readonly currency: Scalars['String'];
  readonly dueDate: Scalars['String'];
  readonly status: Tink_TransferStatus;
  readonly statusMessage: Scalars['String'];
  readonly destinationMessage: Scalars['String'];
  readonly destinationUri: Scalars['String'];
  readonly sourceMessage: Scalars['String'];
  readonly sourceUri: Scalars['String'];
  readonly tinkResponse: Scalars['String'];
};

export enum Tink_TransferStatus {
  Created = 'CREATED',
  Executing = 'EXECUTING',
  AwaitingCredentials = 'AWAITING_CREDENTIALS',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Executed = 'EXECUTED',
  AwaitingThirdPartyAppAuthentication = 'AWAITING_THIRD_PARTY_APP_AUTHENTICATION',
}

export enum Tink_TransferType {
  Transfer = 'TRANSFER',
  Payment = 'PAYMENT',
}

export type Tink_User = {
  readonly __typename?: 'tink_User';
  readonly id: Scalars['String'];
  readonly tinkUserId: Scalars['String'];
  readonly market: Scalars['String'];
  readonly consent?: Maybe<Tink_Consent>;
  readonly connections: ReadonlyArray<Tink_Connection>;
};

export type Topupv2_LimitInfo = {
  readonly __typename?: 'topupv2_LimitInfo';
  readonly monthlyLimit: Topupv2_Money;
  readonly monthlyUsage: Topupv2_Money;
  readonly dailyLimit: Topupv2_Money;
  readonly dailyUsage: Topupv2_Money;
};

export type Topupv2_Money = {
  readonly __typename?: 'topupv2_Money';
  readonly amount: Scalars['Float'];
  readonly currency: Scalars['String'];
};

export type Transaction = {
  readonly __typename?: 'Transaction';
  readonly id: Scalars['ID'];
  readonly accountId?: Maybe<Scalars['String']>;
  readonly userID: Scalars['ID'];
  readonly created: Scalars['String'];
  readonly deleted?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly time: Scalars['String'];
  readonly year: Scalars['Int'];
  readonly month: Scalars['Int'];
  readonly postingTime?: Maybe<Scalars['String']>;
  readonly status: TransactionStatus;
  readonly type: TransactionType;
  readonly transactionOrigin: Scalars['String'];
  readonly transactionOriginGroupID?: Maybe<Scalars['String']>;
  readonly billingAmount?: Maybe<Money>;
  readonly transactionAmount?: Maybe<Money>;
  readonly transactionAmountCurrencyRate?: Maybe<Scalars['Float']>;
  readonly transferInfo?: Maybe<TransferInfo>;
  readonly paymentInfo?: Maybe<PaymentInfo>;
  readonly directDebitInfo?: Maybe<DirectDebitInfo>;
  readonly feeInfo?: Maybe<FeeInfo>;
  readonly conversionInfo?: Maybe<ConversionInfo>;
  readonly cardInfo?: Maybe<CardInfo>;
  readonly merchantInfo?: Maybe<MerchantInfo>;
  readonly localizableTitle?: Maybe<Scalars['String']>;
  readonly iconURL?: Maybe<Scalars['String']>;
  readonly iconBackgroundURL?: Maybe<Scalars['String']>;
  readonly badgeURL?: Maybe<Scalars['String']>;
  readonly color?: Maybe<Scalars['String']>;
  readonly categoryID?: Maybe<Scalars['String']>;
  readonly categoryIconUrl?: Maybe<Scalars['String']>;
  readonly groupID?: Maybe<Scalars['String']>;
  readonly currencyConversions?: Maybe<ReadonlyArray<Maybe<Money>>>;
  readonly geoLocation?: Maybe<GeoLocation>;
  readonly note?: Maybe<Scalars['String']>;
  readonly failedAuthorizationReason?: Maybe<Scalars['String']>;
  readonly performedByUserId?: Maybe<Scalars['String']>;
};

export type Transactioncore_ResendFilter = {
  readonly fromDate?: Maybe<Scalars['String']>;
  readonly ratePerSecond: Scalars['Int'];
  readonly limitPerQuery?: Maybe<Scalars['Int']>;
  readonly accountIds?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type TransactionFilter = {
  readonly userID: Scalars['String'];
  readonly transactionID?: Maybe<Scalars['String']>;
  readonly pageSize?: Maybe<Scalars['Int']>;
  readonly categoryID?: Maybe<Scalars['ID']>;
  readonly groupID?: Maybe<Scalars['ID']>;
  readonly origin?: Maybe<Scalars['String']>;
  readonly originType?: Maybe<Scalars['String']>;
  readonly originGroupID?: Maybe<Scalars['ID']>;
  readonly latestRequestTime?: Maybe<Scalars['String']>;
  readonly before?: Maybe<Scalars['String']>;
  readonly after?: Maybe<Scalars['String']>;
  readonly year?: Maybe<Scalars['Int']>;
  readonly month?: Maybe<Scalars['Int']>;
  readonly transactionType?: Maybe<TransactionType>;
  readonly transactionStatus?: Maybe<TransactionStatus>;
  readonly requireCore?: Maybe<Scalars['Boolean']>;
  readonly requireLabel?: Maybe<Scalars['Boolean']>;
  readonly requireCurrency?: Maybe<Scalars['Boolean']>;
  readonly includeFailedAuthorizations?: Maybe<Scalars['Boolean']>;
};

export enum TransactionStatus {
  Future = 'future',
  Financial = 'financial',
  Authorization = 'authorization',
  Interim = 'interim',
  FailedAuthorization = 'failed_authorization',
  Declined = 'declined',
  Unknown = 'unknown',
}

export enum TransactionType {
  Card = 'card',
  Account = 'account',
  Payment = 'payment',
  Conversion = 'conversion',
  AutomaticTransfer = 'automaticTransfer',
  Goal = 'goal',
  Unknown = 'unknown',
  DirectDebit = 'directDebit',
}

export type TransactionV2Filter = {
  readonly accountId: Scalars['String'];
  readonly before?: Maybe<Scalars['String']>;
};

export type Transfer_Bank = {
  readonly __typename?: 'transfer_Bank';
  readonly name: Scalars['String'];
  readonly sortCode: Scalars['String'];
  readonly iconUrl: Scalars['String'];
};

export type Transfer_CreateAccountTransferRequestInput = {
  readonly userId: Scalars['String'];
  readonly idempotencyId: Scalars['String'];
  readonly transfer: Transfer_TransferInput;
};

export type Transfer_CreatePaymentSlipPaymentRequestInput = {
  readonly userId: Scalars['String'];
  readonly idempotencyId: Scalars['String'];
  readonly payment: Transfer_PaymentInput;
};

export type Transfer_PaymentInput = {
  readonly sourceAccountId: Scalars['String'];
  readonly amount: Scalars['String'];
  readonly currency: Scalars['String'];
  /** ISO 8601 */
  readonly date: Scalars['String'];
  readonly type: Transfer_PaymentSlipType;
  /** Only present for some types */
  readonly paymentId?: Maybe<Scalars['String']>;
  readonly creditorId: Scalars['String'];
  /** Only present for some types */
  readonly textToRecipientAccount?: Maybe<Scalars['String']>;
  readonly textToOwnAccount?: Maybe<Scalars['String']>;
};

export enum Transfer_PaymentSlipType {
  Type_01 = 'TYPE_01',
  Type_04 = 'TYPE_04',
  Type_15 = 'TYPE_15',
  Type_71 = 'TYPE_71',
  Type_73 = 'TYPE_73',
  Type_75 = 'TYPE_75',
}

export type Transfer_TransferInput = {
  readonly sourceAccountId: Scalars['String'];
  readonly amount: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly recipientClearingNumber: Scalars['String'];
  readonly recipientAccountNumber: Scalars['String'];
  /** ISO 8601 */
  readonly date: Scalars['String'];
  readonly textToOwnAccount?: Maybe<Scalars['String']>;
  readonly textToRecipientAccount?: Maybe<Scalars['String']>;
  readonly additionalText?: Maybe<Scalars['String']>;
};

export type Transfer_TransferRequestStatus = {
  readonly __typename?: 'transfer_TransferRequestStatus';
  readonly requestId: Scalars['String'];
  readonly status: Transfer_TransferStatus;
  readonly errorMessages: ReadonlyArray<Scalars['String']>;
};

export enum Transfer_TransferStatus {
  Successful = 'SUCCESSFUL',
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
}

export type TransferInfo = {
  readonly __typename?: 'TransferInfo';
  readonly counterPartyAccountNumber?: Maybe<Scalars['String']>;
  readonly counterPartyUserID?: Maybe<Scalars['ID']>;
  readonly counterPartyInfo?: Maybe<Scalars['String']>;
  readonly correlationID?: Maybe<Scalars['ID']>;
  readonly canDelete?: Maybe<Scalars['Boolean']>;
  readonly isSystemTransfer?: Maybe<Scalars['Boolean']>;
  readonly specialTransferType?: Maybe<SpecialTransferType>;
  readonly counterPartyReference?: Maybe<Scalars['String']>;
  readonly message?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type TransferProgress = {
  readonly __typename?: 'TransferProgress';
  readonly id: Scalars['ID'];
  /** State of the transfer request */
  readonly state: ProgressType;
  /** Error which will be populated if state is FAIL */
  readonly error?: Maybe<Scalars['String']>;
};

/** The reason for a transfer */
export type TransferReason = {
  readonly __typename?: 'TransferReason';
  readonly alias: Scalars['String'];
  readonly message: Scalars['String'];
};

export type TransferStatus = {
  readonly __typename?: 'TransferStatus';
  readonly id: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly accountId: Scalars['ID'];
  readonly type: Scalars['String'];
  /** Reason for the transfer */
  readonly reason: TransferReason;
  readonly amount: Scalars['Decimal'];
  readonly currency: Scalars['Currency'];
  /** Progress for the transfer */
  readonly progress: TransferProgress;
  readonly created: Scalars['DateTime'];
};

export type TransferStatusCollection = {
  readonly __typename?: 'TransferStatusCollection';
  readonly count: Scalars['Int'];
  readonly nodes: ReadonlyArray<TransferStatus>;
};

export type TransferStatusListInput = {
  /** Filter to following accounts */
  readonly accountIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  /** Filter to following states */
  readonly states?: Maybe<ReadonlyArray<ProgressType>>;
};

export type TransferStatusListPayload = {
  readonly __typename?: 'TransferStatusListPayload';
  /** Populated with reason if the query failed */
  readonly error?: Maybe<Scalars['String']>;
  readonly transferStatusList?: Maybe<TransferStatusCollection>;
};

export type Trophy = {
  readonly __typename?: 'Trophy';
  readonly id: Scalars['ID'];
  readonly type: TrophyType;
  readonly label: Scalars['String'];
  readonly ownerId: Scalars['String'];
  readonly owner: User;
  readonly awardedAt: Scalars['String'];
  readonly awardValue: Scalars['Int'];
};

export type TrophyProgress = {
  readonly __typename?: 'TrophyProgress';
  readonly id: Scalars['ID'];
  readonly type: TrophyType;
  readonly label: Scalars['String'];
  readonly progressCurrent: Scalars['Int'];
  readonly progressGoal: Scalars['Int'];
};

export enum TrophyType {
  Chatter = 'Chatter',
  SignupChamp = 'SignupChamp',
  RobotLover = 'RobotLover',
  DestroyerOfDreams = 'DestroyerOfDreams',
}

export type UpdateCustomerInput = {
  readonly lunarRelation?: Maybe<CustomerLunarRelationInput>;
  readonly isBlocked?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  readonly id: Scalars['ID'];
  readonly addRoles?: Maybe<ReadonlyArray<RoleName>>;
  readonly removeRoles?: Maybe<ReadonlyArray<RoleName>>;
  readonly changeName?: Maybe<NameInput>;
};

export type UpdateUserPayload = {
  readonly __typename?: 'UpdateUserPayload';
  readonly user?: Maybe<User>;
  readonly errors?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type User = {
  readonly __typename?: 'User';
  readonly id: Scalars['ID'];
  readonly email: Scalars['String'];
  readonly name: Name;
  readonly settings: Settings;
  readonly roles: RoleCollection;
};

export type User_Details_Update_Response = {
  readonly __typename?: 'user_details_update_Response';
  readonly success: Scalars['Boolean'];
  readonly errorMessage?: Maybe<Scalars['String']>;
};

export type UserCollection = {
  readonly __typename?: 'UserCollection';
  readonly count: Scalars['Int'];
  readonly nodes: ReadonlyArray<User>;
};

export type Userfile_Category = {
  readonly __typename?: 'userfile_Category';
  readonly type: Userfile_CategoryType;
  readonly name: Scalars['String'];
};

export enum Userfile_CategoryType {
  PhotoId = 'PhotoId',
  TransactionDocumentation = 'TransactionDocumentation',
  TaxInformation = 'TaxInformation',
  Screenshot = 'Screenshot',
  AddressInformation = 'AddressInformation',
  Bankruptcy = 'Bankruptcy',
  Compulsory = 'Compulsory',
  LiquidationDocuments = 'LiquidationDocuments',
  Edd = 'EDD',
  Paychecks = 'Paychecks',
}

export type Userfile_CreateRequest = {
  readonly userId: Scalars['ID'];
  readonly categoryType: Userfile_CategoryType;
  readonly description: Scalars['String'];
  readonly notifyUser: Scalars['Boolean'];
};

export type Userfile_File = {
  readonly __typename?: 'userfile_File';
  readonly id: Scalars['ID'];
  readonly uploadedAt: Scalars['String'];
  readonly uploadedBy: Scalars['String'];
  readonly fileName: Scalars['String'];
  readonly mimeType: Scalars['String'];
};

export type Userfile_GenerateUploadUrlForRequestInput = {
  readonly requestId: Scalars['ID'];
  readonly originalFileName?: Maybe<Scalars['String']>;
};

export type Userfile_Request = {
  readonly __typename?: 'userfile_Request';
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['String'];
  readonly createdBy: Scalars['String'];
  readonly deletedAt?: Maybe<Scalars['String']>;
  readonly deletedBy?: Maybe<Scalars['String']>;
  readonly categoryType: Userfile_CategoryType;
  readonly description: Scalars['String'];
  readonly files: ReadonlyArray<Userfile_File>;
  readonly status: Userfile_Status;
  readonly appDeeplink: Scalars['String'];
  readonly silent: Scalars['Boolean'];
};

export enum Userfile_Status {
  Ongoing = 'Ongoing',
  Archived = 'Archived',
  Done = 'Done',
}

export enum UserTheme {
  Dark = 'Dark',
  Light = 'Light',
}

export type Viewing = {
  readonly __typename?: 'Viewing';
  readonly id: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly customerId: Scalars['ID'];
  readonly user: User;
  readonly customer: Customer;
};

export type ViewingCollection = {
  readonly __typename?: 'ViewingCollection';
  readonly count: Scalars['Int'];
  readonly nodes: ReadonlyArray<Viewing>;
};
