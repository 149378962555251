import * as React from 'react';
import styled from 'styled-components';
import { Premium_PlanId, Premium_PlanState } from 'src/__codegen__/types';
import { Color, Font, Gradient } from '_theme';
import { useCustomerPlanQuery } from '../data/__codegen__/GetCustomerPlan';

interface PlanTagProps {
  customerId: string;
}
export const PlanTag: React.FC<PlanTagProps> = ({ customerId }) => {
  const { data, loading, error } = useCustomerPlanQuery({
    variables: { userId: customerId },
  });

  if (loading || !data?.customerPlan) return null;
  if (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Unable to fetch customer plan for customer: ${customerId} - ${error.message}`
    );
  }

  return (
    <StyledPlanTag planId={data.customerPlan.id}>
      {data.customerPlan.state === Premium_PlanState.Cancelled && (
        <StyledCancelledLabel />
      )}
      <StyledIcon src={`${STAR_ICON}`} />
      <StyledText>
        {PLAN_MAP[data.customerPlan.id]?.name || 'Unknown plan'}
      </StyledText>
    </StyledPlanTag>
  );
};

const STAR_ICON: string = require('./star.svg');

const PLAN_MAP: {
  [key in Premium_PlanId]: { gradient: Gradient; name: string };
} = {
  DK_GROW: { gradient: Gradient.Gray, name: 'Grow' },
  DK_SOLO: { gradient: Gradient.LightGray, name: 'Solo' },
  DK_PREMIUM: { gradient: Gradient.Gold, name: 'Premium' },
  DK_PRO: { gradient: Gradient.DarkGray, name: 'Pro' },
  SE_PREMIUM: { gradient: Gradient.Gold, name: 'Premium' },
  SE_PRO: { gradient: Gradient.DarkGray, name: 'Pro' },
  NO_PREMIUM: { gradient: Gradient.Gold, name: 'Premium' },
  NO_PRO: { gradient: Gradient.DarkGray, name: 'Pro' },
  UNKNOWN: { gradient: Gradient.LightGray, name: 'Unkown' },
};

const StyledPlanTag = styled.div<{ planId: Premium_PlanId }>`
  height: 24px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 12px;
  font-family: ${Font.Regular};
  background: ${({ planId }) => PLAN_MAP[planId].gradient};
  color: ${Color.White};
  border-radius: 100px;
  position: relative;
`;

const StyledCancelledLabel = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: ${({ theme }) => theme.attention};
  height: 14px;
  width: 14px;
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.surface};
`;

const StyledText = styled.div`
  padding-left: 5px;
`;

const StyledIcon = styled.img`
  width: 14px;
  height: 14px;
`;
