import React from 'react';
import { ButtonLink } from 'src/components/Button';

interface HoustonCustomerLinkProps {
  customerId?: string;
}
export const HoustonCustomerLink: React.FC<HoustonCustomerLinkProps> = ({
  customerId,
}) => {
  const baseUrl = 'https://houston.prod.lunarway.com/customers';
  const url = customerId ? `${baseUrl}/${customerId}` : baseUrl;

  return (
    <ButtonLink
      target="_blank"
      rel="noopener noreferrer"
      icon={['far', 'external-link']}
      size="small"
      variant="primary"
      href={url}
    >
      Go to Houston
    </ButtonLink>
  );
};
