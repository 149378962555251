import * as Flex from '@twilio/flex-ui';
import { FeatureComponent } from '../initialize_features';
import { addAcceptTaskWithRecordingListener } from './ui/add_accept_task_with_recording_listener';

/**
 * This feature listens to `BeforeAcceptTask` and looks for the custom
 * `callRecord` attribute and decorates the `conferenceOptions` object used to
 * start a call with the required properties for call recording.
 */
export const initialize = (): FeatureComponent => ({
  onLoad: () => {
    addAcceptTaskWithRecordingListener(Flex);
  },
  onInitManager: () => {},
});
