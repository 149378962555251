import * as Flex from '@twilio/flex-ui';

const alertSound = new Audio(
  'https://lunarway-prod-cdn.s3.eu-west-1.amazonaws.com/skylab/skylab-notification-sound.mp3'
);

alertSound.loop = true;

const resStatus = ['accepted', 'canceled', 'rejected', 'rescinded', 'timeout'];

export const notifyOnIncomingCall = (manager: Flex.Manager) =>
  manager.workerClient.on('reservationCreated', (reservation: any) => {
    alertSound.play();

    resStatus.forEach(e => {
      reservation.on(e, () => {
        alertSound.pause();
      });
    });
  });
