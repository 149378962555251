import * as Flex from '@twilio/flex-ui';
import { FeatureComponent } from '../initialize_features';
import { addEndTaskListenerWithSendMessage } from './ui/add_end_task_listener_with_send_message';

/**
 * This feature listens to `beforeCompleteTask` and 
 * send message to customer
 */
export const initialize = (): FeatureComponent => ({
  onLoad: () => {
    addEndTaskListenerWithSendMessage(Flex);
  },
  onInitManager: () => {},
});
