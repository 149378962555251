import * as Flex from '@twilio/flex-ui';
import { send_message } from '../domain/send_message';
import { ICompleteTask } from '../data/ICompleteTask';

export const addEndTaskListenerWithSendMessage = (flex: typeof Flex) =>
  flex.Actions.addListener('beforeCompleteTask', (payload: ICompleteTask) => {
    if (payload.task && payload.task.attributes && payload.task.attributes.name) {
      send_message(payload.task.attributes.name, Flex.Manager.getInstance());
    }
  });
