import React from 'react';
import {
  ApolloProvider,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import * as Flex from '@twilio/flex-ui';

interface AppProps {
  manager: Flex.Manager;
  client: ApolloClient<NormalizedCacheObject> | null;
}
export const App = ({ manager, client }: AppProps) => {
  if (!manager) {
    return null;
  }

  if (!client) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <Flex.ContextProvider manager={manager}>
        <Flex.RootContainer />
      </Flex.ContextProvider>
    </ApolloProvider>
  );
};
