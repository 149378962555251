import { DefaultTheme } from 'styled-components';

export enum ColorLegacy {
  white = '#fff',
  gray90 = '#f9fafc',
  gray70 = '#eeeff1',
  gray40 = '#C3C4C9',
  gray30 = '#808489',
  gray20 = '#4d5258',
  gray10 = '#292f36',
  gray5 = '#171B21',
  black = '#000',
  green = '#00ca8a',
  red = '#ff3b71',
  blue = '#4098FF',
  yellow = '#ffb758',
}

export enum Font {
  Light = '"Campton Light", sans-serif',
  Regular = '"Campton Book", sans-serif',
  Medium = '"Campton Medium", sans-serif',
  Bold = '"Campton SemiBold", sans-serif',
  Code = '"Ubuntu Mono", Menlo, Monaco, Consolas, "Courier New", monospace',
}

export enum Shadow {
  Small = '0 1px 4px 0 rgba(0,0,0,0.08)',
  Medium = '0 3px 8px 0 rgba(0,0,0,0.08)',
  Large = '0 4px 16px 0 rgba(0,0,0,0.08)',
  Xlarge = '0 8px 24px 0 rgba(0,0,0,0.08)',
}

export const EASING = 'ease-in-out';

export const lightTheme: DefaultTheme = {
  background: '#FCFDFF',
  surface: '#FFFFFF',
  primary: '#0085FF',
  positive: '#34E0AF',
  negative: '#FF4981',
  attention: '#FA9B4B',
  text: '#000000',
  secondaryText: '#8E8E93',
  backgroundStroke: '#E9E9ED',
  surfaceStroke: '#E9E9ED',
  contrast0: '#FFFFFF',
  contrast10: '#E9E9ED',
  contrast20: '#C7C7CC',
  contrast30: '#AEAEB2',
  contrast40: '#8E8E93',
  contrast50: '#636366',
  contrast60: '#48484A',
  contrast70: '#3A3A3C',
  contrast80: '#272729',
  contrast100: '#0D0D0F',
  button: {
    primary: {
      default: '#0D0D0F',
      interactive: '#272729',
    },
    positive: {
      default: '#34E0AF',
      interactive: '#34E0AF',
    },
    negative: {
      default: '#FF4981',
      interactive: '#FF4981',
    },
    attention: {
      default: '#FA9B4B',
      interactive: '#FA9B4B',
    },
    secondary: {
      default: '#E9E9ED',
      interactive: '#C7C7CC',
    },
  },
};

export const darkTheme: DefaultTheme = {
  background: '#0D0D0F',
  surface: '#1A1A1C',
  primary: '#3AA1FF',
  positive: '#5DE2BC',
  negative: '#FA5688',
  attention: '#FAA35A',
  text: '#FFFFFF',
  secondaryText: '#AEAEB2',
  backgroundStroke: '#1A1A1C',
  surfaceStroke: '#3A3A3C',
  contrast0: '#0D0D0F',
  contrast10: '#272729',
  contrast20: '#3A3A3C',
  contrast30: '#48484A',
  contrast40: '#636366',
  contrast50: '#8E8E93',
  contrast60: '#AEAEB2',
  contrast70: '#C7C7CC',
  contrast80: '#E9E9ED',
  contrast100: '#FFFFFF',
  button: {
    primary: {
      default: '#FFFFFF',
      interactive: '#E9E9ED',
    },
    positive: {
      default: '#5DE2BC',
      interactive: '#5DE2BC',
    },
    negative: {
      default: '#FA5688',
      interactive: '#FA5688',
    },
    attention: {
      default: '#FAA35A',
      interactive: '#FAA35A',
    },
    secondary: {
      default: '#272729',
      interactive: '#3A3A3C',
    },
  },
};

export enum Color {
  Pink = '#EE7ABC',
  Purple = '#C855DF',
  DeepPurple = '#5F3BF9',
  DeepBlue = '#2D71FA',
  Blue = '#3F95FA',
  Cyan = '#4CCAFF',
  Teal = '#29DDC5',
  Green = '#00DFA0',
  Yellow = '#F4D164',
  Sand = '#C5BEAA',
  Gold = '#DDB368',
  Orange = '#F7775A',
  Salmon = '#F88181',
  Rose = '#FFB8B4',
  Red = '#FD4274',
  CoolGrey = '#687990',
  Black = '#0D0D0F',
  White = '#FFFFFF',
}

export enum Gradient {
  Gold = 'linear-gradient(180deg, rgba(250,219,165,1) 0%, rgba(223,185,116,1) 100%)',
  LightGray = 'linear-gradient(180deg, rgba(228,228,228,1) 0%, rgba(197,197,197,1) 100%)',
  Gray = 'linear-gradient(180deg, rgba(193,193,193,1) 0%, rgba(112,115,118,1) 100%)',
  DarkGray = 'linear-gradient(180deg, rgba(82,88,94,1) 0%, rgba(54,57,59,1) 100%)',
}
