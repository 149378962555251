import * as React from 'react';
import { Actions, withTheme, ITask, Button, Theme } from '@twilio/flex-ui';

export const PrivateCallbackButton = (props: { task: ITask; theme: Theme }) => (
  <Button
    onClick={() => {
      Actions.invokeAction('StartOutboundCall', {
        destination: props.task.attributes.customerNumber,
      });
    }}
  >
    Call customer
  </Button>
);

export const CallbackButton = withTheme(PrivateCallbackButton);
