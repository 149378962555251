import * as React from 'react';
import * as Flex from '@twilio/flex-ui';
import { ThemeProvider } from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { lightTheme } from '_theme';
import { FeatureComponent } from '../initialize_features';
import { DefaultView } from './default_view/ui';
import { Customers } from './customers/ui';

export const initialize = (): FeatureComponent => ({
  onLoad: () => {
    library.add(far, fas, fal);
    Flex.CRMContainer.defaultProps.uriCallback = (task): any => {
      if (task && task.attributes.name) {
        return Flex.CRMContainer.Content.replace(
          <ThemeProvider key="customers" theme={lightTheme}>
            <Customers phone={task.attributes.name} />
          </ThemeProvider>
        );
      }

      return Flex.CRMContainer.Content.replace(
        <ThemeProvider key="default-view" theme={lightTheme}>
          <DefaultView />
        </ThemeProvider>
      );
    };
  },
  onInitManager: () => {},
});
