import * as Flex from '@twilio/flex-ui';

/**
 * Ensures all tasks are shown with equal importance instead of default behavior
 * where voice channel tasks are statically shown at the top.
 *
 * Ensures age-based sorting between multiple shown tasks (now including voice).
 */
export const modifyTaskListSorting = (flex: typeof Flex) => {
  flex.TaskListContainer.defaultProps.staticTaskFilter = () => true; // https://www.twilio.com/docs/flex/ui/configuration#tasklistcontainer
  flex.TaskList.defaultProps.compareFunction = (a: Flex.ITask, b: Flex.ITask) =>
    b.age - a.age;
};
