import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '_components';
import { IntercomUserLink } from '../../intercom_user_link/ui';
import { PlanTag } from '../../plan_tag/ui';
import { Customer } from '../data/customers';
import { HoustonCustomerLink } from './HoustonCustomerLink';

interface StyledPreviewCustomerProps {
  expand: boolean;
}
const StyledPreviewCustomer = styled.div<StyledPreviewCustomerProps>`
  width: 100%;
  padding: 24px;
  display: flex;
  color: ${({ theme }) => theme.text};
  justify-content: space-between;
  align-items: center;
  opacity: 0.7;
  transition: opacity 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  ${({ expand }) =>
    expand &&
    css`
      opacity: 1;
    `}
`;

const StyledLinks = styled.div`
  display: flex;

  > * {
    margin-left: 24px;
  }
`;

const StyledHeading = styled.div`
  display: flex;
  align-items: center;
`;

interface PreviewCustomerProps extends Customer {
  isExpanded: boolean;
  onToggle: () => void;
}
export const PreviewCustomer: React.FC<PreviewCustomerProps> = memo(
  ({ firstName, lastName, phone, children, isExpanded, onToggle, id }) => (
    <div>
      <StyledPreviewCustomer onClick={onToggle} expand={isExpanded}>
        <div>
          <StyledHeading>
            <Text h3 style={{ margin: 0, marginRight: 8 }}>
              {firstName} {lastName}
            </Text>
            <PlanTag customerId={id} />
          </StyledHeading>

          {phone && (
            <Text h3 style={{ margin: 0 }}>
              {phone.number}
            </Text>
          )}
        </div>
        <StyledLinks>
          <IntercomUserLink customerId={id} />
          <HoustonCustomerLink customerId={id} />
        </StyledLinks>
      </StyledPreviewCustomer>
      {isExpanded && children}
    </div>
  )
);
