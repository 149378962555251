import React from 'react';
import styled from 'styled-components';
import { Shadow } from '_theme';

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.surface};
  border-radius: 16px;
  padding: 16px;
  box-shadow: ${Shadow.Small};
  width: 100%;
`;

interface CardProps {
  className?: string;
}
export const Card: React.FC<CardProps> = ({ children, className }) => (
  <StyledCard className={className}>{children}</StyledCard>
);
