import * as Flex from '@twilio/flex-ui';
import { FeatureComponent } from '../initialize_features';
import { notifyOnIncomingCall } from './notify_on_incoming_call';

const muteNotify = false;

export const initialize = (): FeatureComponent => ({
  onLoad: () => {},
  onInitManager: (manager: Flex.Manager) => {
    if (!muteNotify) {
      notifyOnIncomingCall(manager);
    }
  },
});
