import React from 'react';
import styled from 'styled-components';
import { HoustonCustomerLink } from './HoustonCustomerLink';

export const CostumerNotFoundDisplay = () => (
  <StyledContainer>
    <p>Customer not found</p>
    <HoustonCustomerLink />
  </StyledContainer>
);

const StyledContainer = styled.div`
  font-size: 22px;
  text-align: center;
`;
