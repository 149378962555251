import { AcceptTask } from '../data/AcceptTask';

/**
 * Adds recording properties to a conference options object.
 *
 * Recording options and docs:
 * - https://www.twilio.com/docs/voice/api/recording#optional-parameters
 * - https://support.twilio.com/hc/en-us/articles/360010785673-Call-Recording-with-Twilio-Flex
 */
export const withRecording = (payload: AcceptTask) => {
  payload.conferenceOptions.record = 'true';
  payload.conferenceOptions.recordingChannels = 'dual';

  // TODO: when we get a proper Twilio dev setup this should conditionally point to dev when skylab is running in dev
  //       https://trello.com/c/tv7jYKzq/492-twilio-dev-environment
  payload.conferenceOptions.recordingStatusCallback = `https://api.prod.lunarway.com/twilio-helpline/twilio-recording-status-callback?taskSid=${
    payload.task.taskSid
  }`;
};
