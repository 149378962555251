import * as React from 'react';
import styled, { css } from 'styled-components';
import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StyledBaseButton } from './styles/StyledBaseButton';
import { BaseButton } from './types/BaseButton';
import { StyledFontAwesomeIcon } from './styles/StyledFontAwesomeIcon';

export type ButtonIconProps = {
  icon: IconProp;
  onClick?: AriaButtonProps['onPress'];
} & Pick<AriaButtonProps, 'type' | 'autoFocus' | 'isDisabled'> &
  Pick<BaseButton, 'className' | 'size' | 'variant'>;
export const ButtonIcon: React.FC<ButtonIconProps> = ({
  type,
  autoFocus,
  isDisabled,
  size = 'big',
  icon,
  variant,
  className,
  onClick,
}) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    { onPress: onClick, type, autoFocus, isDisabled },
    ref
  );

  return (
    <StyledButtonIcon
      {...buttonProps}
      ref={ref}
      variant={variant}
      size={size}
      className={className}
    >
      <StyledFontAwesomeIcon icon={icon} />
    </StyledButtonIcon>
  );
};

const StyledButtonIcon = styled(StyledBaseButton)`
  width: 40px;
  padding: 0;
  font-size: 18px;

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 14px;
      width: 32px;
    `}

  &:disabled {
    opacity: 0.4;
    cursor: auto;

    ${({ variant, theme }) => {
      switch (variant) {
        case 'primary':
          return css`
            background: ${theme.button.primary.default};
          `;
        case 'positive':
          return css`
            background: ${theme.button.positive.default};
          `;
        case 'negative':
          return css`
            background: ${theme.button.negative.default};
          `;
        case 'attention':
          return css`
            background: ${theme.button.attention.default};
          `;
        case 'secondary':
          return css`
            background: ${theme.button.secondary.default};
          `;
        default:
          return null;
      }
    }}
  }
`;
